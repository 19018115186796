import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

// Components
import { Configuration } from '../../../components/Configuration/Configuration';
import Details from '../../../components/Details/Details';
import NotAuthorized from '../../../components/NotAuthorized/NotAuthorized';

// Miscellaneous
import { ApplicationState, TenantPayload, Tenants, TenantUploadRequest, SsoData, AccountData } from '../../../models';
import * as TenantActions from '../../../actions/tenants/tenants';
import * as SsoActions from '../../../actions/sso/sso';
import * as LoadingActions from '../../../actions/loading/loading';
import * as VerificationActions from '../../../actions/verification/verification';
import { UPLOAD_TENANT_LOGO, UPDATE_TENANT, CREATE_TENANT } from '../../../constants';

export interface SsoConfigurationContainerProps {
    tenants: Tenants;
    updateTenant: () => void;
    uploadTenantLogo: () => void;
    createTenant: () => void;
    saveSsoData: () => void;
    clearSsoData: () => void;
    toggleMode: () => void;
    clearVerificationCode: () => void;
    account?: AccountData;
    loading: any;
    sso: SsoData;
}

const mapStateToProps = ({ account, tenants, loading, sso }: ApplicationState) => {
    return {
        account,
        tenants,
        loading: {
            updatingTenant: loading.status.UPDATE_TENANT,
            uploadingLogo: loading.status.UPLOAD_TENANT_LOGO,
            creatingTenant: loading.status.CREATE_TENANT,
        },
        sso,
    };
};

// Helper function to get application state. Consider moving this into /helpers.
const getState = (dispatch: any) => new Promise((resolve) => {
    dispatch((dispatchFunction: any, getStateFunction: any) => resolve(getStateFunction()));
});

const resetSsoState = (dispatch: any) => {
    dispatch(SsoActions.clearSsoData());
    dispatch(LoadingActions.clearLoadingState(UPLOAD_TENANT_LOGO));
    dispatch(LoadingActions.clearLoadingState(UPDATE_TENANT));
    dispatch(LoadingActions.clearLoadingState(CREATE_TENANT))
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        updateTenant: (payload: TenantPayload) => {
            dispatch(TenantActions.updateTenant(payload) as any).then(() => {
                resetSsoState(dispatch);
            });
        },
        // Since the user did not upload a logo when creating the tenant,
        // we need to update the created tenant with an empty logo field
        // because the POST endpoint does not accept empty strings for logo
        createTenant: (tenantPayload: SsoData, logoBody?: any) => {
            dispatch(TenantActions.createTenant(tenantPayload) as any).then(async () => {
                const { tenants: { selectedTenant: { id } } } = (await getState(dispatch)) as any;
                if (logoBody) {
                    const logoPayload = { id, body: logoBody };
                    dispatch(SsoActions.uploadTenantLogo(logoPayload) as any).then(async () => {
                        const { sso: { logo } } = (await getState(dispatch)) as any;
                        dispatch(TenantActions.updateTenant({ id, body: { logo } } ) as any).then(() => {
                            resetSsoState(dispatch);
                        });
                    });
                } else {
                    dispatch(TenantActions.updateTenant({ id, body: { logo: '' } } ) as any).then(() => {
                        resetSsoState(dispatch);
                    });
                }
                dispatch(VerificationActions.clearVerificationCode());
            });
        },
        // The sso form's data is saved to the application state when
        // the user hits publish. If there's a new logo, we upload it
        // and then use the data in the application state to PATCH the
        // tenant with the updated values.
        uploadTenantLogo: (payload: TenantUploadRequest) => {
            dispatch(SsoActions.uploadTenantLogo(payload) as any).then(() => {
                getState(dispatch).then((state: any) => {
                    const { enabled, mfaEnabled, name, serialNumber, logo, subdomain } = state.sso;
                    const tenantPayload = {
                        id: state.tenants.selectedTenant.id,
                        body: {
                            enabled,
                            mfaEnabled,
                            name,
                            serialNumber,
                            logo,
                            subdomain,
                        },
                    };
                    dispatch(TenantActions.updateTenant(tenantPayload) as any).then(() => {
                        resetSsoState(dispatch);
                    });
                });
            });
        },
        saveSsoData: (payload: SsoData ) => dispatch(SsoActions.saveSsoData(payload)),
        toggleMode: (mode: string) => dispatch(SsoActions.toggleMode(mode)),
    };
};

class SsoConfiguration extends React.Component<SsoConfigurationContainerProps | null> {

    render() {
        const { account, tenants, updateTenant, createTenant, loading, uploadTenantLogo, saveSsoData, sso, toggleMode } = this.props;

        if (!tenants.results || !tenants.selectedTenant) {
            return null;
        }

        if (tenants.results.length === 0) {
            return <NotAuthorized labelId="NO_BUREAUS" />;
        }

        return (
            <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 pt-3 pb-4 vh-100">
                    <Configuration
                        account={account}
                        selectedTenant={tenants.selectedTenant}
                        updateTenant={updateTenant}
                        uploadTenantLogo={uploadTenantLogo}
                        loading={loading}
                        saveSsoData={saveSsoData}
                        ssoData={sso}
                        toggleMode={toggleMode}
                        createTenant={createTenant}
                        tenants={tenants}
                    />
                </div>
                <div className="col-3 d-none d-lg-block col-lg-3 col-xl-3">
                    <Details selectedTenant={tenants.selectedTenant}/>
                </div>
            </div>
        );

    }
}
const SingleSignOn = connect<any>(mapStateToProps, mapDispatchToProps)(SsoConfiguration);
export default SingleSignOn;
