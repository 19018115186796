import * as React from 'react';

import './Logo.scss';

export interface LogoProps {
    url: string;
}

export default function Logo( { url }: LogoProps) {
    return (
        <div className="header-logo">
            <img alt="logo" src={url} className="img-fluid"/>
        </div>
    );
}

