import * as React from 'react';
import { injectIntl, FormattedMessage, InjectedIntlProps  } from 'react-intl';
import { Named } from '../../../helpers/lists';

export interface NoDropdownComponentProps {
    header: string;
    selectedItem?: Named;
}

class NoDropdown extends React.Component<NoDropdownComponentProps & InjectedIntlProps> {
    render() {
        const { header, selectedItem } = this.props;
        return (
            <div className="no-dropdown h-100 d-flex align-items-center">
                <div className="border-0 h-100 pl-0 pr-2 text-muted d-flex align-items-center text-left">
                    <div className="d-flex flex-column pl-3 ml-2 border-left">
                        <span className="text-uppercase text-muted small"><FormattedMessage id={header}/></span>
                        <span className="text-dark">{selectedItem ? selectedItem.name : ''}</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(NoDropdown, { withRef: true });
