import { LOAD_EDCODES } from '../../actions/integrations/edCodesByCompany';
import { EDCodesByCompany, EDCode, CompanyKey } from '../../models';
import { Action } from 'redux-actions';
import { createSelector } from 'reselect';

const initialState: EDCodesByCompany = {};

const serializeKey = (key: CompanyKey) => `${key.tenantId}~${key.clientId}~${key.companyId}`;

export function edCodesByCompany(state: EDCodesByCompany = initialState, action: Action<any>): EDCodesByCompany {
    if (!action.error && action.type && action.payload) {
        switch (action.type) {
            case LOAD_EDCODES: {
                const { payload: { companyKey, edCodes } } = action;
                return {
                    ...state,
                    [serializeKey(companyKey)]: edCodes,
                }
            }

        }
    }
    return state;
}

// memoized selector to extract the E/D codes for a specified company
export const getEDCodes = createSelector<EDCodesByCompany, CompanyKey | undefined, EDCodesByCompany, CompanyKey | undefined, EDCode[] | undefined>(
    (state) => state,
    (state, props) => props,
    (byCompany, companyKey) => {
        return companyKey ? byCompany[serializeKey(companyKey)] : undefined;
    }
)

