import * as React from 'react';
import { Input, Button, ButtonDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { injectIntl, InjectedIntlProps, FormattedMessage  } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Fuse from 'fuse.js';
import { ChevronDown, Close, Add, Search } from '../../../facade/Icons/Icons';

import { Tenant, ConfigurationModes, AccountData } from '../../../models';
import { isAuthorizedTo } from '../../../helpers/auth';

import './SearchDropdown.scss';

export interface SearchDropdownComponentProps {
    account?: AccountData;
    searchToggleHeader?: string;
    searchData: Array<Tenant>;
    searchKeys: Array<string>;
    searchHeaderText?: string;
    selectItem: (tenantId: string) => string;
    selectedItem?: Tenant;
    toggleMode: (mode: string) => void;
    configurationMode?: string;
}
export interface SearchDropdownComponentState {
    searchQuery: string;
    searchResults: Array<Tenant>;
    showSearch: boolean;
}

class SearchDropdown extends React.Component<SearchDropdownComponentProps & InjectedIntlProps & RouteComponentProps, SearchDropdownComponentState> {
    private searchInput: HTMLInputElement;

    constructor(props: SearchDropdownComponentProps & InjectedIntlProps & RouteComponentProps) {
        super(props);
        this.state = {
            searchQuery: '',
            searchResults: props.searchData,
            showSearch: false,
        };

        this.queryData = this.queryData.bind(this);
        this.handleCreateTenant = this.handleCreateTenant.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps: any) {
        const currentState = this.state;
        this.setState({
            ...currentState,
            showSearch: false,
            searchResults: nextProps.searchData,
        });
    }

    queryData = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { searchData, searchKeys } = this.props;
        const { value } = event.target;
        const options = {
            keys: searchKeys,
            minMatchCharLength: 2,
            threshold: 0.3,
        };
        const fuse = new Fuse(searchData, options);
        const state = this.state;
        this.setState({
            ...state,
            searchResults: value ? fuse.search(value) : searchData,
            searchQuery: value,
        });
    };

    clearSearch = () => {
        const state = this.state;
        this.setState({
            ...state,
            searchResults:  this.props.searchData,
            searchQuery: '',
        });
    };

    toggle = () => {
        const state = this.state;
        this.setState({
            ...state,
            showSearch: !this.state.showSearch,
        }, () => {
            if (this.state.showSearch) {
                this.searchInput.focus();
                this.searchInput.focus();
            }
        });
    };

    highlightRelevantData = (value: string) => {
        const { searchQuery } = this.state;
        if (searchQuery && value && value.indexOf(searchQuery) >= 0) {
            return <strong className="text-dark">{value}</strong>;
        }
        return value;
    };

    handleCreateTenant = () => {
        const { history, location: { pathname }, toggleMode } = this.props;
        if (pathname !== '/sso-configuration') {
            history.push('/sso-configuration');
        }
        toggleMode(ConfigurationModes.Create);
    };

    render() {
        const { searchResults } = this.state;
        const { searchToggleHeader, searchHeaderText, selectItem, selectedItem, configurationMode } = this.props;
        const filteredResults = searchResults ? (
            <ul className="search-results list-group">
                {searchResults.map(item => {
                        const listClassNames = ['list-group-item', 'border-left-0', 'border-right-0', 'px-4'];
                        if (selectedItem && item.id === selectedItem.id) {
                            listClassNames.push('selected');
                        }
                        return (
                            <li
                                key={item.id}
                                className={listClassNames.join(' ')}
                                onClick={() => {
                                    selectItem(item.id);
                                    this.toggle();
                                }}
                            >
                                <span className="item-name">{this.highlightRelevantData(item.name)}</span>
                                <ul className="list-inline">
                                    <li className="item-serial-number list-inline-item small text-gray">
                                        {this.highlightRelevantData(item.serialNumber || 'N/A')}
                                    </li>
                                    <li className="list-inline-item small text-gray">•</li>
                                    <li className="item-subdomain list-inline-item small text-gray">
                                        {this.highlightRelevantData(item.subdomain)}
                                    </li>
                                </ul>
                            </li>
                        )
                    }
                )}
            </ul>
        ) : null;

        return (
          <div className="search-dropdown-component h-100">
              <ButtonDropdown
                  isOpen={this.state.showSearch}
                  toggle={this.toggle}
                  className="search-toggle h-100 d-flex align-items-center"
              >
                  <DropdownToggle
                      caret={false}
                      color="header-nav"
                      className="search-dropdown-btn border-0 h-100 pl-0 pr-2 text-muted d-flex align-items-center text-left"
                      disabled={configurationMode === ConfigurationModes.Create}
                  >
                      <div className="d-flex flex-column pl-3 ml-2 border-left">
                          <span className="text-uppercase text-muted small"><FormattedMessage id={searchToggleHeader || ''}/></span>
                          <span className="selected-item text-dark d-flex flex-row justify-content-between align-items-start">
                              <span>{selectedItem ? selectedItem.name : ''}</span>
                              <ChevronDown size="10" className="text-muted mx-2" />
                          </span>
                      </div>
                  </DropdownToggle>
                  <DropdownMenu className="border-0 p-0 search-dropdown-content">
                      <div className="search-dropdown bg-white d-flex flex-column">
                          {isAuthorizedTo({ action: 'tenant:create' }, this.props.account) &&
                              <div>
                                  <div className="search-dropdown-header border-bottom bg-light px-2 d-flex flex-row align-items-center">
                                      <div className="col">
                                          <span className="text-uppercase text-muted"><FormattedMessage id={searchHeaderText || ''}/></span>
                                      </div>
                                      <div className="col">
                                          <Button
                                              color="link"
                                              size="sm"
                                              className="add-sbo-btn d-flex align-items-center float-right pr-0 py-2 position-relative"
                                              onClick={this.handleCreateTenant}
                                          >
                                              <Add size="10" className="mx-1" />
                                              <FormattedMessage id="ADD_NEW"/>
                                          </Button>
                                      </div>
                                  </div>
                              </div>}
                          <div className="w-100 px-4 py-3 border-bottom position-relative">
                              <Search size="16" className="search-icon position-absolute text-muted" />
                              <Input
                                  type="text"
                                  name="search"
                                  placeholder={this.props.intl.formatMessage({ id: 'SEARCH' })}
                                  onChange={this.queryData}
                                  className="search-input"
                                  value={this.state.searchQuery}
                                  innerRef={(input: any) => { this.searchInput = input; }}
                              />
                              <Close
                                size="16"
                                className="search-icon-clear position-absolute text-muted"
                                onClick={this.clearSearch}
                                role="button"
                              />
                          </div>
                          <div className="search-results-wrapper d-flex flex-column">
                              {filteredResults}
                          </div>
                      </div>
                  </DropdownMenu>
              </ButtonDropdown>
          </div>
        )
    }
}

export default withRouter(injectIntl(SearchDropdown, { withRef: true }));
