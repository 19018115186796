import { Store, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { logger } from 'redux-logger';
import { ApplicationState } from '../models';
import { rootReducer } from '../reducers';

export function configureStore(initialState?: ApplicationState): Store<ApplicationState> {
    let enhancer = applyMiddleware(thunkMiddleware);

    if (process.env.NODE_ENV !== 'production') {
        enhancer = composeWithDevTools(applyMiddleware(thunkMiddleware, logger));
    }

    const store = createStore(rootReducer as any, initialState, enhancer) as Store<ApplicationState>;

    if (module.hot) {
        module.hot.accept('../reducers', () => {
            const nextReducer = require('../reducers');
            store.replaceReducer(nextReducer);
        });
    }

    return store;
}
