import { LOAD_TENANTS, BASE_URL, SELECT_TENANT, UPDATE_TENANT, CREATE_TENANT } from '../../constants';
import { request } from '../../helpers/http';
import { getToken, getUserDataFromToken } from '../../helpers/tokens';
import { createAsyncAction } from '../async/async';
import { authFallback } from '../authentication/authentication';
import { GenericAction, TenantPayload, Tenant, Tenants, SsoData } from '../../models';
import { alphabetizeByName } from '../../helpers/lists';

export interface LoadTenants extends GenericAction<Tenants> {
    type: LOAD_TENANTS;
}

export interface SelectTenant extends GenericAction<string> {
    type: SELECT_TENANT;
}

export interface UpdateTenant extends GenericAction<Tenant> {
    type: UPDATE_TENANT;
}

export interface CreateTenant extends GenericAction<Tenant> {
    type: CREATE_TENANT;
}

export type TenantAction = LoadTenants | SelectTenant | UpdateTenant | CreateTenant;

export function loadTenants() {
    // Load the tenants using the access token in sessionStorage.
    const token = getToken();
    const { tenantId } = getUserDataFromToken(token);
    return createAsyncAction(LOAD_TENANTS, () => request({
        url: `${BASE_URL}/identity/tenants`,
        headers: { Authorization: `Bearer ${token}` },
    }).then((payload) => {
        payload.results = alphabetizeByName(payload.results);
        return payload;
    }).catch((error) => {
        if (error.status === 403) {
            // if user is not an asure admin, they can only get their own tenant
            return request({
                url: `${BASE_URL}/identity/tenants/${tenantId}`,
                headers: { Authorization: `Bearer ${token}` },
            }).then((payload) => {
                return { results: [ payload ] };
            });
        }
        throw error;
    }), authFallback);
}

export function selectTenant(selectedTenantId: string) {
    return {
        type: SELECT_TENANT,
        payload: selectedTenantId,
    }
}

export function updateTenant(payload: TenantPayload) {
    return createAsyncAction(UPDATE_TENANT, () => request({
        url: `${BASE_URL}/identity/tenants/${payload.id}`,
        method: 'PATCH',
        headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': 'application/json' },
        body: payload.body,
    }), authFallback);
}

export function createTenant(payload: SsoData) {
    return createAsyncAction(CREATE_TENANT, () => request({
        url: `${BASE_URL}/identity/tenants`,
        method: 'POST',
        headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': 'application/json' },
        body: payload,
    }), authFallback);
}
