import * as QrCode from 'qrcode';
import * as React from 'react';
import { Input, Row, Col, Button, Label, Alert } from 'reactstrap';
import { MfaContainer, MfaBody, MfaFooter } from './MfaContainer';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { AccountData, Tenant } from '../../models';
import { buildOtpAuthUri, generateOtpSecret } from '../../helpers/auth';
import MfaCodeVerification from './MfaCodeVerification'
import { getBlankHref } from '../../helpers/urls';
import { ErrorIcon } from '../../facade/Icons/Icons';

const DEFAULT_ISSUER = 'Evolution HCM';

export interface MfaSetupDeviceProps {
    onCancel: () => void,
    account: AccountData;
    tenant?: Tenant;
    onEnableMfa: ({ secret, totpCode, useEmailOnly }: { secret: string, totpCode: string, useEmailOnly: boolean }) => void,
    loading: any;
    isModal?: boolean;
}
export interface MfaSetupDeviceState {
    secret: string,
    totpCode: string,
    formIsValid: boolean,
    isCopyCodeVisible: boolean,
    didCopy: boolean,
    totpIssuer: string,
    qrCodeDataURL: string
}

class MfaSetupDevice extends React.Component<MfaSetupDeviceProps, MfaSetupDeviceState> {
    UNSAFE_componentWillMount() {
        const { account, tenant } = this.props;

        // check if tenant name has been populated with a human-readable value (not the subdomain)
        const hasFullName = (tenant && tenant.name && tenant.name !== tenant.subdomain);
        const totpIssuer = hasFullName ? tenant!.name : DEFAULT_ISSUER;

        const secret = generateOtpSecret();
        const fullName = `${account.givenName} ${account.surname}`;
        const otpAuth = buildOtpAuthUri(fullName, totpIssuer, secret);
        QrCode.toDataURL(otpAuth, { margin: 0, scale: 6 }, (err: any, url: string) => {
            this.setState({
                qrCodeDataURL: url,
            });
        });

        this.setState({
            secret,
            totpCode: '',
            formIsValid: false,
            isCopyCodeVisible: false,
            didCopy: false,
            totpIssuer,
        });
    }

    showCopyCode() {
        this.setState({ isCopyCodeVisible : true });
    }

    copySecretToClipboard(e: React.MouseEvent<any>) {
        e.preventDefault();

        try {
            const el = document.querySelector('.totp-secret-for-copy');
            if (el) {
                (el as HTMLInputElement).select();
                document.execCommand('copy');

                this.setState({ didCopy : true });
            }
        } catch (err) {
            alert(`Looks like copy isn't supported on your browser.`);
        }
    }

    onSubmit(e: any) {
        e.preventDefault();
        const { secret, totpCode } = this.state;
        this.props.onEnableMfa({ secret, totpCode, useEmailOnly: false });
    }

    onTotpCodeChange({ isValid, totpCode }: { isValid: boolean, totpCode: string }) {
        this.setState({ formIsValid: isValid, totpCode });
    }

    render() {
        const { secret, totpIssuer, qrCodeDataURL } = this.state;

        const loadState = this.props.loading.status.UPDATE_ACCOUNT_TOTP;

        return (
            <MfaContainer isModal={this.props.isModal} onCancel={() => this.props.onCancel()} id="setup-mfa-device">
                <Alert color="danger" isOpen={loadState === 'error'}>
                    <ErrorIcon className="mr-2 align-middle" size="16" />
                    <FormattedMessage id="VERIFICATION_CODE_DID_NOT_MATCH" />
                </Alert>
                <MfaBody>
                    <h4><FormattedMessage id="MFA_VERIFY_NAME"/></h4>
                    <ol className="pt-4">
                        <li>
                            <h5><FormattedMessage id="GET_AUTH_APP" /></h5>
                            <FormattedHTMLMessage id="GET_AUTH_APP_HELP" />
                            <hr />
                        </li>
                        <li>
                            <h5 className="mt-4"><FormattedMessage id="ADD_THIS_ACCOUNT" /></h5>
                            <Row className="flex-column-reverse flex-sm-row">
                                <Col xs="12" sm="4" className="mb-4">
                                    <img className="qr-code img-fluid" src={qrCodeDataURL} alt={totpIssuer} />
                                </Col>
                                <Col xs="12" sm="8" className="mb-4">
                                    <FormattedMessage id="OPEN_AUTH_APP" />
                                    <ul>
                                        <li>
                                            <FormattedMessage id="ADD_NEW_ACCOUNT" />
                                            &nbsp;&mdash;&nbsp;
                                            <span className="text-muted"><FormattedMessage id="ADD_NEW_ACCOUNT_HELP" /></span>
                                        </li>
                                        <li>
                                            <span className="d-none d-sm-inline"><FormattedMessage id="SCAN_QR_CODE" /></span>
                                            <span className="d-sm-none"><FormattedMessage id="SCAN_QR_CODE_MOBILE" /></span>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                            <a href={getBlankHref()} onClick={(e) => { e.preventDefault(); this.showCopyCode(); }}>
                                <FormattedMessage id="CANNOT_SCAN_CODE" />
                            </a>
                            {this.state.isCopyCodeVisible && (
                                <div className="mt-4">
                                    <Label><FormattedMessage id="CANNOT_SCAN_CODE_HELP" /></Label>
                                    <div className="d-flex">
                                        <Input
                                            type="text"
                                            className="totp-secret-for-copy"
                                            value={secret}
                                            readOnly={true}
                                        />
                                        <Button color="white" onClick={(e: React.MouseEvent<any>) => this.copySecretToClipboard(e)}>
                                            {this.state.didCopy ? (
                                                <FormattedMessage id="COPY_TOTP_SECRET_SUCCESS" />
                                            ) : (
                                                <FormattedMessage id="COPY_TOTP_SECRET" />
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            )}
                            <hr />
                        </li>
                        <li>
                            <h5 className="mt-4"><FormattedMessage id="ENTER_VERIFICATION_CODE" /></h5>
                            <form onSubmit={e => this.onSubmit(e)}>
                                <MfaCodeVerification
                                    label="MFA_TOTP_VERIFY_DEVICE_LABEL"
                                    onTotpCodeChange={(e) => this.onTotpCodeChange(e)}
                                />
                            </form>
                        </li>
                    </ol>
                </MfaBody>
                <MfaFooter>
                    <Button color="white" disabled={loadState === 'loading'} onClick={() => this.props.onCancel()}>
                        <FormattedMessage id="CANCEL" />
                    </Button>
                    <Button color="primary" disabled={loadState === 'loading' || !this.state.formIsValid} onClick={(e: any) => this.onSubmit(e)}>
                        <FormattedMessage id="ENABLE" />
                    </Button>
                </MfaFooter>
            </MfaContainer>
        )
    }
}

export default MfaSetupDevice;
