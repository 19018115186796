import * as React from 'react';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Close } from '../../../facade/Icons/Icons';

import './UploadContent.scss';
import { formatBytes } from '../../../helpers/formatting';
import { ImageFile } from 'react-dropzone';

export interface UploadContentComponentProps {
    file: ImageFile | any;
    onClear: () => void;
    disabled: boolean;
}
export interface UploadContentComponentState {
    imageDimensions: {
        width: number | null;
        height: number | null;
    }
}

const INITIAL_STATE = {
    imageDimensions: {
        width: null,
        height: null,
    },
};
export default class UpdloadContent extends React.Component<UploadContentComponentProps, UploadContentComponentState> {
    constructor(props: UploadContentComponentProps) {
        super(props);
        this.state = INITIAL_STATE;
    }

    componentDidMount() {
        this.getImageDimensions(this.props.file);
    }

    UNSAFE_componentWillReceiveProps(nextProps: any) {
        if (nextProps === this.props) {
            this.getImageDimensions(nextProps.file);
        }
    }

    getImageDimensions = (fileInput: any) => {
        const image = new Image();
        image.onload = () => {
            const { width, height } = image;
            this.setState({
                imageDimensions: { width, height },
            });
        };
        // If the image fails to load the previous imageDimensions properties will persist
        // To avoid this, reset the state
        image.onerror = () => {
            this.setState(INITIAL_STATE);
        };

        // only drag and dropped images will have a size property
        if (fileInput.size) {
            image.src = window.URL.createObjectURL(fileInput);
        } else {
            // cache bust the url to force the browser to re-render the image,
            // otherwise the onload event won't fire
            image.src = `${fileInput.preview}?${new Date().getMilliseconds()}`;
        }

    };

    render() {
        const { file: { name, size, preview }, onClear, disabled } = this.props;
        const { imageDimensions: { width, height } } = this.state;
        const logoContainerClasses = [
            'logo-container',
            'position-relative',
            'd-flex',
            'justify-content-center',
            'align-items-center',
            'p-3',
        ];
        if (disabled) {
            logoContainerClasses.push('disabled');
        }

        const fileSize = size
            ? (
                <tr>
                    <td className="text-right text-muted pr-3"><FormattedMessage id="SIZE"/>:</td>
                    <td><span className="h6 font-weight-normal">{formatBytes(size)}</span></td>
                </tr>
            ) : null;

        const dimensions = width && height
            ? (
                <tr>
                    <td className="text-right text-muted pr-3"><FormattedMessage id="DIMENSIONS"/>:</td>
                    <td><span className="h6 font-weight-normal">{width} x {height} <FormattedMessage id="PIXELS"/></span></td>
                </tr>
            ) : null;

        const fileName = name
            ? (
                <tr>
                    <td className="text-right text-muted pr-3"><FormattedMessage id="FILE"/>:</td>
                    <td><span className="h6 font-weight-normal">{name}</span></td>
                </tr>
            ) : null;

        const fileDetails = (
            <table className="file-details mt-2">
                <tbody key={name}>
                    {fileName}
                    {dimensions}
                    {fileSize}
                </tbody>
            </table>
        );
        const cacheBuster = fileSize ? '' : `?${new Date().getMilliseconds()}`;
        return (
            <div>
                <div
                    className={logoContainerClasses.join(' ')}
                >
                    <img alt="logo" src={`${preview}${cacheBuster}`} className="img-fluid" />
                    <Button
                        color="white"
                        size="sm"
                        className="edit-controls position-absolute d-flex align-items-center p-0"
                        onClick={() => onClear()}
                    >
                        <Close size="16" role="button" />
                    </Button>
                </div>
                {fileDetails}
            </div>
        );
    }
}
