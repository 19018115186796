import AccountSettings from './AccountSettings/AccountSettings';
import SsoConfiguration from './SSOConfiguration/SSOConfiguration';
import IntegrationsPage from './Integrations/IntegrationsPage';
import IntegrationPage from './Integrations/IntegrationPage';
import AccountListingPage from './Accounts/AccountListingPage';
import AccountDetailsPage from './Accounts/AccountDetailsPage';
import Verification from './Verification/Verification';
import Logout from './Logout/Logout';
import AdvancedHrSettingsPage from './AdvancedHrSettings/AdvancedHrSettingsPage';

const Views = { AccountSettings, SsoConfiguration, IntegrationsPage, IntegrationPage, AccountListingPage, AccountDetailsPage, Verification, Logout, AdvancedHrSettingsPage };

export default Views;
