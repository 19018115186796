import { Authentication } from '../../models';
import { AuthenticationAction } from '../../actions/authentication/authentication';
import { REQUEST_SSO_TOKEN } from '../../constants';

export function authentication(state: Authentication = {}, action: AuthenticationAction): Authentication {
    if (!action.error && action.payload) {
        switch (action.type) {

            case REQUEST_SSO_TOKEN: {
                const { payload } = action;
                return {
                    ...state,
                    ...payload,
                };
            }
        }
    }
    return state;
}
