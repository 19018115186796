import { request, FetchOptions } from './http';
import { authFallback } from '../actions/authentication/authentication';
import { ReduxStore } from '../store/ReduxStore';

export class HttpClient {

    static inject = [ ReduxStore ];
    constructor(private reduxStore: ReduxStore) {}

    request = async (options: FetchOptions) => {
        try {
            return await request(options);
        } catch (error) {
            if (error.status === 401) {
                this.reduxStore.dispatch(authFallback(error)!);
            }
            throw error;
        }
    };
}
