import * as React from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import ControlledInput from '../../components/Forms/ControlledInput/ControlledInput';
import { isMobile } from '../../helpers/browser';

export interface MfaCodeVerificationProps {
    label: string,
    onTotpCodeChange: ({ totpCode, isValid }: { totpCode: string, isValid: boolean }) => void,
}
export interface MfaCodeVerificationState {
    totpCode: string,
    isDirty?: boolean,
}

class MfaCodeVerification extends React.Component<MfaCodeVerificationProps & InjectedIntlProps, MfaCodeVerificationState> {

    UNSAFE_componentWillMount() {
        this.setState({
            totpCode: '',
            isDirty: false,
        });
    }

    handleCodeChange = (updates: { totpCode: string }): void => {

        this.setState({
            totpCode: updates.totpCode,
            isDirty: true,
        }, () => {
            // use callback so we get updated state here
            this.props.onTotpCodeChange({
                isValid: !this.isCodeInvalid(),
                totpCode: this.state.totpCode,
            });
        });
    };

    focusTotpCode(el: any) {
        // when the component is dismounting, el will be null
        if (el && !isMobile()) {
            // if we don't wait, the browser appears to be in a state where the focus does nothing
            // possibly because it isn't mounted yet, or not visible yet - and we need to wait at
            // least 500ms for slide-in animation to complete
            setTimeout(() => {
                el.querySelector('input').focus();
            }, 500);
        }
    }

    isCodeInvalid() {
        const { totpCode } = this.state;
        const regex = /^[0-9]{6}$/;
        return totpCode === '' || !regex.test(totpCode);
    }

    render() {
        const { totpCode, isDirty } = this.state;

        return (
            <div ref={el => this.focusTotpCode(el)} className="verification-code">
                <ControlledInput
                    name="totpCode"
                    type={isMobile() ? 'number' : 'text'}
                    label={this.props.label}
                    placeholder={this.props.intl.formatMessage({ id: 'SIX_DIGIT_CODE' })}
                    errorMessage={this.props.intl.formatMessage({ id: 'SIX_DIGIT_CODE_ERROR' })}
                    value={totpCode}
                    invalid={isDirty && this.isCodeInvalid()}
                    columnConfig={{ default: 12 }}
                    disabled={false}
                    required={false}
                    onChange={this.handleCodeChange}
                />
            </div>
        )
    }
}

export default injectIntl(MfaCodeVerification, { withRef: true });
