import { TenantAction } from '../../actions/tenants/tenants';
import { Tenants, Tenant } from '../../models';
import { LOAD_TENANTS, SELECT_TENANT, UPDATE_TENANT, CREATE_TENANT } from '../../constants';

const initialState = {
    results: [],
}

const getSelected = (tenantList: Tenant[], selectedId?: string) => {
    if (!tenantList.length) {
        return undefined;
    }
    const found = tenantList.find((tenant) => tenant.id === selectedId);
    return found || tenantList[0];
}

export function tenants(state: Tenants = initialState, action: TenantAction): Tenants {
    if (!action.error && action.type && action.payload) {
        switch (action.type) {
            case LOAD_TENANTS: {
                const { payload } = action;
                const selectedId = state.selectedTenant ? state.selectedTenant.id : undefined;
                const selectedTenant = getSelected(payload.results, selectedId);
                return {
                    ...state,
                    ...payload,
                    selectedTenant,
                }
            }
            case SELECT_TENANT: {
                const { payload } = action;
                const selectedTenant = getSelected(state.results, payload);
                return {
                    ...state,
                    selectedTenant,
                }
            }
            case UPDATE_TENANT: {
                const { payload } = action;
                // merge the updated tenant into the tenants results array
                const results = state.results.map(result => result.id === payload.id ? { ...result, ...payload } : result);

                return {
                    ...state,
                    results,
                    selectedTenant: payload,
                }
            }
            case CREATE_TENANT: {
                const { payload } = action;
                // merge the updated tenant into the tenants results array
                const results = state.results.map(result => result.id === payload.id ? { ...result, ...payload } : result);

                return {
                    ...state,
                    results,
                    selectedTenant: payload,
                }
            }
        }
    }
    return state;
}
