import * as React from 'react';
import { default as ReactToggleComponent } from 'react-toggle';  // alias to preserve exported component name as 'Toggle'
import { FormattedMessage } from 'react-intl';

import 'react-toggle/style.css';
import './Toggle.scss';

export interface ToggleComponentProps {
    on?: boolean;
    disabled: boolean;
    onChange: (object: { [key: string]: any }) => void;
    name: string;
    toggleLabelOn?: string;
    toggleLabelOff?: string;
}

export default function Toggle({ on, disabled, onChange, name, toggleLabelOff = 'OFF', toggleLabelOn = 'ON' }: ToggleComponentProps) {
    const handleChange = (e: { target: HTMLInputElement; }) => {
        onChange({ [`${name}`]: e.target.checked });
    };

    let toggleLabel;
    toggleLabel = on
        ? <FormattedMessage id={toggleLabelOn}/>
        : <FormattedMessage id={toggleLabelOff}/>
    ;

    return (
        <div className="d-flex align-items-center">
            <ReactToggleComponent
                icons={false}
                disabled={disabled}
                onChange={handleChange}
                className="sso-toggle mr-3"
                checked={on}
            />
            <span className="h6 font-weight-normal text-muted m-0">{toggleLabel}</span>
        </div>
    );
}

