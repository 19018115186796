import * as React from 'react';
import { Table, Alert } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { AccountData } from '../../models';
import FilterSearch from '../FilterSearch/FilterSearch';

const buildAccountUrl = (account: AccountData) => {
    return `/accounts/${encodeURIComponent(account.username!)}`;
}

export interface AccountListingProps extends RouteComponentProps {
    accounts: AccountData[];
}

class AccountListing extends React.Component<AccountListingProps> {
    render() {
        const { accounts, history } = this.props;
        return (
            <>
                <FilterSearch
                    searchData={accounts.map((acct) => ({ ...acct, fullName: `${acct.givenName} ${acct.surname}` }))}
                    searchKeys={['fullName', 'username', 'email']}
                    placeholderId="SEARCH_USERS"
                    renderList={(filteredAccounts, searchQuery) => (
                        <>
                            <Table className="border-bottom selectable">
                                <thead>
                                    <tr>
                                        <th><small><FormattedMessage id="NAME_AND_USERNAME" /></small></th>
                                        <th><small><FormattedMessage id="EMAIL" /></small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredAccounts.map((account) => {
                                        return (
                                            <tr
                                                key={account.id}
                                                data-name="user-list-item"
                                                data-id={account.username}
                                                onClick={() => history.push(buildAccountUrl(account))}
                                            >
                                                <td className="align-middle text-break">
                                                    <Link to={buildAccountUrl(account)} onClick={(e) => e.stopPropagation()}>
                                                        {account.fullName}
                                                    </Link>
                                                    <p className="text-muted small mb-0">{account.username}</p>
                                                </td>
                                                <td className="align-middle text-break">
                                                    {account.email}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                            {filteredAccounts.length === 0 &&
                                <Alert color="info">
                                    <FormattedMessage id="NO_USERS_MATCH" values={{ SEARCH_QUERY: searchQuery }} />
                                </Alert>
                            }
                        </>
                    )}
                />
            </>
        );
    }
}

export default withRouter(AccountListing);
