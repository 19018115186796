import * as React from 'react';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import './CardButton.scss';

export interface CardButtonProps {
    active: boolean,
    icon: React.ReactNode,
    title: string,
    message: { id: string, values?: any },
    onClick: () => void,
}

export default (props: CardButtonProps) => {
    const { active, icon, title, message, onClick } = props;
    const ButtonIcon = icon as any;
    return (
        <Button
            className="card-button btn-white p-4 m-3 text-left"
            data-toggle="button"
            active={active}
            onClick={onClick}
        >
            <div className="d-flex align-items-start">
                <div className="icon-wrapper d-flex flex-column align-items-center justify-content-center">
                    <ButtonIcon size="20" />
                </div>
                <div className="ml-4 flex-fill">
                    <h5 className="font-weight-normal"><FormattedMessage id={title} /></h5>
                    <p className="text-muted form-text">
                        <FormattedMessage id={message.id} values={message.values}/>
                    </p>
                </div>
            </div>
        </Button>
    );
};
