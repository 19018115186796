import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { withDependencies, Subscribe, Compose } from '@symbiotic/green-state';

import { Integration, Tenant, AccountData } from '../../../models';
import LoadingIndicator from '../../../facade/LoadingIndicator/LoadingIndicator';
import ErrorRenderer from '../../../components/ErrorRenderer/ErrorRenderer';
import { IntegrationsStateFactory } from './IntegrationsStateFactory';
import { InjectSelectedTenant } from '../Tenants/InjectSelectedTenant';
import { InjectAuthenticatedAccount } from '../Accounts/InjectAuthenticatedAccount';

export interface IntegrationPageProps {
    integrationKey: string;
    param1?: string;
    param2?: string;
    stateFactory?: IntegrationsStateFactory; // injected
}

export interface IntegrationPageState {
    integration?: Integration;
    component: any;
}

@withDependencies({ stateFactory: IntegrationsStateFactory })
export default class IntegrationPage extends React.Component<IntegrationPageProps, IntegrationPageState> {
    private unmounted: boolean = false;

    constructor(props: IntegrationPageProps) {
        super(props);
        this.state = {
            component: undefined,
        };
    }

    async UNSAFE_componentWillMount() {
        const { integrationKey } = this.props;
        let component;
        try {
            // try to dynamically load the "index" component from the appropriate integration folder
            // NOTE: we need "/index" on the end of the path below, otherwise tsc thinks we are referencing the entire
            // /Integrations folder, which causes it to include all excluded files in there (e.g. __snapshots__).
            ({ default: component } = await import(`../../../components/Integrations/${integrationKey}/index`));
        } catch (ignore) {
            // return an empty component if not found
            component = () => null;
        }
        if (!this.unmounted) {
            this.setState({ component });
        }
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    render() {
        const { integrationKey, param1, param2, stateFactory } = this.props;
        const { component: Component } = this.state;

        if (!Component) {
            return null;
        }

        return (
            <Compose components={[InjectSelectedTenant, InjectAuthenticatedAccount]}>
                {({ selectedTenant: tenant }: { selectedTenant: Tenant }, { account }: { account: AccountData }) => (
                    <Subscribe key={tenant.id} to={() => stateFactory!.getIntegrationsForTenant(tenant.id)}>
                        {({ integrations, isLoading, error }) => {
                            if (isLoading) {
                                return <LoadingIndicator />;
                            }

                            if (error) {
                                return <ErrorRenderer error={error} />;
                            }

                            const integration = integrations.find(i => i.key === integrationKey);
                            if (!integration) {
                                return <h3 className="mt-4"><FormattedMessage id="UNRECOGNIZED_INTEGRATION" values={{ INTEGRATION_KEY: integrationKey }} /></h3>;
                            }

                            return <Component integration={integration} tenant={tenant} account={account} params={[param1, param2]} />;
                        }}
                    </Subscribe>
                )}
            </Compose>
        );
    }
}
