import { BASE_URL } from '../../constants';
import { request } from '../../helpers/http';
import { getToken } from '../../helpers/tokens';
import { createAsyncAction } from '../async/async';
import { authFallback } from '../authentication/authentication';
import { GenericAction, Configuration, TenantIntegrationKey } from '../../models';

export const LOAD_INTEGRATION_CONFIGURATIONS = 'LOAD_INTEGRATION_CONFIGURATIONS';
export type LOAD_INTEGRATION_CONFIGURATIONS = typeof LOAD_INTEGRATION_CONFIGURATIONS;
export const CREATE_INTEGRATION_CONFIGURATION = 'CREATE_INTEGRATION_CONFIGURATION';
export type CREATE_INTEGRATION_CONFIGURATION = typeof CREATE_INTEGRATION_CONFIGURATION;
export const UPDATE_INTEGRATION_CONFIGURATION = 'UPDATE_INTEGRATION_CONFIGURATION';
export type UPDATE_INTEGRATION_CONFIGURATION = typeof UPDATE_INTEGRATION_CONFIGURATION;

export interface ConfigurationsPayload {
    key: TenantIntegrationKey;
    configurations: Configuration[];
}

export interface LoadConfigurations extends GenericAction<ConfigurationsPayload> {
    type: LOAD_INTEGRATION_CONFIGURATIONS;
}

export interface CreateConfiguration extends GenericAction<Configuration> {
    type: CREATE_INTEGRATION_CONFIGURATION;
}

export interface UpdateConfiguration extends GenericAction<Configuration> {
    type: UPDATE_INTEGRATION_CONFIGURATION;
}

export type ConfigurationsAction = LoadConfigurations | CreateConfiguration | UpdateConfiguration;

export function loadConfigurations({ tenantId, integrationId }: TenantIntegrationKey) {
    return createAsyncAction(LOAD_INTEGRATION_CONFIGURATIONS, () => request({
        url: `${BASE_URL}/api/tenants/${tenantId}/integrations/${integrationId}/integration-configurations`,
        headers: { Authorization: `Bearer ${getToken()}` },
    }).then((configurations) => {
        return { key: { tenantId, integrationId }, configurations };
    }), authFallback);
}

export function createConfiguration(configuration: Configuration) {
    const { tenantId, integrationId, clientId, companyId } = configuration;
    return createAsyncAction(CREATE_INTEGRATION_CONFIGURATION, () => request({
        url: `${BASE_URL}/api/tenants/${tenantId}/clients/${clientId}/companies/${companyId}/integrations/${integrationId}/integration-configurations`,
        method: 'POST',
        headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': 'application/json' },
        body: configuration,
    }), authFallback);
}

export function updateConfiguration(configuration: Configuration) {
    const { id, tenantId, integrationId, clientId, companyId } = configuration;
    return createAsyncAction(UPDATE_INTEGRATION_CONFIGURATION, () => request({
        url: `${BASE_URL}/api/tenants/${tenantId}/clients/${clientId}/companies/${companyId}/integrations/${integrationId}/integration-configurations/${id}`,
        method: 'PUT',
        headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': 'application/json' },
        body: configuration,
    }), authFallback);
}
