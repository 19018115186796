import * as React from 'react';
import { AccountData } from '../../../models';
import { AccountsService } from './AccountsService';
import { withDependencies } from '@symbiotic/green-state';

export interface InjectAccountState {
    account?: AccountData;
    isLoading: boolean;
    error?: Error;
}

interface InjectAccountProps {
    tenantId: string;
    username: string;
    accountsService?: AccountsService;
    children: (props: InjectAccountRenderProps) => any;
}

interface InjectAccountRenderProps extends InjectAccountState {}

@withDependencies({ accountsService: AccountsService })
export class InjectAccount extends React.Component<InjectAccountProps, InjectAccountState> {
    state = {
        account: undefined,
        isLoading: true,
        error: undefined,
    };

    async componentDidMount() {
        const { tenantId, username } = this.props;
        try {
            const account = await this.props.accountsService!.getAccount({ tenantId, username });
            this.setState({ account, isLoading: false });
        } catch (error) {
            this.setState({ error, isLoading: false });
        }
    }
    componentWillUnmount() {
        this.setState = () => {};
    }
    render() {
        const { children } = this.props;
        return children(this.state);
    }
}
