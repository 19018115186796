import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { hot } from 'react-hot-loader';
import * as queryString from 'query-string';

import AppRouter from './AppRouter';
import './App.scss';

import { AccountData, ApplicationState, Authentication } from '../../models';
import { DEFAULT_TOKEN_NAME, SSO_DOMAIN } from '../../constants';
import { setToken, getToken, getUserDataFromToken } from '../../helpers/tokens';
import * as AccountActions from '../../actions/account/account';
import * as AuthenticationActions from '../../actions/authentication/authentication';

import { getTenantSubdomainFromUrl } from '../../helpers/urls';
import { handleTenantIdParam } from '../../helpers/tenantId';
import { ErrorService } from './ErrorService';

export interface AppProps {
    setAccountData: (accountData: AccountData | object) => AccountData | object;
    getSSOToken: () => void;
    authentication: Authentication;
    account: AccountData;
}

const mapStateToProps = ({ authentication, account }: ApplicationState) => {
    return {
        authentication,
        account,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        setAccountData: (accountData: AccountData | object) => dispatch(AccountActions.setAccountData(accountData)),
        getSSOToken: () => dispatch(AuthenticationActions.requestSSOToken()),
    };
};

class App extends React.Component<AppProps | null> {

    completeOAUTHLogin(accessToken: string) {
        setToken(DEFAULT_TOKEN_NAME, accessToken);
        const previousHref = window.sessionStorage.getItem('oauth.previous-href') || '/';
        window.location.replace(previousHref);
    }

    startOAUTHLogin(ssoToken: string) {
        // never save /logout here or we'll create an infinite loop!
        const returnToUrl = (window.location.pathname !== '/logout' ? window.location.href : window.location.origin);
        window.sessionStorage.setItem('oauth.previous-href', returnToUrl);

        window.location.replace(`${SSO_DOMAIN}/?jwt=${ssoToken}&shared_session=true`);
    }

    async startApp() {
        // For backward compatibility, support ?tenantId or ?_tenantId in place of subdomain.
        // DEPRECATED: remove when no longer in use by legacy links.
        if (await handleTenantIdParam()) {
            return;
        };

        // tenant subdomain must be present, or we show error page
        if (!getTenantSubdomainFromUrl()) {
            new ErrorService().showNotFound();
            return;
        }

        const accessToken = getToken();

        if (accessToken) {
            const { setAccountData } = this.props;
            const accountData = getUserDataFromToken(accessToken) as AccountData;
            setAccountData(accountData);
        } else {
            // trigger getting the sso token
            //  which will cause a redux state change to include an sso token
            //  which will then cause a redirect to heisenberg (see componentWillReceiveProps)
            this.props.getSSOToken();
        }
    }

    async UNSAFE_componentWillMount() {
        const accessToken = queryString.parse(window.location.search).access_token;
        if (accessToken) {
            this.completeOAUTHLogin(accessToken);
        } else {
            await this.startApp();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: AppProps) {
        // Redirect to SSO to authenticate after token request
        const { ssoToken } = nextProps.authentication;
        if (ssoToken) {
            this.startOAUTHLogin(ssoToken);
        }
    }

    render() {
        if (this.props.account.loadedFromToken) {
            return (
                <AppRouter account={this.props.account} />
            );
        } else {
            return null;
        }
    }
}

const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App);
export default hot(module)(ConnectedApp);
