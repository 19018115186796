import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLock,
  faPuzzlePiece,
  faUsersCog,
  faShieldCheck,
  faLink,
  faExclamationCircle,
  faCheckCircle,
  faTimesCircle,
  faExchangeAlt,
  faTimes,
  faSearch,
  faCloudUploadAlt,
  faPlus,
  faChevronDown,
  faChevronRight,
  faLongArrowRight,
  faRedo,
  faPen,
  faMobileAlt,
  faEnvelope
} from '@fortawesome/pro-light-svg-icons';
export interface Icon {
  className?: string,
  size: string,
  onClick?: () => void,
  role?: string,
}
export const Authentication = ({ className, size, onClick, role }: Icon) => {
  const fontStyle = { fontSize: `${size}px` };
  return (
    <FontAwesomeIcon icon={faLock} onClick={onClick} className={className} style={fontStyle} />
  )
}

export const Integrations = ({ className, size, onClick, role }: Icon) => {
  const fontStyle = { fontSize: `${size}px` };
  return (
    <FontAwesomeIcon icon={faPuzzlePiece} onClick={onClick} className={className} style={fontStyle} />
  )
}

export const Users = ({ className, size, onClick, role }: Icon) => {
  const fontStyle = { fontSize: `${size}px` };
  return (
    <FontAwesomeIcon icon={faUsersCog} onClick={onClick} className={className} style={fontStyle} />
  )
}

export const Verification = ({ className, size, onClick, role }: Icon) => {
  const fontStyle = { fontSize: `${size}px` };
  return (
    <FontAwesomeIcon icon={faShieldCheck} onClick={onClick} className={className} style={fontStyle} />
  )
}

export const AdvancedHR  = ({ className, size, onClick, role }: Icon) => {
  const fontStyle = { fontSize: `${size}px` };
  return (
    <FontAwesomeIcon icon={faLink} onClick={onClick} className={className} style={fontStyle} />
  )
}

export const Warning = ({ className, size, onClick, role }: Icon) => {
  const fontStyle = { fontSize: `${size}px` };
  return (
    <FontAwesomeIcon icon={faExclamationCircle} onClick={onClick} className={className} style={fontStyle} />
  )
}

export const Success = ({ className, size, onClick, role }: Icon) => {
  const fontStyle = { fontSize: `${size}px` };
  return (
    <FontAwesomeIcon icon={faCheckCircle} onClick={onClick} className={className} style={fontStyle} />
  )
}

export const ErrorIcon = ({ className, size, onClick, role }: Icon) => {
  const fontStyle = { fontSize: `${size}px` };
  return (
    <FontAwesomeIcon icon={faTimesCircle} onClick={onClick} className={className} style={fontStyle} />
  )
}

export const Exchange = ({ className, size, onClick, role }: Icon) => {
  const fontStyle = { fontSize: `${size}px` };
  return (
    <FontAwesomeIcon icon={faExchangeAlt} onClick={onClick} className={className} style={fontStyle} />
  )
}

export const Close = ({ className, size, onClick, role }: Icon) => {
  const fontStyle = { fontSize: `${size}px` };
  return (
    <FontAwesomeIcon icon={faTimes} onClick={onClick} className={className} style={fontStyle} />
  )
}

export const Search = ({ className, size, onClick, role }: Icon) => {
  const fontStyle = { fontSize: `${size}px` };
  return (
    <FontAwesomeIcon icon={faSearch} onClick={onClick} className={className} style={fontStyle} />
  )
}

export const Upload = ({ className, size, onClick, role }: Icon) => {
  const fontStyle = { fontSize: `${size}px` };
  return (
    <FontAwesomeIcon icon={faCloudUploadAlt} onClick={onClick} className={className} style={fontStyle} />
  )
}

export const Add = ({ className, size, onClick, role }: Icon) => {
  const fontStyle = { fontSize: `${size}px` };
  return (
    <FontAwesomeIcon icon={faPlus} onClick={onClick} className={className} style={fontStyle} />
  )
}
export const ChevronDown = ({ className, size, onClick, role }: Icon) => {
  const fontStyle = { fontSize: `${size}px` };
  return (
    <FontAwesomeIcon icon={faChevronDown} onClick={onClick} className={className} style={fontStyle} />
  )
}

export const ChevronRight = ({ className, size, onClick, role }: Icon) => {
  const fontStyle = { fontSize: `${size}px` };
  return (
    <FontAwesomeIcon icon={faChevronRight} onClick={onClick} className={className} style={fontStyle} />
  )
}

export const ArrowRight = ({ className, size, onClick, role }: Icon) => {
  const fontStyle = { fontSize: `${size}px` };
  return (
    <FontAwesomeIcon icon={faLongArrowRight} onClick={onClick} className={className} style={fontStyle} />
  )
}

export const Refresh = ({ className, size, onClick, role }: Icon) => {
  const fontStyle = { fontSize: `${size}px` };
  return (
    <FontAwesomeIcon icon={faRedo} onClick={onClick} className={className} style={fontStyle} />
  )
}

export const Edit = ({ className, size, onClick, role }: Icon) => {
  const fontStyle = { fontSize: `${size}px` };
  return (
    <FontAwesomeIcon icon={faPen} onClick={onClick} className={className} style={fontStyle} />
  )
}

export const Mobile = ({ className, size, onClick, role }: Icon) => {
  const fontStyle = { fontSize: `${size}px` };
  return (
    <FontAwesomeIcon icon={faMobileAlt} onClick={onClick} className={className} style={fontStyle} />
  )
}

export const Mail = ({ className, size, onClick, role }: Icon) => {
  const fontStyle = { fontSize: `${size}px` };
  return (
    <FontAwesomeIcon icon={faEnvelope} onClick={onClick} className={className} style={fontStyle} />
  )
}
