import * as React from 'react';
import { FormText } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import NotAuthorized from '../NotAuthorized/NotAuthorized';
import LoadingIndicator from '../../facade/LoadingIndicator/LoadingIndicator';
import { getBlankHref } from '../../helpers/urls';

export interface ErrorRendererProps {
    error?: any;
    displayErrorMessage?: boolean;
    onRetry?: () => void;
}

export default (props: ErrorRendererProps) => {
    const { error = {}, onRetry, displayErrorMessage } = props;
    let errorMessage = <FormattedMessage id="ERROR_TEXT" />;

    if (error.status === 401) {
        // show "loading..." while we wait for redirect to login
        return <LoadingIndicator text="SESSION_EXPIRED" />;
    }

    if (error.status === 403) {
        return <NotAuthorized labelId="NOT_AUTHORIZED" />
    }

    if (displayErrorMessage && error.message) {
        errorMessage = error.message;
    }

    return (
        <div className="mt-4">
            <h3><FormattedMessage id="ERROR_HEADING" /></h3>
            <FormText color="muted">
                <p>
                    {errorMessage}&nbsp;
                    {onRetry &&
                        <a href={getBlankHref()} onClick={(e) => { e.preventDefault(); onRetry(); }}><FormattedMessage id="ERROR_RETRY" /></a>
                    }
                </p>
                <p><FormattedMessage id="ERROR_CONTACT" /></p>
            </FormText>
        </div >
    );
};
