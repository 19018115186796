import { AccountAction } from '../../actions/account/account';
import { SET_ACCOUNT_DATA, LOAD_ACCOUNT } from '../../constants';
import { AccountData } from '../../models/account';

export function account(state: AccountData = { tenantId: '', id: '', clients: [], loadedFromToken: false, loadedFromDb: false }, action: AccountAction): AccountData {
    if (!action.error && action.payload) {
        switch (action.type) {
            case SET_ACCOUNT_DATA: {
                const { payload } = action;
                return {
                    ...state,
                    ...payload,
                    loadedFromToken: true,
                };
            }
            case LOAD_ACCOUNT: {
                const payload = action.payload as AccountData;

                return {
                    ...state,
                    ...payload,
                    totp: payload.totp || undefined,
                    loadedFromDb: true,
                };
            }
        }
    }
    return state;
}
