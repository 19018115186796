import { addLocaleData } from 'react-intl';
import * as queryString from 'query-string';
import enUS from './en-us';
import esUS from './es-us';
import * as en from 'react-intl/locale-data/en';
import * as es from 'react-intl/locale-data/es';

addLocaleData([...en, ...es]);

const allMessages = { enUS, esUS };

export const localeData = (() => {
    // TODO - Pass user's locale via JWT to be decoded and implemented here
    const queryParams = queryString.parse(window.location.search);
    let locale = 'en-US';
    if (queryParams && queryParams.locale) {
        locale = queryParams.locale;
    }
    const localeMessages = allMessages[locale.replace('-', '' )];
    return { locale, localeMessages };
})();
