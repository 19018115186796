/**
 * Simple abstract Map implementation that allows object keys, but requires subclasses
 * to provide a "keyToString" method to enforce the desired key uniqueness.
 */
export abstract class HashMap<K, V> {
  protected map: { [key: string]: V };

  protected abstract keyToString: (key: K) => string;

  constructor(copyFrom?: HashMap<K, V>) {
    this.map = (!copyFrom ? {} : copyFrom.map);
  }

  set = (key: K, value: V) => {
    this.map[this.keyToString(key)] = value;
    return this;
  }

  get = (key: K) => {
    return this.map[this.keyToString(key)];
  }

  has = (key: K) => {
    return Boolean(this.get(key));
  }
}
