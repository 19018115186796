import { State } from '@symbiotic/green-state';
import { REACT_APP_HR_SERVICES_API_URL } from '../../constants';
import { HttpClient } from '../../helpers/HttpClient';
import { getToken } from '../../helpers/tokens';

export class AdvancedHrDatabaseState extends State {
  static inject = [HttpClient];
  constructor(private httpClient: HttpClient) {
    super();
  }
  private databaseCache = {};

    startLoading = (action: string) => this.setState({ loading: { [action]: true } });
    endLoading = (action: string) => this.setState({ loading: { [action]: false } });
    toggleCreateConfirmModal = () => this.setState({ createConfirmModalOpen: !this.state.createConfirmModalOpen });
    toggleDeleteConfirmModal = () => this.setState({ deleteConfirmModalOpen: !this.state.deleteConfirmModalOpen });
    toggleCancelDeleteConfirmModal = () => this.setState({ cancelDeleteConfirmModalOpen: !this.state.cancelDeleteConfirmModalOpen });
    toggleUserCreationConfirmModal = () => this.setState({ userCreationConfirmModalOpen: !this.state.userCreationConfirmModalOpen });
    setIntegrationUser = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({ integrationUsername: event.target.value });
    setIntegrationPassword = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({ integrationPassword: event.target.value });
    handleMouseEnter = () => this.setState({ isPasswordVisible: true });
    handleMouseLeave = () => this.setState({ isPasswordVisible: false });

  checkForPreexistingDatabase = async ({ id: tenantId }: { id: string }) => {
    const action = 'checkingForDatabase';
    let status = {
      databaseExists: false,
      markedForDeletion: false,
      databaseTTL: undefined,
      integrationUserExists: false,
    };

    if (this.databaseCache[tenantId]) {
      return this.setState(this.databaseCache[tenantId]);
    }
    try {
      this.startLoading(action);
      this.setState({ databaseExists: false, error: null });
      const res = await this.httpClient.request({
        url: `${REACT_APP_HR_SERVICES_API_URL}/tenants/${tenantId}/connection-strings`,
        headers: {
          Authorization: `Bearer ${getToken()}`,
          Accept: 'application/json',
        },
      });
      if (res) {
        status = {
          databaseExists: true,
          markedForDeletion: !!res[0].TTL,
          databaseTTL: res[0].TTL,
          integrationUserExists: res[0].integrationUserExists,
        };
      }
      this.setState(status);
      this.databaseCache[tenantId] = status;
    } catch (e) {
      this.setState({ error: 'FAILED_TO_CHECK_FOR_DATABASE' });
    } finally {
      this.endLoading(action);
    }
  };

  createDatabaseIntegrationUser = async ({ id: tenantId }: { id: string }) => {
    console.log('creating integration user... for tenant: ' + tenantId);
    if (
      !this.state.integrationUsername?.length ||
      !this.state.integrationPassword?.length
    ) {
      alert('Input fields can not be blank!');
      return;
    }
    const action = 'creatingIntegrationUserCredentials';
    try {
      this.startLoading(action);
      this.setState({ error: null });
      await this.httpClient.request({
        url: `${REACT_APP_HR_SERVICES_API_URL}/tenants/${tenantId}/integration-user-credentials`,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${getToken()}`,
          Accept: 'application/json',
        },
        body: {
          username: this.state.integrationUsername,
          password: this.state.integrationPassword,
        },
      });
      this.setState({ integrationUserExists: true });
      this.databaseCache[tenantId] = { integrationUserExists: true };
      console.log('created integration user for tenant: ' + tenantId)
      console.log(this.state);
    } catch (e) {
      this.setState({ error: 'INTEGRATION_USER_CREATION_FAILED' });
    } finally {
      this.endLoading(action);
      this.toggleUserCreationConfirmModal();
    }
  };

  createDatabase = async ({
    id,
    name,
    subdomain,
  }: {
    id: string;
    name: string;
    subdomain: string;
  }) => {
    if (
      !this.state.integrationUsername?.length ||
      !this.state.integrationPassword?.length
    ) {
      alert('Input fields can not be blank!');
      return;
    }
    const action = 'creatingDatabase';
    try {
      this.startLoading(action);
      this.setState({ error: null });
      await this.httpClient.request({
        url: `${REACT_APP_HR_SERVICES_API_URL}/tenants`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${getToken()}`,
          Accept: 'application/json',
        },
        body: {
          id,
          name,
          subdomain,
          integrationUsername: this.state.integrationUsername,
          integrationUserPassword: this.state.integrationPassword,
        },
      });
      this.setState({ databaseExists: true });
      this.databaseCache[id] = { databaseExists: true };
      // updating integration user state
      this.setState({ integrationUserExists: true });
      this.databaseCache[id] = { integrationUserExists: true };
      console.log('created DB for tenant: ' + id)
      console.log('created integration user for tenant: ' + id)
    } catch (e) {
      this.setState({ error: 'ERROR_CREATING_DATABASE' });
    } finally {
      this.endLoading(action);
      this.toggleCreateConfirmModal();
    }
  };

  scheduleDatabaseDeletion = async (id: string, days: Number) => {
    const action = 'schedulingDatabaseDeletion';
    try {
      this.startLoading(action);
      this.setState({ error: null });
      let status = { markedForDeletion: false, databaseTTL: undefined };
      const res = await this.httpClient.request({
        url: `${REACT_APP_HR_SERVICES_API_URL}/tenants/${id}`,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${getToken()}`,
          Accept: 'application/json',
        },
        body: {
          patch: {
            op: 'remove',
            value: days,
          },
        },
      });
      if (res) {
        status = { markedForDeletion: true, databaseTTL: res.ttl };
      }
      this.setState(status);
      this.databaseCache[id] = status;
    } catch (e) {
      const errorObject = await e.json();
      let errorMessage = 'ERROR_SCHEDULING_DATABASE_DELETION';
      if (errorObject.code === 74) {
        errorMessage = 'TENANT_URL_STILL_EXISTS_MESSAGE';
      }
      this.setState({ error: errorMessage });
    } finally {
      this.endLoading(action);
      this.toggleDeleteConfirmModal();
    }
  };

  cancelDatabaseDeletion = async ({ id }: { id: string }) => {
    const action = 'cancellingDatabaseDeletion';
    try {
      this.startLoading(action);
      this.setState({ error: null });
      await this.httpClient.request({
        url: `${REACT_APP_HR_SERVICES_API_URL}/tenants/${id}`,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${getToken()}`,
          Accept: 'application/json',
        },
        body: {
          patch: {
            op: 'undo',
          },
        },
      });
      this.setState({ markedForDeletion: false, databaseTTL: undefined });
      this.databaseCache[id] = {
        markedForDeletion: false,
        databaseTTL: undefined,
      };
    } catch (e) {
      const errorObject = await e.json();
      let errorMessage = 'ERROR_CANCELLING_DATABASE_DELETION';
      if (errorObject.code === 74) {
        errorMessage = 'TENANT_URL_STILL_EXISTS_MESSAGE';
      }
      this.setState({ error: errorMessage });
    } finally {
      this.endLoading(action);
      this.toggleCancelDeleteConfirmModal();
    }
  };
}
