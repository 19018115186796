import * as React from 'react';

import { DEFAULT_TOKEN_NAME, SSO_DOMAIN } from '../../../constants';
import { getSSOToken } from '../../../helpers/tokens';

export default class Logout extends React.Component {

    async componentDidMount() {
        // clear bobafett session
        sessionStorage.removeItem(DEFAULT_TOKEN_NAME);

        // clear prev href so we go to the default landing page if we log back in
        window.sessionStorage.removeItem('oauth.previous-href');

        // redirect to heisenberg logout
        const { ssoToken } = await getSSOToken();
        window.open(`${SSO_DOMAIN}/signout?jwt=${ssoToken}`, '_self');
    }

    render() {
        return null;
    }
}
