import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import * as defaultLogo from '../../assets/asure-logo-black.png';
import Logo from './Logo/Logo';

export interface HeaderComponentProps {
    headingId: string,
    logo?: string,
    children: any
}

export default function Header({ headingId, logo = defaultLogo as any, children }: HeaderComponentProps) {
    return (
        <React.Fragment>
            <header className="navbar horizontal-nav border-bottom pl-3 py-0 pr-0" style={{ height: '55px' }}>
                <div className="row h-100 d-flex align-items-center flex-grow-1 mr-0">
                    <div className="col-9 h-100 d-flex flex-row align-items-center text-nowrap">
                        <Logo url={logo} />
                        <h5 className="mb-0 pl-4 pr-2 d-none d-sm-block"><FormattedMessage id={headingId} /></h5>
                        {children[0]}
                    </div>
                    <div className="col-3 h-100 d-flex justify-content-end">
                        {children[1]}
                    </div>
                </div>
            </header>
            <div className="row d-sm-none m-0 px-3 w-100 h-100 text-white bg-primary">
                <h5 className="my-3"><strong><FormattedMessage id={headingId} /></strong></h5>
            </div>
        </React.Fragment>
    );
}
