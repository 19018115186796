import * as React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';

import { isLast } from '../../../helpers/lists';
import { FormattedMessage } from 'react-intl';
import { withDependencies, Subscribe } from '@symbiotic/green-state';
import { IntegrationsStateFactory } from './IntegrationsStateFactory';
import LoadingIndicator from '../../../facade/LoadingIndicator/LoadingIndicator';
import ErrorRenderer from '../../../components/ErrorRenderer/ErrorRenderer';
import { InjectSelectedTenant } from '../Tenants/InjectSelectedTenant';

export interface IntegrationsPageProps {
    stateFactory?: IntegrationsStateFactory; // injected
}

@withDependencies({ stateFactory: IntegrationsStateFactory })
export default class IntegrationsPage extends React.Component<IntegrationsPageProps> {

    render() {
        const { stateFactory } = this.props;

        return (
            <InjectSelectedTenant>
                {({ selectedTenant: tenant }) => (
                    <Subscribe key={tenant.id} to={() => stateFactory!.getIntegrationsForTenant(tenant.id)}>
                        {({ integrations, isLoading, error }) => {
                            if (isLoading) {
                                return <LoadingIndicator />;
                            }

                            if (error) {
                                return <ErrorRenderer error={error} />;
                            }

                            return (
                                <div className="pt-4 mb-5 pr-3 mw-960">
                                    <h2><FormattedMessage id="INTEGRATIONS" /></h2>
                                    {integrations.map((integration, i, arr) => (
                                        <Card className={`${!isLast(i, arr) ? 'border-bottom-0' : ''}`} key={integration.id} data-id={integration.key}>
                                            <CardBody>
                                                <Row>
                                                    <Col xs="7" sm="8">
                                                        <h3 className="mb-0">{integration.name === 'AsureForce' ? 'Asure Time & Attendance' : integration.name}</h3>
                                                        <span className="text-para text-muted"> {integration.description}</span>
                                                    </Col>
                                                    <Col className="my-auto pr-4 text-right">
                                                        <Link className="text-para" to={`/integrations/${integration.key}`}>
                                                            View
                                                        </Link>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    ))}
                                </div>
                            );
                        }}
                    </Subscribe>
                )}
            </InjectSelectedTenant>
        );
    }
}
