import * as React from 'react';
import { Card, CardBody, Row, Col, FormText, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { AccountData } from '../../models';

export interface AccountDetailsProps {
    account: AccountData;
}

export default class AccountDetails extends React.Component<AccountDetailsProps> {
    render() {
        const { account } = this.props;
        return (
            <>
                <Card className="mt-4" data-page="user-details">
                    <CardBody>
                        <h3>
                            <FormattedMessage id="USER_DETAILS" />
                            <FormText color="muted" className="mt-3">
                                <FormattedMessage id="USER_DETAILS_HELP" />
                            </FormText>
                        </h3>
                        <hr className="d-lg-none" />
                        <Row className="mb-3">
                            <Col xs="12" lg="2"><Label><FormattedMessage id="USERNAME" /></Label></Col>
                            <Col className="text-muted">{account.username}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs="12" lg="2"><Label><FormattedMessage id="EMAIL_ADDRESS" /></Label></Col>
                            <Col className="text-muted">{account.email}</Col>
                        </Row>
                    </CardBody>
                </Card>
            </>
        );
    }
}
