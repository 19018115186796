import * as React from 'react';
import { FormattedMessage  } from 'react-intl';
import { Label } from 'reactstrap';

export interface NotAuthorizedComponentProps {
    labelId: string
}

export default function NotAuthorizedComponent({ labelId }: NotAuthorizedComponentProps) {
    return (
        <React.Fragment>
            <h3 className="pt-3"><FormattedMessage id="NOT_AUTHORIZED" /></h3>
            <Label><FormattedMessage id={labelId} /></Label>
        </React.Fragment>
    );
}
