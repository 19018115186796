import moment from 'moment';

export function formatBytes(bytes: number): string {
  if (bytes !== 0) {
      const bits = 1024,
          decimals = 2,
          sizes = ['Bytes', 'KB', 'MB'],
          int = Math.floor(Math.log(bytes) / Math.log(bits))
      ;

      return parseFloat((bytes / Math.pow(bits, int)).toFixed(decimals)) + ' ' + sizes[int];
  }

  return '0';
}

export function initCap(text: string) {
    if (!text) {
        return '';
    }
    return text.charAt(0).toUpperCase() + text.slice(1)
}

// returns date like "Jan. 21, 2018"
export function formatLongDate(date: any) {
    return moment(date).format('MMM. D, YYYY');
}

// returns "yesterday", "today", "tomorrow", or date like "Jan. 21" for everything else
export function formatShortDate(date: any) {
    const dateMoment = moment(date);
    if (moment().diff(dateMoment, 'days') >= 1) {
        return dateMoment.format('MMM. D');
    }
    return dateMoment.calendar().split(' ')[0].toLowerCase();
}

// returns time like '9:43 am'
export function formatTime(dateTime: any) {
    const timeMoment = moment(dateTime);
    return timeMoment.format('h:mm a')
}

// returns true if date is yesterday, today (or tomorrow)
export function isRecent(date: any) {
    return (Math.abs(moment().diff(moment(date), 'days')) < 1);
}

// returns true if date is in the future
export function isFuture(date: any) {
    return (moment().diff(moment(date)) < 0);
}
