import { LoadingAction } from '../../actions/loading/loading';
import { Loading } from '../../models';
import { SET_ASYNC_STATUS_ERROR, SET_ASYNC_STATUS_LOADING, SET_ASYNC_STATUS_LOADED, CLEAR_ASYNC_STATUS } from '../../constants/';

export function loading(state: Loading = { status: {}, error: {} }, action: LoadingAction): Loading {
    if (!action.error && action.payload) {
        const newLoadingState = { ...state };

        switch (action.type) {

            case SET_ASYNC_STATUS_LOADING:
                newLoadingState.status[action.payload] = 'loading';
                delete newLoadingState.error[action.payload];
                break;

            case SET_ASYNC_STATUS_LOADED:
                newLoadingState.status[action.payload] = 'complete';
                delete newLoadingState.error[action.payload];
                break;

            case SET_ASYNC_STATUS_ERROR:
                const { actionType, error } = action.payload;
                newLoadingState.status[actionType] = 'error';
                newLoadingState.error[actionType] = error;
                break;

            case CLEAR_ASYNC_STATUS:
                delete newLoadingState.status[action.payload];
                delete newLoadingState.error[action.payload];
                break;
        }

        return newLoadingState;
    }
    return state;
}
