import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';

export default function MyAccountLink() {
    return (
        <Link to="/account-settings">
            <DropdownItem className="btn-settings text-nowrap">
                <FormattedMessage id="ACCOUNT_SETTINGS"/>
            </DropdownItem>
        </Link>
    );
}
