import * as React from 'react';
import { Row, Col, Card, CardBody, Button, Fade, Alert } from 'reactstrap';
import { Exchange } from '../../facade/Icons/Icons';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useSubscription, useInstance } from '@symbiotic/green-state';

import { AdvancedHrDatabaseState } from './AdvancedHrDatabaseState';
import StatusIcon, { StatusIconState } from '../../facade/Icons/StatusIcon';
import StandardModal from '../StandardModal/StandardModal';
import { isAuthorizedTo } from '../../helpers/auth';

const AdvancedHrDatabase = () => {
  const advancedHrDatabaseState: AdvancedHrDatabaseState = useInstance(
    AdvancedHrDatabaseState
  );
  const { selectedTenant } = useSelector(
    (reduxState: any) => reduxState.tenants
  );
  const { account } = useSelector((reduxState: any) => reduxState);
  const state = useSubscription(() => {
    advancedHrDatabaseState.checkForPreexistingDatabase(selectedTenant);
    return advancedHrDatabaseState;
  }, selectedTenant.id);
  const deletionDays = 7;
  const deleteDate = new Date(
    new Date().setDate(new Date().getDate() + deletionDays)
  );

  if (!state) {
    return null;
  }

  const {
    loading: {
      checkingForDatabase,
      creatingIntegrationUserCredentials,
      creatingDatabase,
      schedulingDatabaseDeletion,
      cancellingDatabaseDeletion,
      creatingDBIntegrationUserCredentials,
    },
    error,
    databaseExists,
    markedForDeletion,
    databaseTTL,
    integrationUserExists,
    createConfirmModalOpen,
    deleteConfirmModalOpen,
    cancelDeleteConfirmModalOpen,
    userCreationConfirmModalOpen,
    integrationUsername,
    integrationPassword,
    isPasswordVisible,
  } = state;
  const canCreateDatabase =
    account &&
    isAuthorizedTo({ action: 'tenant:list-ahr-connection-strings' }, account) &&
    isAuthorizedTo(
      {
        action: 'tenant:add-ahr-database',
        resource: `tenants/${selectedTenant.id || account.tenantId}`,
      },
      account
    ) &&
    isAuthorizedTo(
      {
        action: 'tenant:add-role-membership',
        resource: `tenants/${selectedTenant.id || account.tenantId}`,
        params: {
          roleId: `${process.env.REACT_APP_GLOBAL_ADMIN_ROLE_ID}`,
        },
      },
      account
    );
  const actionDisabled = checkingForDatabase || !!error || !canCreateDatabase;
  const createButtonDisabled =
    databaseExists || creatingDatabase || actionDisabled;
  const defaultMessage = (
    <Fade className="d-flex align-items-center" timeout={250}>
      <Exchange size="20" className="status-icon align-middle text-info mr-2" />
      <FormattedMessage
        id="CHECKING_FOR_AHR_DATABASE"
        values={{ tenantName: selectedTenant.name }}
      />
    </Fade>
  );
  const cardMessage = databaseExists ? (
    markedForDeletion ? (
      <span className="d-flex align-items-center">
        <Exchange
          size="20"
          className="status-icon align-middle text-info mr-2"
        />
        <FormattedMessage
          id="DATABASE_DELETION_SCHEDULED"
          values={{
            tenantName: selectedTenant.name,
            date: new Date(databaseTTL * 1000).toLocaleDateString(),
            time: new Date(databaseTTL * 1000).toLocaleTimeString(),
          }}
        />
      </span>
    ) : (
      <span className="d-flex align-items-center m-1">
        <StatusIcon status={StatusIconState.OK} />
        <FormattedMessage
          id="DATABASE_CREATED"
          values={{ tenantName: selectedTenant.name }}
        />
      </span>
    )
  ) : (
    <span className="d-flex align-items-center">
      <StatusIcon status={StatusIconState.Disconnected} />
      <FormattedMessage
        id="NO_DATABASE_EXISTS"
        values={{ tenantName: selectedTenant.name }}
      />
    </span>
  );
  const integrationUserCreatedMsg = (
    <span className="d-flex align-items-center m-1">
      <StatusIcon status={StatusIconState.OK} />
      <FormattedMessage
        id="USER_CREATED"
        values={{ tenantName: selectedTenant.name }}
      />
    </span>
  );

  return (
    <React.Fragment>
      {isAuthorizedTo(
        { action: 'tenant:list-ahr-connection-strings' },
        account
      ) && (
        <React.Fragment>
          <Row>
            <Col md={12} lg={8}>
              <Card>
                {error && (
                  <Alert color="danger" className="rounded-0 mb-0">
                    <FormattedMessage
                      id={error}
                      values={{ tenantName: selectedTenant.name }}
                    />
                  </Alert>
                )}
                <CardBody>
                  <h4 className="d-flex align-items-center">
                    <span className="flex-grow-1">
                      <FormattedMessage id="CREATE_ADVANCED_HR_DATABASE" />
                    </span>
                    {databaseExists && !markedForDeletion && (
                      <Button
                        color="danger"
                        onClick={() =>
                          advancedHrDatabaseState.toggleDeleteConfirmModal()
                        }
                        disabled={schedulingDatabaseDeletion || actionDisabled}
                      >
                        <FormattedMessage id="DELETE" />
                      </Button>
                    )}
                    {databaseExists && markedForDeletion && (
                      <Button
                        color="primary"
                        onClick={() =>
                          advancedHrDatabaseState.toggleCancelDeleteConfirmModal()
                        }
                        disabled={cancellingDatabaseDeletion || actionDisabled}
                      >
                        <FormattedMessage id="CANCEL_DELETION" />
                      </Button>
                    )}
                  </h4>
                  {checkingForDatabase ? defaultMessage : cardMessage}
                  {databaseExists &&
                    integrationUserExists &&
                    integrationUserCreatedMsg}

                  {!integrationUserExists && !checkingForDatabase && (
                    <div className="mt-4">
                      <h1 className="d-flex align-items-center my-3 user-credentials-header ">
                        Add integration user Credentials
                      </h1>
                      <div>
                        <div className="form-group">
                          <label htmlFor="username">Username:</label>
                          <input
                            type="text"
                            id="username"
                            name="username"
                            className="form-control col-md-6"
                            value={integrationUsername || ''}
                            onChange={
                              advancedHrDatabaseState.setIntegrationUser
                            }
                            autoComplete="off"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="password">Password:</label>
                          <input
                            type={isPasswordVisible ? 'text' : 'password'}
                            id="password"
                            name="password"
                            className="form-control col-md-6"
                            value={integrationPassword || ''}
                            onChange={
                              advancedHrDatabaseState.setIntegrationPassword
                            }
                            onMouseEnter={
                              advancedHrDatabaseState.handleMouseEnter
                            }
                            onMouseLeave={
                              advancedHrDatabaseState.handleMouseLeave
                            }
                            autoComplete="off"
                          />
                        </div>
                        {!databaseExists ? (
                          <Button
                            color="primary"
                            onClick={() =>
                              advancedHrDatabaseState.toggleCreateConfirmModal()
                            }
                            disabled={createButtonDisabled}
                          >
                            <FormattedMessage id="CREATE" />
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            onClick={() =>
                              advancedHrDatabaseState.toggleUserCreationConfirmModal()
                            }
                            disabled={creatingDBIntegrationUserCredentials}
                          >
                            <FormattedMessage id="CREATE_INTEGRATION_USER" />
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
            <StandardModal
              isOpen={createConfirmModalOpen}
              disabled={createButtonDisabled}
              titleId="CREATE_ADVANCED_HR_DATABASE"
              modalClassName=""
              cancelButtonId="CANCEL"
              confirmButtonId="OK_CREATE"
              onCancel={() =>
                advancedHrDatabaseState.toggleCreateConfirmModal()
              }
              onConfirm={async () =>
                await advancedHrDatabaseState.createDatabase(selectedTenant)
              }
            >
              <div>
                <FormattedMessage id="PUBLISH_TENANT_CHANGES_MESSAGE" />
              </div>
            </StandardModal>
            <StandardModal
              isOpen={deleteConfirmModalOpen}
              disabled={schedulingDatabaseDeletion}
              titleId="DELETE_ADVANCED_HR_DATABASE"
              modalClassName=""
              cancelButtonId="CANCEL"
              confirmButtonId="I_AM_SURE"
              onCancel={() =>
                advancedHrDatabaseState.toggleDeleteConfirmModal()
              }
              onConfirm={async () =>
                await advancedHrDatabaseState.scheduleDatabaseDeletion(
                  selectedTenant.id,
                  deletionDays
                )
              }
              confirmButtonColor="danger"
            >
              <div>
                <FormattedMessage
                  id="DELETE_CONFIRMATION_MESSAGE"
                  values={{
                    tenantName: selectedTenant.name,
                    date: deleteDate.toLocaleDateString(),
                    time: deleteDate.toLocaleTimeString(),
                  }}
                />
              </div>
            </StandardModal>
            <StandardModal
              isOpen={cancelDeleteConfirmModalOpen}
              disabled={cancellingDatabaseDeletion}
              titleId="CANCEL_ADVANCED_HR_DATABASE_DELETION"
              modalClassName=""
              cancelButtonId="GO_BACK"
              confirmButtonId="I_AM_SURE"
              onCancel={() =>
                advancedHrDatabaseState.toggleCancelDeleteConfirmModal()
              }
              onConfirm={async () =>
                await advancedHrDatabaseState.cancelDatabaseDeletion(
                  selectedTenant
                )
              }
            >
              <div>
                <FormattedMessage
                  id="CANCEL_DELETE_CONFIRMATION_MESSAGE"
                  values={{ tenantName: selectedTenant.name }}
                />
              </div>
            </StandardModal>
            <StandardModal
              isOpen={userCreationConfirmModalOpen}
              disabled={creatingIntegrationUserCredentials}
              titleId="CREATE_ADVANCED_HR_INTEGRATION_USER"
              modalClassName=""
              cancelButtonId="CANCEL"
              confirmButtonId="OK_CREATE"
              onCancel={() =>
                advancedHrDatabaseState.toggleUserCreationConfirmModal()
              }
              onConfirm={async () =>
                await advancedHrDatabaseState.createDatabaseIntegrationUser(
                  selectedTenant
                )
              }
            >
              <div>
                <FormattedMessage id="HR_INTEGRATION_USER_CONFIRMATION" />
              </div>
            </StandardModal>
          </Row>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default AdvancedHrDatabase;
