import * as React from 'react';
import { Alert } from 'reactstrap';

import './Toast.scss';

export interface ToastProps {
    color?: string,
    isOpen: boolean,
    toggle?: () => void,
    children: any,
}

export default (props: ToastProps) => {
    const { color, isOpen, toggle, children } = props;
    return (
        <div className={`toast position-fixed d-flex w-100 justify-content-center ${isOpen ? 'open' : ''}`}>
            <Alert color={color} isOpen={isOpen} toggle={toggle}>
                {children}
            </Alert>
        </div>
    );
};
