export const CLEAR_SSO_DATA = 'CLEAR_SSO_DATA';
export type CLEAR_SSO_DATA = typeof CLEAR_SSO_DATA;
export const CLEAR_ASYNC_STATUS = 'CLEAR_ASYNC_STATUS';
export type CLEAR_ASYNC_STATUS = typeof CLEAR_ASYNC_STATUS;
export const CLEAR_VERIFICATION_CODE = 'CLEAR_VERIFICATION_CODE';
export type CLEAR_VERIFICATION_CODE = typeof CLEAR_VERIFICATION_CODE;
export const DELETE_ACCOUNT_TOTP = 'DELETE_ACCOUNT_TOTP';
export type DELETE_ACCOUNT_TOTP = typeof DELETE_ACCOUNT_TOTP;
export const REQUEST_SSO_TOKEN = 'REQUEST_SSO_TOKEN';
export type REQUEST_SSO_TOKEN = typeof REQUEST_SSO_TOKEN;
export const REQUEST_EVO_TOKEN = 'REQUEST_EVO_TOKEN';
export type REQUEST_EVO_TOKEN = typeof REQUEST_EVO_TOKEN;
export const LOAD_ACCOUNT = 'LOAD_ACCOUNT';
export type LOAD_ACCOUNT = typeof LOAD_ACCOUNT;
export const LOAD_TENANTS = 'LOAD_TENANTS';
export type LOAD_TENANTS = typeof LOAD_TENANTS;
export const SAVE_SSO_DATA = 'SAVE_SSO_DATA';
export type SAVE_SSO_DATA = typeof SAVE_SSO_DATA;
export const SELECT_TENANT = 'SELECT_TENANT';
export type SELECT_TENANT = typeof SELECT_TENANT;
export const SET_ACCOUNT_DATA = 'SET_ACCOUNT_DATA';
export type SET_ACCOUNT_DATA = typeof SET_ACCOUNT_DATA;
export const SET_ASYNC_STATUS_ERROR = 'SET_ASYNC_STATUS_ERROR';
export type SET_ASYNC_STATUS_ERROR = typeof SET_ASYNC_STATUS_ERROR;
export const SET_ASYNC_STATUS_LOADED = 'SET_ASYNC_STATUS_LOADED';
export type SET_ASYNC_STATUS_LOADED = typeof SET_ASYNC_STATUS_LOADED;
export const SET_ASYNC_STATUS_LOADING = 'SET_ASYNC_STATUS_LOADING';
export type SET_ASYNC_STATUS_LOADING = typeof SET_ASYNC_STATUS_LOADING;
export const UPDATE_ACCOUNT_TOTP = 'UPDATE_ACCOUNT_TOTP';
export type UPDATE_ACCOUNT_TOTP = typeof UPDATE_ACCOUNT_TOTP;
export const UPDATE_TENANT = 'UPDATE_TENANT';
export type UPDATE_TENANT = typeof UPDATE_TENANT;
export const UPLOAD_TENANT_LOGO = 'UPLOAD_TENANT_LOGO';
export type UPLOAD_TENANT_LOGO = typeof UPLOAD_TENANT_LOGO;
export const EMAIL_TOTP_CODE = 'EMAIL_TOTP_CODE';
export type EMAIL_TOTP_CODE = typeof EMAIL_TOTP_CODE;
export const FORCE_CONNECT = 'FORCE_CONNECT';
export type FORCE_CONNECT = typeof FORCE_CONNECT;
export const TOGGLE_MODE = 'TOGGLE_MODE';
export type TOGGLE_MODE = typeof TOGGLE_MODE;
export const CREATE_TENANT = 'CREATE_TENANT';
export type CREATE_TENANT = typeof CREATE_TENANT;
export const LOAD_VERIFICATION_URL = 'LOAD_VERIFICATION_URL';
export type LOAD_VERIFICATION_URL = typeof LOAD_VERIFICATION_URL;
export const ED_CODE_PAYTYPES = 'ED_CODE_PAYTYPES';
export type ED_CODE_PAYTYPES = typeof ED_CODE_PAYTYPES;
export const LOAD_PAYGROUPS = 'LOAD_PAYGROUPS';
export type LOAD_PAYGROUPS = typeof LOAD_PAYGROUPS;
export const LOAD_EMPLOYEES = 'LOAD_EMPLOYEES';
export type LOAD_EMPLOYEES = typeof LOAD_EMPLOYEES;
export const UPDATE_EMPLOYEES = 'UPDATE_EMPLOYEES';
export type UPDATE_EMPLOYEES = typeof UPDATE_EMPLOYEES;
export const LOAD_FORCE_EMPLOYEES = 'LOAD_FORCE_EMPLOYEES';
export type LOAD_FORCE_EMPLOYEES = typeof LOAD_FORCE_EMPLOYEES;
export const LOAD_ALL_EMPLOYEE_MAPPINGS = 'LOAD_ALL_EMPLOYEE_MAPPINGS';
export type LOAD_ALL_EMPLOYEE_MAPPINGS = typeof LOAD_ALL_EMPLOYEE_MAPPINGS;
export const SAVE_ALL_EMPLOYEE_MAPPINGS = 'SAVE_ALL_EMPLOYEE_MAPPINGS';
export type SAVE_ALL_EMPLOYEE_MAPPINGS = typeof SAVE_ALL_EMPLOYEE_MAPPINGS;
