import { BASE_URL } from '../../../constants';
import { HttpClient } from '../../../helpers/HttpClient';
import { getToken } from '../../../helpers/tokens';
import { Integration } from '../../../models';

export class IntegrationsService {

    static inject = [ HttpClient ];
    constructor(private httpClient: HttpClient) {}

    private assignKeys = (integrations: any[]): Integration[] => {
        return integrations.map(i => ({ ...i, key: i.name.toLowerCase().replace(' ', '-') }));
    };

    getIntegrations = async ({ tenantId }: { tenantId: string }): Promise<Integration[]> => {
        return await this.httpClient.request({
            url: `${BASE_URL}/api/integrations?only-visible=true&tenantId=${tenantId}`,
            headers: {
                Authorization: `Bearer ${getToken()}`,
                Accept: 'application/json',
            },
        }).then(this.assignKeys);
    }
}
