import * as React from 'react';
import { AccountData } from '../../../models';
import { AccountsService } from './AccountsService';
import { withDependencies } from '@symbiotic/green-state';
import { getUserDataFromToken, getToken } from '../../../helpers/tokens';

interface InjectAccountsState {
    accounts: AccountData[];
    isLoading: boolean;
    error?: Error;
}

interface InjectAccountsProps {
    tenantId: string;
    accountsService?: AccountsService; // injected
    children: (props: InjectAccountsRenderProps) => any;
}

interface InjectAccountsRenderProps extends InjectAccountsState {}

const serviceBureauOnlyAccounts = (account: AccountData) => Boolean(account.evoSbUserId);

@withDependencies({ accountsService: AccountsService })
export class InjectAccounts extends React.Component<InjectAccountsProps, InjectAccountsState> {
    state = {
        accounts: [] as AccountData[],
        error: undefined,
        isLoading: true,
    }

    async componentDidMount() {
        const { tenantId } = this.props;
        const isCurrentUserSBUser = Boolean(getUserDataFromToken(getToken()).evoSbUserId);

        try {
            let accounts = await this.props.accountsService!.getAccounts({ tenantId });

            // we need to filter down the list of accounts to just service bureau accounts
            // when the authenticated user is a SB user (not an admin) so that they don't get confused
            // by all the company users they might see (until we can provide a better UX for that).
            // NOTE: payroll web users have `evoSbUserId` and so they will also show up in the list.
            if (isCurrentUserSBUser) {
                accounts = accounts.filter(serviceBureauOnlyAccounts);
            }

            accounts = accounts.sort(sortAccounts);
            this.setState({
                accounts,
                isLoading: false,
            })
        } catch (error) {
            this.setState({
                error,
                isLoading: false,
            });
        }
    }

    componentWillUnmount() {
        this.setState = () => {};
    }

    render() {
        const { children } = this.props;
        return children(this.state);
    }
}


function sortAccounts(left: AccountData, right: AccountData) {
    if (left.givenName === right.givenName) {
        return (left.surname || '').localeCompare(right.surname || '');
    }
    return (left.givenName || '').localeCompare(right.givenName || '');
}
