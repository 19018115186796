import * as React from 'react';
import { Input, InputProps, FormGroup, FormFeedback, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

export interface RenderFieldProps {
    invalid?: boolean;
    disabled?: boolean;
}
export interface ControlledInputComponentProps {
    name?: string;
    type?: InputProps['type'];
    label: string;
    placeholder?: string;
    errorMessage?: string | JSX.Element;
    value?: string;
    invalid?: boolean;
    disabled?: boolean;
    columnConfig: {
        default: number;
        sm?: number;
        md?: number;
        lg?: number;
        xl?: number;
    };
    required?: boolean;
    onChange?: (object: { [key: string]: any }) => void;
    renderField?: ({ invalid, disabled }: RenderFieldProps) => JSX.Element;
}

export default function ControlledInput (
    {
        name,
        type,
        label,
        placeholder,
        errorMessage,
        value = undefined,
        invalid,
        columnConfig,
        disabled,
        required,
        onChange = () => {},
        renderField,
    }: ControlledInputComponentProps
) {
    const formGroupClasses = Object.keys(columnConfig).map((size) => {
        if (size === 'default') {
            return `col-${columnConfig[size]}`;
        }
        return `col-${size}-${columnConfig[size]}`;
    });
    const inputName = name || label.replace(/\s+/g, '').toLowerCase();
    const requiredIndicator = required ? <span className="required-indicator text-danger">*</span> : null;
    const field = renderField ? (
        renderField({ invalid, disabled })
    ) : (
        <Input
            name={inputName}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            required={required}
            onChange={(e) => onChange({ [`${inputName}`]: e.target.value })}
            invalid={invalid}
            value={value}
        />
    )
    return (
        <FormGroup className={[...formGroupClasses, ...['position-relative', 'form-control-sm']].join(' ')}>
            <Label for={inputName}><FormattedMessage id={label}/> {requiredIndicator}</Label>
            {field}
            <FormFeedback className="position-absolute">
                {errorMessage ||
                    <FormattedMessage id="CUSTOM_FORM_VALIDATION_ERROR" values={{ FIELD_NAME: <FormattedMessage id={label}/> }}/>
                }
            </FormFeedback>
        </FormGroup>
    );
}
