import './polyfills';

import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { ReduxStore } from './store/ReduxStore';
import { DependencyContainerContext, Container } from '@symbiotic/green-state';

import App from './containers/App/App';
import { configureStore } from './store';

import { IntlProvider } from 'react-intl';
import { localeData } from './i18n/locale';

import './sass/main.scss';
import { AccountSetupApp } from './containers/AccountSetupApp/AccountSetupApp';

const STORAGE_KEY = 'selectedTenantId';

// use selectedTenantId from sessionStorage as initial state
const initialState: any = {
    tenants: { selectedTenant: { id: sessionStorage.getItem(STORAGE_KEY) } },
};

const store = configureStore(initialState);

// save selectedTenantId to sessionStorage whenever it changes
store.subscribe(() => {
    const savedId = sessionStorage.getItem(STORAGE_KEY);
    const selectedTenant = store.getState().tenants.selectedTenant;
    if (selectedTenant && selectedTenant.id !== savedId) {
        sessionStorage.setItem(STORAGE_KEY, selectedTenant.id);
    }
});

const isAccountSetupFlow = () => {
    return window.location.pathname === '/account-setup/mfa';
}

class AppDependencyContainerContext extends DependencyContainerContext {
    async containerMounted(container: Container) {
        container.registerInstance(ReduxStore, store);
    }
}

// Remove the loading page now that our app has loaded
(window as any).loadingPage.doneLoading();

ReactDOM.render(
    <AppDependencyContainerContext>
        <IntlProvider locale={localeData.locale} messages={localeData.localeMessages}>
            <Provider store={store as any}>
                {isAccountSetupFlow() ? <AccountSetupApp /> : <App />}
            </Provider>
        </IntlProvider>
    </AppDependencyContainerContext>,
    document.getElementById('root') as HTMLElement
);
