import { SAVE_SSO_DATA, CLEAR_SSO_DATA, BASE_URL, UPLOAD_TENANT_LOGO, TOGGLE_MODE } from '../../constants';
import { GenericAction, TenantUploadRequest, SsoData, TenantUploadResponse } from '../../models';
import { request } from '../../helpers/http';
import { getToken } from '../../helpers/tokens';
import { createAsyncAction } from '../async/async';
import { authFallback } from '../authentication/authentication';

export interface SaveSsoData extends GenericAction<SsoData> {
    type: SAVE_SSO_DATA;
}

export interface ClearSsoData extends GenericAction<void> {
    type: CLEAR_SSO_DATA;
}

export interface UploadTenantLogo extends GenericAction<TenantUploadResponse> {
    type: UPLOAD_TENANT_LOGO;
}

export interface ToggleMode extends GenericAction<any> {
    type: TOGGLE_MODE;
}

export type SsoAction = SaveSsoData | ClearSsoData | UploadTenantLogo | ToggleMode;

export function saveSsoData(payload: SsoData) {
    return {
        type: SAVE_SSO_DATA,
        payload,
    }
}

export function clearSsoData() {
    return {
        type: CLEAR_SSO_DATA,
    }
}

export function uploadTenantLogo(payload: TenantUploadRequest) {
    return createAsyncAction(UPLOAD_TENANT_LOGO, () => request({
        url: `${BASE_URL}/identity/tenants/${payload.id}/uploads`,
        headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': 'application/json' },
        method: 'POST',
        body: payload.body,
    }), authFallback);
}

export function toggleMode(mode: string) {
    return {
        type: TOGGLE_MODE,
        payload: mode,
    }
}
