import * as React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, CardFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

interface MfaProps {
    isModal?: boolean;
}

export interface MfaContainerProps extends MfaProps {
    id?: string;
    hasOverlay?: boolean;
    onCancel: () => void;
}

const renderSection = (children: any, isModal: boolean) => {
    // copy "isModal" from parent to each child before rendering
    return React.Children.map(children, (child: any) => React.cloneElement(child, { isModal }));
}

export const MfaContainer: React.SFC<MfaContainerProps> = ({ isModal, id, hasOverlay, onCancel, children }) => {
    if (isModal) {
        return (
            <Modal id={id} modalClassName={`drawer ${hasOverlay ? '' : 'overlay'}`} isOpen={true} toggle={() => onCancel()}>
                <ModalHeader toggle={() => onCancel()}><FormattedMessage id="MFA_DRAWER_TITLE" /></ModalHeader>
                {renderSection(children, true)}
            </Modal>
        );
    } else {
        return (
            <Card id={id}>
                {renderSection(children, false)}
            </Card>
        );
    }
};

export const MfaBody: React.SFC<MfaProps> = ({ isModal, children }) => {
    if (isModal) {
        return <ModalBody>{children}</ModalBody>;
    } else {
        return <CardBody>{children}</CardBody>;
    }
}

export const MfaFooter: React.SFC<MfaProps> = ({ isModal, children }) => {
    if (isModal) {
        return <ModalFooter>{children}</ModalFooter>;
    } else {
        return <CardFooter><div className="setup-buttons">{children}</div></CardFooter>;
    }
}

