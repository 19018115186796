import * as React from 'react';
import { Tenant } from '../../../models';
import { InjectReduxState } from '../../InjectReduxState';

interface InjectSelectedTenantProps {
    children: (props: InjectSelectedTenantRenderProps) => any;
}

interface InjectSelectedTenantRenderProps {
    selectedTenant: Tenant;
}

export class InjectSelectedTenant extends React.Component<InjectSelectedTenantProps> {
    render() {
        const { children } = this.props;

        return (
            <InjectReduxState>
                {(state) => {
                    const selectedTenant = state.tenants.selectedTenant as Tenant;
                    return children({ selectedTenant });
                }}
            </InjectReduxState>
        );
    }
}
