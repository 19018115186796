import * as base32 from 'hi-base32';
import { getRandomValues } from './crypto';
import * as authenticator from 'authenticator';
import { ISecurityRequest, SecurityPolicyAuthorizer } from '@asuresoftware/asure.auth';

import { AccountData } from '../models';

const ASURE_ADMIN_SCOPE = 'https://www.asuresoftware.com/iam/asure-admin';
const INTEGRATIONS_WRITE_SCOPE = 'https://www.asuresoftware.com/iam/integrations.write';
const INTEGRATIONS_READ_SCOPE = 'https://www.asuresoftware.com/iam/integrations.read';

/**
 * Generates and encodes a secret key.
 */
export function generateOtpSecret(): string {
    const secret = getRandomValues(new Uint8Array(20));
    return base32.encode(secret);
}

export function generateOtpCode(secret: string): string {
    return authenticator.generateToken(secret);
}

/**
 * Generates an otpauth URI.
 */
export function buildOtpAuthUri(user: string, issuer: string, secret: string): string {
    return encodeURI(`otpauth://totp/${issuer}:${user}?secret=${secret}&issuer=${issuer}`);
}

function isAsureAdmin(account?: AccountData): boolean {
    if (!account || !account.scope) {
        return false;
    }
    return account.scope.some((s) => s === ASURE_ADMIN_SCOPE);
}

export function isAuthorizedTo(securityRequest: ISecurityRequest, account?: AccountData): boolean {
    if (account && account.policy) {
        return new SecurityPolicyAuthorizer(account.policy).isAuthorizedTo(securityRequest);
    }

    return isAsureAdmin(account);
}

/**
 * Determine whether the authenticated user has access to "SSO Configuration" in the sidebar.
 */
export function isAuthorizedToSsoConfiguration({ account }: { account?: AccountData }) {
    return isAuthorizedTo({ action: 'tenant:create' }, account) || isAuthorizedTo({ action: 'tenant:list' }, account);
};

/**
 * Determine whether the authenticated user has access to "Integrations" in the sidebar.
 * This determination should be done via policies, but policies are not yet used for integrations
 * Once they are, we can update this method to use SecurityPolicyAuthorizer
 */
export function isAuthorizedToIntegrations({ account }: { account?: AccountData }): boolean {
    return (
        hasScope(INTEGRATIONS_WRITE_SCOPE, account) ||
        hasScope(INTEGRATIONS_READ_SCOPE, account) ||
        isAsureAdmin(account)
    );
}

/**
 * Determine whether the authenticated user has permission to edit integrations ("write" access).
 * This determination should be done via policies, but policies are not yet used for integrations
 * Once they are, we can update this method to use SecurityPolicyAuthorizer
 */
export function isAuthorizedToEditIntegrations({ account }: { account?: AccountData }): boolean {
    return (
        hasScope(INTEGRATIONS_WRITE_SCOPE, account) ||
        isAsureAdmin(account)
    );
}

/**
 * Determine whether the authenticated user has access to "Users" in the sidebar.
 * If an asure admin user has selected a tenant from the drop-down, its ID should be provided.
 * Otherwise, this evaluation uses the authenticated user's tenantId.
 */
export function isAuthorizedToAccounts({ account, selectedTenantId }: { account?: AccountData, selectedTenantId?: string }) {
    return account && isAuthorizedTo({ action: 'tenant:list-accounts', resource: `tenants/${selectedTenantId || account.tenantId}` }, account);
};

/**
 * Determine whether the authenticated user has access to "Verification" in the sidebar.
 * If an asure admin user has selected a tenant from the drop-down, its ID should be provided.
 * Otherwise, this evaluation uses the authenticated user's tenantId.
 */
export function isAuthorizedToVerification({ account, selectedTenantId }: { account?: AccountData, selectedTenantId?: string }) {
    return account && isAuthorizedTo({ action: 'verificationCode:create', resource: `tenants/${selectedTenantId || account.tenantId}` }, account);
};


/**
 * Determine whether the authenticated user has access to "Advanced HR" in the sidebar.
 * If an asure admin user has selected a tenant from the drop-down, its ID should be provided.
 * Otherwise, this evaluation uses the authenticated user's tenantId.
 */
export function isAuthorizedToAdvancedHr({ account, selectedTenantId }: { account?: AccountData, selectedTenantId?: string }) {
    return account && (isAuthorizedTo({ action: 'tenant:list-ahr-connection-strings' }, account) || isAsureAdmin(account));
};


function hasScope(scope: string, account?: AccountData) {
    if (!account || !account.scope) {
        return false;
    }

    return Boolean(account.scope.some(s => s === scope));
}
