import * as React from 'react';
import Fuse from 'fuse.js';
import { Input } from 'reactstrap';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { Search, Close } from '../../facade/Icons/Icons';

import './FilterSearch.scss'

export interface Identified { id: string | number; }

/**
 * This component implements fuzzy searching using an input box and a filtered list.
 * It can be used in two modes:
 * 1) The consumer provides an implementation to generate a list item (generateListItem)
 * and a callback to invoke when an item is selected (selectItem). In this mode, the
 * currently selected item is tracked by the component (selectedItem).
 * 2) The consumer provides an implementation of the entire filtered list (renderList).
 * In this mode, the props selectItem, generateListItem, and selectedItem are unused.
 */
export interface FilterSearchComponentProps<T> {
    searchData: Array<T>;
    searchKeys: Array<string>;
    searchHeaderText?: string;
    placeholderId?: string;
    renderList?: (items: Array<T>, searchQuery: string) => any;
    selectItem?: (item: T) => void;
    generateListItem?: (item: T, highlight: any) => string;
    selectedItem?: T;
    inputClassName?: string;
}
export interface FilterSearchComponentState<T> {
    searchQuery: string;
    searchResults: Array<T>;
    showSearch: boolean;
}

class FilterSearch<T extends Identified> extends React.Component<FilterSearchComponentProps<T> & InjectedIntlProps, FilterSearchComponentState<T>> {

    constructor(props: FilterSearchComponentProps<T> & InjectedIntlProps) {
        super(props);
        this.state = {
            searchQuery: '',
            searchResults: props.searchData,
            showSearch: false,
        };

        this.queryData = this.queryData.bind(this);
        this.highlightRelevantData = this.highlightRelevantData.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps: FilterSearchComponentProps<T> & InjectedIntlProps) {
        const currentState = this.state;
        this.setState({
            ...currentState,
            showSearch: false,
            searchResults: nextProps.searchData,
        });
    }

    queryData = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { searchData, searchKeys } = this.props;
        const { value } = event.target;
        const options = {
            keys: searchKeys,
            minMatchCharLength: 2,
            threshold: 0.3,
        };
        const fuse = new Fuse(searchData, options);
        const state = this.state;
        this.setState({
            ...state,
            searchResults: value ? fuse.search(value) : searchData,
            searchQuery: value,
        });
    };

    clearSearch = () => {
        const state = this.state;
        this.setState({
            ...state,
            searchResults: this.props.searchData,
            searchQuery: '',
        });
    };

    highlightRelevantData = (value: string) => {
        const { searchQuery } = this.state;
        if (searchQuery && value && value.toString().indexOf(searchQuery) >= 0) {
            return `<strong className="text-dark">${value}</strong>`;
        }
        return value;
    };

    render() {
        const { searchResults, searchQuery } = this.state;
        const { renderList, selectItem, generateListItem, selectedItem, placeholderId, inputClassName } = this.props;
        let filteredResults = null;

        if (searchResults) {
            if (renderList) {
                // if renderList provided, the consumer is responsible for building the whole list
                filteredResults = renderList(searchResults, searchQuery);
            } else {
                filteredResults = (
                    <div className="search-results-wrapper d-flex flex-column">
                        <ul className="search-results list-group">
                            {searchResults.map((item, i) => {
                                const listClassNames = ['list-group-item', 'border-left-0', 'border-right-0'];
                                if (selectedItem && item.id === selectedItem.id) {
                                    listClassNames.push('selected');
                                }
                                return (
                                    <li
                                        key={`search-item-${i}`}
                                        className={listClassNames.join(' ')}
                                        onClick={() => { selectItem!(item) }}
                                    >
                                        <span dangerouslySetInnerHTML={{ __html: generateListItem!(item, (value: any) => this.highlightRelevantData(value)) }} />
                                    </li>
                                )
                            }
                            )}
                        </ul>
                    </div>
                );
            }
        }

        return (
            <div className="filter-search-component h-100">
                <div className="search-container w-100 border-0 shadow-none bg-white d-flex flex-column">
                    <div className={`search-input-wrapper w-100 py-3 position-relative ${inputClassName || ''}`}>
                        <Search size="16" className="search-icon position-absolute text-muted" />
                        <Input
                            type="text"
                            name="search"
                            placeholder={this.props.intl.formatMessage({ id: placeholderId || 'SEARCH' })}
                            onChange={this.queryData}
                            className="search-input"
                            value={this.state.searchQuery}
                        />
                        <Close
                            size="16"
                            className="search-icon-clear position-absolute text-muted"
                            onClick={this.clearSearch}
                            role="button"
                        />
                    </div>
                    {filteredResults}
                </div>
            </div>
        );
    }
}

// This extra casting is necessary because the injectIntl HOC doesn't handle generics correctly.
// Without it, the array passed to renderList() is Identified[] instead of T[].
export default injectIntl(FilterSearch, { withRef: true }) as any as <T extends Identified>(props: FilterSearchComponentProps<T>) => any;
