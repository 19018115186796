import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { LoadingIndicator as StrataLoadingIndicator } from '@asuresoftware/asure.design-system';

export interface Props {
    text?: string;

    /**
     * true when the loading indicator is contained within a parent
     * that it should be rendered within. Otherwise it is centered on
     * the viewport.
     */
    contained?: boolean;
}

export default function LoadingIndicator({ text, contained }: Props) {
    const positionClass = contained ? 'position-relative' : 'd-flex w-100 h-100 align-items-center justify-content-center';
    return (
        <div className={positionClass}>
            <StrataLoadingIndicator
                className="loading-wrapper"
                data-cy="loading-indicator"
            >
                <FormattedMessage id={text || 'LOADING'} />
            </StrataLoadingIndicator>
        </div>
    );
}
