import { LOAD_TENANT_SETTINGS, SAVE_TENANT_SETTINGS } from '../../actions/integrations/tenantSettingsMap';
import { Action } from 'redux-actions';
import { TenantSettingsMap } from '../../models';

const initialState = new TenantSettingsMap();

export function tenantSettingsMap(state: TenantSettingsMap = initialState, action: Action<any>): TenantSettingsMap {
    if (!action.error && action.type && action.payload) {
        switch (action.type) {

            case LOAD_TENANT_SETTINGS: {
                const { payload } = action;
                const { key, tenantSettings } = payload;
                return new TenantSettingsMap(state).set(key, tenantSettings);
            }

            case SAVE_TENANT_SETTINGS: {
                const { payload } = action;
                const { tenantId, integrationId } = payload;
                return new TenantSettingsMap(state).set({ tenantId, integrationId }, payload);
            }
        }
    }
    return state;
}
