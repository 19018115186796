import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../reducers';

class InjectReduxStateBase extends React.Component<{ children: (props: RootState) => any, reduxState: RootState}> {
    render() {
        return this.props.children(this.props.reduxState as RootState);
    }
}

const InjectReduxState = connect(state => ({ reduxState: state }))(InjectReduxStateBase);

export { InjectReduxState };
