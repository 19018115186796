import { tenants } from './tenants/tenants';
import { loading } from './loading/loading';
import { authentication } from './authentication/authentication';
import { account } from './account/account';
import { sso } from './sso/sso';
import { verification } from './verification/verification';
import { configurationsMap } from './integrations/configurationsMap';
import { tenantSettingsMap } from './integrations/tenantSettingsMap';
import { logsMap } from './integrations/logsMap';
import { companiesByTenant } from './integrations/companiesByTenant';
import { edCodesByCompany } from './integrations/edCodesByCompany';
import { Tenants, Loading, Authentication, AccountData, SsoData, Integration, TenantSettingsMap, ConfigurationsMap, LogsMap, CompaniesByTenant, EDCodesByCompany } from '../models';
import { combineReducers, Reducer } from 'redux';

interface StoreEnhancerState {}

export interface RootState extends StoreEnhancerState {
    tenants: Tenants;
    loading: Loading;
    authentication: Authentication;
    account: AccountData;
    sso: SsoData;
    integrations: Integration[];
    tenantSettingsMap: TenantSettingsMap;
    configurationsMap: ConfigurationsMap;
    logsMap: LogsMap;
    companiesByTenant: CompaniesByTenant;
    edCodesByCompany: EDCodesByCompany;
    verification: any;
}

export const rootReducer: Reducer<RootState> = combineReducers<RootState>({
    tenants,
    loading,
    authentication,
    account,
    sso,
    tenantSettingsMap,
    configurationsMap,
    logsMap,
    companiesByTenant,
    edCodesByCompany,
    verification,
});
