import { SET_ASYNC_STATUS_ERROR, SET_ASYNC_STATUS_LOADING, SET_ASYNC_STATUS_LOADED, CLEAR_ASYNC_STATUS } from '../../constants';
import { GenericAction, FailurePayload } from '../../models';

export type SET_ASYNC_STATUS_OK = SET_ASYNC_STATUS_LOADING | SET_ASYNC_STATUS_LOADED | CLEAR_ASYNC_STATUS;

export interface Loading extends GenericAction<string> {
    type: SET_ASYNC_STATUS_OK;
}

export interface Failed extends GenericAction<FailurePayload> {
    type: SET_ASYNC_STATUS_ERROR;
}

export type LoadingAction = Loading | Failed;

export function isLoading(actionType: string): Loading {
    return {
        type: SET_ASYNC_STATUS_LOADING,
        payload: actionType,
    };
}

export function isLoaded(actionType: string): Loading {
    return {
        type: SET_ASYNC_STATUS_LOADED,
        payload: actionType,
    };
}

export function isFailed(actionType: string, error?: any): Failed {
    return {
        type: SET_ASYNC_STATUS_ERROR,
        payload: { actionType, error },
    };
}

export function clearLoadingState(actionType: string): Loading {
    return {
        type: CLEAR_ASYNC_STATUS,
        payload: actionType,
    };
}
