import * as React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

export interface StandardModalProps {
    isOpen: boolean;
    toggle?: () => void;
    disabled: boolean;
    titleId: string;
    confirmButtonId?: string;
    cancelButtonId?: string;
    abortButtonId?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
    onAbort?: () => void;
    confirmButtonColor?: string;
    children: any;
    modalClassName: string;
}

export default (props: StandardModalProps) => {
    const { isOpen, toggle, disabled, titleId, confirmButtonId, cancelButtonId, abortButtonId, onConfirm, onCancel, onAbort, children, modalClassName, confirmButtonColor } = props;
    return (
        <Modal modalClassName={modalClassName} isOpen={isOpen} toggle={toggle} id="modal-confirm">
            <ModalHeader className="p-4 border-bottom-0">
                <span className="h3 mb-0 font-weight-normal"><FormattedMessage id={titleId} /></span>
            </ModalHeader>
            <ModalBody className="px-4">
                {children}
            </ModalBody>
            <ModalFooter className="border-top-0">
                {abortButtonId &&
                    <Button
                        color="danger mr-auto ml-0"
                        className="btn-abort"
                        onClick={onAbort || toggle}
                        disabled={disabled}
                    >
                        <FormattedMessage id={abortButtonId} />
                    </Button>
                }
                {cancelButtonId &&
                    <Button
                        color="white"
                        className="btn-cancel mr-3"
                        onClick={onCancel || toggle}
                        disabled={disabled}
                    >
                        <FormattedMessage id={cancelButtonId} />
                    </Button>
                }
                {confirmButtonId &&
                    <Button
                        color={confirmButtonColor || 'primary'}
                        className="btn-confirm"
                        onClick={onConfirm || toggle}
                        disabled={disabled}
                    >
                        <FormattedMessage id={confirmButtonId} />
                    </Button>
                }
            </ModalFooter>
        </Modal>
    );
};
