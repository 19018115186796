import * as React from 'react';
import { Alert } from 'reactstrap';
import Dropzone from 'react-dropzone';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import './UploadDropzone.scss';
import { ImageFile } from 'react-dropzone';
import { Upload } from '../../../facade/Icons/Icons';

import { LogoUploadPayload } from '../../../models';
import { ACCEPTED_IMAGE_TYPES } from '../../../constants';
import { getBlankHref } from '../../../helpers/urls';

export interface UploadDropzoneComponentProps {
    onFileDrop: (data: any) => any;
    disabled: boolean;
}
export interface UploadDropzoneComponentState {
    accepted: Array<ImageFile | any>;
    rejected: Array<ImageFile | any>;
    alertVisible: boolean;
}

export default class UploadDropzone extends React.Component<UploadDropzoneComponentProps, UploadDropzoneComponentState> {
    constructor(props: UploadDropzoneComponentProps) {
        super(props);

        this.state = {
            accepted: [],
            rejected: [],
            alertVisible: false,
        }
    }
    encodeImage(file: any) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                const encodedFile = event.target.result;
                resolve({
                    file,
                    ...{ encodedFile },
                });
            };
            reader.readAsDataURL(file);
        });

    }
    onDropAccepted(accepted: Array<ImageFile>) {
        this.setState({
            accepted,
            rejected: [],
            alertVisible: false,
        });
        this.encodeImage(accepted[0]).then((logoUpload: LogoUploadPayload) => {
            this.props.onFileDrop(logoUpload);
        });
    }

    onDropRejected(rejected: Array<ImageFile>) {
        this.setState({
            accepted: [],
            rejected,
            alertVisible: true,
        });
    }

    onDismissAlert = () => {
        const state = this.state;
        this.setState({
            ...state,
            alertVisible: false,
        });
    };

    render() {
        const { disabled } = this.props;
        const { rejected } = this.state;
        const uploadAlert = rejected.length > 0
            ? (
                <Alert
                    color="danger"
                    isOpen={this.state.alertVisible}
                    toggle={this.onDismissAlert}
                >
                    <span className="text-danger d-flex align-items-center">
                        <FormattedHTMLMessage id="INVALID_FILE_TYPE"/>
                    </span>
                </Alert>
            )
            : null
        ;
        const dropzoneClasses = ['dropzone', 'bg-light'];
        if (disabled) {
            dropzoneClasses.push('disabled');
        }
        const dropzoneContentClasses = [
            'w-100', 'h-25', 'border-gray', 'd-flex', 'align-items-center',
            'flex-column', 'justify-content-center', 'position-relative', 'p-3',
        ];
        return (
            <div>
                {uploadAlert}
                <div className={dropzoneClasses.join(' ')}>
                    <Dropzone
                        onDropAccepted={(acceptedFile) => {
                            this.onDropAccepted(acceptedFile);
                        }}
                        onDropRejected={(rejectedFile) => {
                            this.onDropRejected(rejectedFile);
                        }}
                        className={dropzoneContentClasses.join(' ')}
                        activeClassName="drag-over"
                        accept={ACCEPTED_IMAGE_TYPES}
                        disabled={disabled}
                        multiple={false}
                    >
                        <Upload className="upload-icon" size="72" />
                        <h4 className="text-center font-weight-light mb-0"><FormattedMessage id="DROP_AN_IMAGE_HERE"/></h4>
                        <p>
                            <FormattedMessage id="OR"/>
                            <span> <a href={getBlankHref()} onClick={(e) => e.preventDefault()}><FormattedMessage id="CHOOSE_A_FILE"/></a></span>
                        </p>
                    </Dropzone>
                </div>
                <div className="pt-2 recommended-file-specs">
                    <p className="text-center pt-2 text-muted small">
                        <FormattedHTMLMessage id="RECOMMENDED_IMAGE_SIZE_MESSAGE"/>
                    </p>
                </div>
            </div>
        );
    }
}
