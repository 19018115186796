import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { Button, Alert, Input } from 'reactstrap';
import { MfaContainer, MfaBody, MfaFooter } from './MfaContainer';
import { AccountData, EmailTotpData } from '../../models';
import MfaCodeVerification from './MfaCodeVerification';
import { generateOtpSecret } from '../../helpers/auth';
import { getBlankHref } from '../../helpers/urls';
import { ErrorIcon } from '../../facade/Icons/Icons';

export interface MfaSetupEmailProps {
    loading: any;
    account: AccountData;
    onEnableMfa: ({ secret, totpCode, useEmailOnly }: { secret: string, totpCode: string, useEmailOnly: boolean }) => void;
    onEmailTotpCode: (payload: EmailTotpData) => void;
    onCancel: () => void;
    isModal?: boolean;
}
export interface MfaSetupEmailState {
    secret: string,
    totpCode: string,
    formIsValid: boolean,
}

class MfaSetupEmail extends React.Component<MfaSetupEmailProps & InjectedIntlProps, MfaSetupEmailState> {
    UNSAFE_componentWillMount(): void {
        const secret = generateOtpSecret();

        this.setState(
            { secret, totpCode: '', formIsValid: false },
            () => this.sendTotpCodeEmail()
        );
    }

    sendTotpCodeEmail() {
        const { account, onEmailTotpCode } = this.props;
        const { secret } = this.state;

        const payload = {
            tenantId: account.tenantId,
            username: account.username!,
            secret,
        };

        onEmailTotpCode(payload);
    }

    onSubmit(e: any) {
        e.preventDefault();
        const { secret, totpCode } = this.state;
        this.props.onEnableMfa({ secret: secret, totpCode: totpCode, useEmailOnly: true });
    }

    onTotpCodeChange({ isValid, totpCode }: { isValid: boolean, totpCode: string }) {
        this.setState({ formIsValid: isValid, totpCode });
    }

    render() {
        const loadState = this.props.loading.status.UPDATE_ACCOUNT_TOTP;
        const { secret } = this.state;

        return (
            <MfaContainer isModal={this.props.isModal} onCancel={() => this.props.onCancel()} id="setup-mfa-email">
                <Alert color="danger" isOpen={loadState === 'error'}>
                    <ErrorIcon className="mr-2 align-middle" size="16" />
                    <FormattedMessage id="VERIFICATION_CODE_DID_NOT_MATCH" />
                </Alert>
                <MfaBody>
                    <h4><FormattedMessage id="MFA_VERIFY_NAME"/></h4>
                    <p><FormattedMessage id="MFA_EMAIL_VERIFY_DESCRIPTION" values={{ ACCOUNT_EMAIL: this.props.account.email }}/></p>
                    <form onSubmit={e => this.onSubmit(e)}>
                        <Input
                            type="text"
                            className="totp-secret-for-copy"
                            value={secret}
                            readOnly={true}
                            hidden={true}
                        />
                        <MfaCodeVerification
                            label="MFA_TOTP_VERIFY_EMAIL_LABEL"
                            onTotpCodeChange={(e) => this.onTotpCodeChange(e)}
                        />
                    </form>
                    <p>
                        <FormattedMessage id="MFA_EMAIL_RESEND_CODE_PROMPT" />&nbsp;
                        <a href={getBlankHref()} onClick={() => this.sendTotpCodeEmail()}><FormattedMessage id="MFA_EMAIL_RESEND_CODE" /></a>
                    </p>
                </MfaBody>
                <MfaFooter>
                    <Button color="white" disabled={loadState === 'loading'} onClick={() => this.props.onCancel()}>
                        <FormattedMessage id="CANCEL" />
                    </Button>
                    <Button color="primary" disabled={loadState === 'loading' || !this.state.formIsValid} onClick={(e: any) => this.onSubmit(e)}>
                        <FormattedMessage id="ENABLE" />
                    </Button>
                </MfaFooter>
            </MfaContainer>
        )
    }
}

export default injectIntl(MfaSetupEmail, { withRef: true });
