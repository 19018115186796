import { LOAD_VERIFICATION_URL, BASE_URL, CLEAR_VERIFICATION_CODE } from '../../constants';
import { GenericAction } from '../../models';
import { createAsyncAction } from '../async/async';
import { request } from '../../helpers/http';
import { getToken, getApplicationIdFromToken } from '../../helpers/tokens';
import { authFallback } from '../authentication/authentication';

export interface LoadVerificationCode extends GenericAction<any> {
    type: LOAD_VERIFICATION_URL;
}

export interface ClearVerificationCode extends GenericAction<any> {
    type: CLEAR_VERIFICATION_CODE;
}

export type VerificationAction = LoadVerificationCode | ClearVerificationCode;

export function loadVerificationCode(tenantId: string) {
    const token = getToken();
    const applicationId = getApplicationIdFromToken(token);
    return createAsyncAction(LOAD_VERIFICATION_URL, () => request({
        url: `${BASE_URL}/identity/tenants/${tenantId}/verification-codes`,
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
        method: 'POST',
        body: { applicationId },
    }), authFallback);
}

export function clearVerificationCode() {
    return {
        type: CLEAR_VERIFICATION_CODE,
    };
}
