import { SsoData, ConfigurationModes } from '../../models';
import { SsoAction } from '../../actions/sso/sso';
import { SAVE_SSO_DATA, CLEAR_SSO_DATA, UPLOAD_TENANT_LOGO, TOGGLE_MODE } from '../../constants';

const initialState = {
    configurationMode: ConfigurationModes.View,
}

export function sso(state: SsoData = initialState, action: SsoAction): SsoData | null {
    if (!action.error) {
        switch (action.type) {
            case SAVE_SSO_DATA: {
                const { payload } = action;
                return {
                    ...state,
                    ...payload,
                };
            }
            case CLEAR_SSO_DATA: {
                return initialState;
            }
            case UPLOAD_TENANT_LOGO: {
                const { payload } = action;
                return {
                    ...state,
                    logo: payload.filename,
                }
            }
            case TOGGLE_MODE: {
                const { payload } = action;
                return {
                    ...state,
                    configurationMode: payload,
                }
            }
        }
    }
    return state;
}
