import * as React from 'react';
import { AccountData } from '../../../models';
import { InjectReduxState } from '../../InjectReduxState';

interface InjectAuthenticatedAccountProps {
    children: (props: InjectAuthenticatedAccountRenderProps) => any;
}

interface InjectAuthenticatedAccountRenderProps {
    account: AccountData;
}

export class InjectAuthenticatedAccount extends React.Component<InjectAuthenticatedAccountProps> {
    render() {
        const { children } = this.props;

        return (
            <InjectReduxState>
                {(state) => {
                    const account = state.account as AccountData;
                    return children({ account });
                }}
            </InjectReduxState>
        );
    }
}
