export const DEFAULT_TOKEN_NAME = 'access_token';
export const APP_ID = process.env.REACT_APP_APPLICATION_ID;
export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const SSO_DOMAIN = process.env.REACT_APP_SSO_DOMAIN;
export const DEFAULT_TENANT_ID = process.env.REACT_APP_DEFAULT_TENANT_ID;
export const EVO_APP_ID = process.env.REACT_APP_EVO_APP_ID;
export const TLM_SYNCHRONIZATIONS_APP_ID = process.env.REACT_APP_TLM_SYNCHRONIZATIONS_APP_ID;
export const TENANT_SEARCH_KEYS = ['name', 'serialNumber', 'subdomain'];
export const TENANT_BUCKET = process.env.REACT_APP_TENANT_BUCKET;
export const ACCEPTED_IMAGE_TYPES = 'image/jpeg, image/png, image/gif';
export const REACT_APP_MFA_WEB_SETUP_COMPLETE_URL = process.env.REACT_APP_MFA_WEB_SETUP_COMPLETE_URL as string;
export const REACT_APP_HR_SERVICES_API_URL = process.env.REACT_APP_HR_SERVICES_API_URL;
