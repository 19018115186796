import * as React from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { ChevronDown } from '../../../facade/Icons/Icons';

import { AccountData } from '../../../models';
import './AccountDropdown.scss';
import LogOutLink from '../LogOutLink/LogOutLink';
import MyAccountLink from '../MyAccountLink/MyAccountLink';

export interface Props {
    account?: AccountData;
}

export interface State {
    dropdownOpen: boolean;
}

export default class AccountDropdown extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            dropdownOpen: false,
        };
    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
    };

    render() {
        if (this.props.account) {
            const { givenName } = this.props.account;
            return (
                <ButtonDropdown
                    isOpen={this.state.dropdownOpen}
                    toggle={this.toggle}
                    className="account-dropdown h-100 w-100 d-flex justify-content-end"
                >
                    <DropdownToggle
                        caret={false}
                        color="header-nav"
                        className="account-dropdown-content px-0 border-0 d-flex align-items-center"
                    >
                        <span className="pr-2">{givenName}</span>
                        <ChevronDown size="10" className="text-muted mx-2" />
                    </DropdownToggle>
                    <DropdownMenu className="border-top-0 px-2">
                        <MyAccountLink />
                        <LogOutLink />
                    </DropdownMenu>
                </ButtonDropdown>
            );
        }
        return null;
    }
}
