import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

// Components
import AdminHeader from '../../components/Header/AdminHeader';
import Sidebar from '../../components/Sidebar/Sidebar';
import LoadingIndicator from '../../facade/LoadingIndicator/LoadingIndicator';
import ErrorRenderer from '../../components/ErrorRenderer/ErrorRenderer';

// Miscellaneous
import { ApplicationState, Tenants, Tenant, AccountData, SsoData } from '../../models';

// Actions
import * as TenantActions from '../../actions/tenants/tenants';
import * as SsoActions from '../../actions/sso/sso';
import * as AccountActions from '../../actions/account/account';
import * as VerificationActions from '../../actions/verification/verification';

export interface AdminLayoutProps {
    loadTenants: () => Tenants;
    setAccountData: (accountData: AccountData | object) => AccountData | object;
    selectTenant: (tenantId: string) => string;
    toggleMode: (mode: string) => void;
    tenants: Tenants;
    loading: boolean;
    error: boolean;
    account?: AccountData;
    sso: SsoData;
}

class AdminLayout extends React.Component<AdminLayoutProps | null> {

    componentDidMount() {
        const { loadTenants } = this.props;
        loadTenants();
    }

    render() {
        const { loadTenants, loading, error, tenants, account, selectTenant, toggleMode, sso: { configurationMode } } = this.props;
        const tenantsList: Array<Tenant> = tenants.results;

        if (loading) {
            return (
                <div className="vh-100 vw-100 d-flex align-items-center justify-content-center">
                    <LoadingIndicator />
                </div>
            );
        }

        if (error) {
            return (
                <ErrorRenderer error={error} onRetry={loadTenants} />
            )
        }

        return (
           <div className="app-wrapper">
                <AdminHeader
                    tenants={tenantsList}
                    account={account}
                    selectTenant={selectTenant}
                    selectedTenant={tenants.selectedTenant}
                    toggleMode={toggleMode}
                    configurationMode={configurationMode}
                />
                <div className="container-fluid">
                    <div className="row">
                        <div className="sidebar col-2 col-sm-4 col-md-3 col-lg-3 col-xl-2 pl-0">
                        <Sidebar account={account}/>
                        </div>
                        <div className="col-10 col-sm-8 col-md-9 col-lg-9 col-xl-10 vh-100">
                            {this.props.children}
                        </div>
                    </div>
               </div>
           </div>
        );
    }
}

export default connect<any>(({ tenants, loading, account, sso }: ApplicationState) => ({
    tenants,
    loading: loading && loading.status.LOAD_TENANTS === 'loading',
    error: loading && loading.error.LOAD_TENANTS,
    account,
    sso,
}), (dispatch: Dispatch<any>) => ({
    loadTenants: () => dispatch(TenantActions.loadTenants()),
    setAccountData: (accountData: AccountData | object) => dispatch(AccountActions.setAccountData(accountData)),
    selectTenant: (tenantId: string) => {
        dispatch(TenantActions.selectTenant(tenantId));
        dispatch(VerificationActions.clearVerificationCode());
    },
    toggleMode: (mode: string) => dispatch(SsoActions.toggleMode(mode)),
}))(AdminLayout);
