import * as queryString from 'query-string';

import { getSSOToken, getTenantSubdomainFromToken } from './tokens';
import { getTenantSubdomainFromUrl } from './urls';
import { ErrorService } from '../containers/App/ErrorService';

// For backward compatibility, support ?tenantId or ?_tenantId in place of subdomain.
// Returns true on error/redirect, indicating processing of current page should abort.
// DEPRECATED: Remove this file when no longer in use by legacy links.
export async function handleTenantIdParam(): Promise<boolean> {
    const { protocol, host, pathname, hash, search } = window.location;

    const { tenantId, _tenantId, ...otherParams } = queryString.parse(search);
    const tenantIdParam = tenantId || _tenantId || undefined;

    if (!tenantIdParam) {
        return false;
    }

    // we don't support both subdomain and tenantId
    if (getTenantSubdomainFromUrl()) {
        new ErrorService().showNotFound();
        return true;
    }

    // derive subdomain from tenantId by requesting an sso token
    let subdomain;
    try {
        const { ssoToken } = await getSSOToken(tenantIdParam);
        subdomain = getTenantSubdomainFromToken(ssoToken);
    } catch (e) {
        // must be an invalid tenantId
        new ErrorService().showNotFound();
        return true;
    }

    // remove tenantId, add subdomain, redirect
    const qs = Object.keys(otherParams).length === 0 ? '' : `?${queryString.stringify(otherParams)}`;
    window.location.replace(`${protocol}//${subdomain}.${host}${pathname}${qs}${hash}`);
    return true;
}
