import * as React from 'react';
import { Warning, Success, ErrorIcon } from './Icons';

export interface StatusIconProps {
    className?: string;
    status: StatusIconState;
}

export enum StatusIconState {
    Error,
    OK,
    Warn,
    Disconnected,
}

export default class StatusIcon extends React.Component<StatusIconProps> {
    getStatusClass() {
        switch (this.props.status) {
            case StatusIconState.Error: return 'text-danger';
            case StatusIconState.OK: return 'text-success';
            case StatusIconState.Warn: return 'text-warning';
            case StatusIconState.Disconnected: return 'text-muted';
        }
    }

    getStatusIcon() {
        switch (this.props.status) {
            case StatusIconState.Error: return ErrorIcon;
            case StatusIconState.OK: return Success;
            case StatusIconState.Warn: return Warning;
            case StatusIconState.Disconnected: return ErrorIcon;
        }
    }

    render() {
        const Icon = this.getStatusIcon();
        return (
            <Icon
                className={`status-icon align-middle mr-2 ${this.props.className || ''} ${this.getStatusClass()}`}
                size="20"
            />
        );
    }
}
