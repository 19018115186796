import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Button } from 'reactstrap';

import NotAuthorized from '../../../components/NotAuthorized/NotAuthorized';

import VerificationData from '../../../components/Forms/VerificationData/VerificationData';
import { ApplicationState, Tenants, Verification } from '../../../models';
import { FormattedMessage } from 'react-intl';
import * as VerificationActions from '../../../actions/verification/verification';
import * as LoadingActions from '../../../actions/loading/loading';
import { LOAD_VERIFICATION_URL } from '../../../constants';

export interface VerificationContainerProps {
    loadVerificationCode: (tenantId: string) => void;
    verification: Verification;
    tenants: Tenants;
    loading: any;
}

const resetState = (dispatch: any) => {
    dispatch(LoadingActions.clearLoadingState(LOAD_VERIFICATION_URL));
};

class VerificationContainer extends React.Component<VerificationContainerProps | null> {
    render() {
        const { loadVerificationCode, tenants, verification, loading: { loadingVerificationCode } } = this.props;
        const isLoading = loadingVerificationCode === 'loading';

        if (!tenants.results || !tenants.selectedTenant) {
            return null;
        }

        if (tenants.results.length === 0) {
            return <NotAuthorized labelId="NO_BUREAUS" />;
        }

        const verificationFields = verification && verification.code ? <VerificationData {...verification} /> : undefined;

        return (
            <div className="py-3">
                <h2 className="h4"><FormattedMessage id="VERIFICATION" /></h2>
                <h6 className="font-weight-bold"><FormattedMessage id="VERIFY_SERVICE_ACCOUNT_TITLE" /></h6>
                <p><FormattedMessage id="VERIFY_SERVICE_ACCOUNT_DESCRIPTION" /><br/>
                    <FormattedMessage id="VERIFICATION_NOT_STORED_DESCRIPTION" />
                </p>
                <div className="py-2">
                    <Button
                        color="primary"
                        onClick={() => {
                            loadVerificationCode(tenants.selectedTenant!.id);
                        }}
                        disabled={isLoading}
                    >
                        <FormattedMessage id="GENERATE_CODE_AND_URL" />
                    </Button>
                </div>
                {verificationFields}
            </div>
        );
    }
}

export default connect(({ tenants, verification, loading }: ApplicationState) => ({
    verification,
    tenants,
    loading: {
        loadingVerificationCode: loading.status.LOAD_VERIFICATION_URL,
    },
}), (dispatch: Dispatch<any>) => ({
    loadVerificationCode: (tenantId: string) => {
        dispatch(VerificationActions.loadVerificationCode(tenantId) as any).then(() => {
            resetState(dispatch);
        })
    },
}))(VerificationContainer);
