import * as React from 'react';
import { Button,  Alert } from 'reactstrap';
import { MfaContainer, MfaBody, MfaFooter } from './MfaContainer';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Success } from '../../facade/Icons/Icons';

export interface MfaConfirmSetupProps {
    onConfirmed: () => void;
    selectedSetupType?: 'device' | 'email';
    isModal?: boolean;
}

class MfaConfirmSetup extends React.Component<MfaConfirmSetupProps> {
    render() {
        const { selectedSetupType } = this.props;

        const authEnabledMessage = selectedSetupType === 'device'
            ? <FormattedHTMLMessage id="MFA_DEVICE_ENABLED_MESSAGE"/>
            : <FormattedHTMLMessage id="MFA_EMAIL_ENABLED_MESSAGE"/>;

        const authForgotMessage = selectedSetupType === 'device'
            ? <FormattedMessage id="MFA_DEVICE_FORGOT_MESSAGE"/>
            : <FormattedMessage id="MFA_EMAIL_FORGOT_MESSAGE"/>;

        return (
            <MfaContainer isModal={this.props.isModal} onCancel={() => this.props.onConfirmed()} id="setup-mfa-enabled">
                <Alert color="success">
                    <h4 className="d-sm-none alert-heading m-3 text-center">
                        <div className="d-flex w-100 mb-4">
                            <Success className="mx-auto align-middle" size="72" />
                        </div>
                        <FormattedMessage id="MFA_ENABLED" />
                    </h4>
                    <h4 className="d-none d-sm-block alert-heading m-3">
                        <Success className="align-middle mr-3" size="20" />
                        <FormattedMessage id="MFA_ENABLED" />
                    </h4>
                </Alert>
                <MfaBody>
                    <p className="mt-2 mx-4">
                        {authEnabledMessage}
                    </p>
                    <p className="mt-4 mx-4">
                        {authForgotMessage}
                    </p>
                </MfaBody>
                <MfaFooter>
                    <Button color="primary" onClick={() => this.props.onConfirmed()}>
                        <FormattedMessage id="OK_GOT_IT" />
                    </Button>
                </MfaFooter>
            </MfaContainer>
        )
    }
}

export default MfaConfirmSetup;
