import * as React from 'react';
import { Tenant } from '../../models';
import { FormattedMessage } from 'react-intl';

import moment from 'moment';

export interface TenantDetailsComponentProps {
    selectedTenant: Tenant;
}

export default function TenantDetail({ selectedTenant }: TenantDetailsComponentProps) {
    const { createdBy, createdAt, modifiedBy, modifiedAt } = selectedTenant;
    const detailsArray = [];
    if (createdBy && createdAt) {
        detailsArray.push(
            {
                label: 'CONFIGURATION_CREATED_ON',
                date: createdAt,
                username: createdBy && createdBy.username,
                type: 'created',
            }
        );
    }
    if (modifiedBy && modifiedAt) {
        detailsArray.push(
            {
                label: 'LAST_MODIFIED_ON',
                date: modifiedAt,
                username: modifiedBy.username,
                type: 'modified',
            }
        );
    }

    const details = detailsArray.map((item: any) => {
        return (
            <div key={item.type} className="my-4">
                <h6 className="d-block font-weight-normal mb-2"><FormattedMessage id={item.label}/></h6>
                <span className="d-block" title={`${moment.utc(item.date).format('MM-DD-YYYY, hh:mm:ss')} UTC`}>
                    {moment(item.date).format('MM-DD-YYYY, hh:mm:ss A')}
                </span>
                <span className="d-block text-muted"><FormattedMessage id="BY"/> {item.username}</span>
            </div>
        );
    });

    return (
        <div className="details-content border-left pl-3 pt-3 h-100">
            <h4 className="pt-3"><FormattedMessage id="DETAILS"/></h4>
            {details}
        </div>
    );
}
