import * as React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

import Views from '../../containers/Views';
import AdminLayout from '../../containers/App/AdminLayout';
import { isAuthorizedToSsoConfiguration, isAuthorizedToIntegrations, isAuthorizedToAccounts, isAuthorizedToVerification, isAuthorizedToAdvancedHr } from '../../helpers/auth';
import { AccountData } from '../../models';

const getRootRedirect = (location: any, account?: AccountData): { pathname: string, search?: string } => {
    if (isAuthorizedToSsoConfiguration({ account })) {
        return { pathname: '/sso-configuration', search: location.search };
    }

    if (isAuthorizedToIntegrations({ account })) {
        return { pathname: '/integrations' };
    }

    if (isAuthorizedToAccounts({ account })) {
        return { pathname: '/accounts' };
    }

    if (isAuthorizedToVerification({ account })) {
        return { pathname: '/verification' };
    }

    if (isAuthorizedToAdvancedHr({ account })) {
        return { pathname: '/advanced-hr' };
    }

    return { pathname: '/account-settings' };
}

// helper for custom "My Account" behavior until everything uses portal header
export const isAccountSettings = () => window.location.pathname === '/account-settings';

export interface Props {
    account: AccountData,
}

export default function AppRouter({ account }: Props) {
    return (
        <Router>
            <Switch>
                <Route path="/" exact={true} render={({ location }) => <Redirect to={getRootRedirect(location, account)} />} />
                <Route path="/logout" exact={true} component={Views.Logout}/>
                <Route
                    render={(layoutProps: any) => (
                        <AdminLayout {...layoutProps}>
                            <Switch>
                                <Route path="/account-settings" exact={true} component={Views.AccountSettings}/>
                                <Route path="/sso-configuration" exact={true} component={Views.SsoConfiguration}/>
                                <Route path="/integrations" exact={true} component={Views.IntegrationsPage}/>
                                <Route
                                    path="/integrations/:integrationKey/:param1?/:param2?"
                                    exact={false}
                                    component={(props: any) => (
                                        <Views.IntegrationPage
                                            integrationKey={props.match.params.integrationKey}
                                            param1={props.match.params.param1}
                                            param2={props.match.params.param2}
                                        />
                                    )}
                                />
                                <Route path="/accounts" exact={true} component={Views.AccountListingPage}/>
                                <Route path="/accounts/:username" exact={true} component={(props: any) => <Views.AccountDetailsPage username={props.match.params.username} />} />
                                <Route path="/verification" exact={true} component={Views.Verification}/>
                                <Route path="/advanced-hr" exact={true} component={Views.AdvancedHrSettingsPage}/>
                            </Switch>
                        </AdminLayout>
                    )}
                />
            </Switch>
        </Router>
    );
}
