import * as React from 'react';
import { Alert, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { ChevronRight } from '../../../facade/Icons/Icons';
import LoadingIndicator from '../../../facade/LoadingIndicator/LoadingIndicator';
import { InjectAccount } from './InjectAccount';
import AccountDetails from '../../../components/AccountDetails/AccountDetails';
import AccountRoles from '../../../components/AccountRoles/AccountRoles';
import ErrorRenderer from '../../../components/ErrorRenderer/ErrorRenderer';
import { FormattedMessage } from 'react-intl';
import { InjectSelectedTenant } from '../Tenants/InjectSelectedTenant';
import { Link } from 'react-router-dom';

interface AccountDetailsPageProps {
    username: string;
}

export default class AccountDetailsPage extends React.Component<AccountDetailsPageProps> {
    render() {
        const { username } = this.props;

        return (
            <InjectSelectedTenant>
                {({ selectedTenant }) => (
                    <InjectAccount key={`${selectedTenant.id}-${username}`} tenantId={selectedTenant.id} username={username}>
                        {({ account, error, isLoading }) => {

                            if (isLoading) {
                                return (
                                    <LoadingIndicator />
                                );

                            }

                            if (error) {
                                return (
                                    <ErrorRenderer error={error} />
                                );
                            }

                            if (!account) {
                                return (
                                    <div className="pt-4 mb-5 pr-3 mw-960">
                                        <h2 className="pb-2"><FormattedMessage id="USER_DETAILS" /></h2>
                                        <Alert color="danger"><FormattedMessage id="USER_NOT_FOUND" values={{ USERNAME: username }} /></Alert>
                                    </div>
                                );
                            }

                            return (
                                <>
                                    <Breadcrumb>
                                        <BreadcrumbItem><Link to="/accounts"><FormattedMessage id="USERS" /></Link></BreadcrumbItem>
                                        <ChevronRight size="9" className="mx-1" />
                                        <BreadcrumbItem active={true}>{account.givenName} {account.surname}</BreadcrumbItem>
                                    </Breadcrumb>
                                    <div className="pt-4 mb-5 pr-3 mw-960">
                                        <h2>{account.givenName} {account.surname}</h2>
                                        <AccountDetails account={account} />
                                        <AccountRoles account={account} />
                                    </div>
                                </>
                            );
                        }}
                    </InjectAccount>
                )}
            </InjectSelectedTenant>
        );
    }
}
