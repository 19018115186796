import jwt_decode from 'jwt-decode';

import { request } from './http';
import { getTenantSubdomainFromUrl } from './urls';
import { APP_ID, BASE_URL, DEFAULT_TOKEN_NAME } from '../constants';
import { AccountData } from '../models';

export async function getSSOToken(tenantId?: string) {
    const tenantSubdomain = (tenantId ? undefined : getTenantSubdomainFromUrl());
    const callbackUrl = `${window.location.protocol}//${window.location.host}`;
    return await request({
        url: `${BASE_URL}/identity/applications/${APP_ID}/token`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: { tenantId, tenantSubdomain, callbackUrl },
    });
}

export function setToken(tokenName: string, tokenValue: string) {
    if (tokenName && tokenValue) {
        sessionStorage.setItem(tokenName, tokenValue);
    }
}

export function getToken() {
    return sessionStorage.getItem(DEFAULT_TOKEN_NAME);
}

export function getUserDataFromToken(jwt: any): AccountData {
    const tokenData = decodeJwt(jwt);

    if (tokenData && tokenData.account) {
        const { account: { tenantId, id, clients, surname, givenName, evoSbUserId, username, email }, scope, policy } = tokenData;
        return {
            clients,
            email,
            evoSbUserId,
            givenName,
            id,
            scope,
            surname,
            tenantId,
            username,
            loadedFromToken: true,
            loadedFromDb: false,
            policy,
        };
    }

    // if token is bad, we'll find out soon enough;
    // it's not helpful to throw from here
    // TODO: its less helpful to return a bad AccountData object ;)
    //  and there are already paths that might throw (`decodeJwt(jwt)`)
    return {} as AccountData;
}

export function getApplicationIdFromToken(jwt: any): string | undefined {
    const tokenData = decodeJwt(jwt);
    return tokenData ? tokenData.applicationId : undefined;
}

export function getTenantSubdomainFromToken(jwt: any): string | undefined {
    const tokenData = decodeJwt(jwt);
    return tokenData ? tokenData.tenantSubdomain : undefined;
}

function decodeJwt(jwt: string): any {
    try {
        return jwt_decode(jwt);
    } catch (e) {
        return undefined;
    }
}
