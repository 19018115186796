import * as React from 'react';

import { AccountData, Tenant, EmailTotpData } from '../../models';
import MfaConfirmSetup from './MfaConfirmSetup';
import MfaChooseMethod from './MfaChooseMethod';
import MfaSetupDevice from './MfaSetupDevice';
import MfaSetupEmail from './MfaSetupEmail';

import './MfaDrawer.scss';

export interface MfaDrawerProps {
    account: AccountData;
    tenant: Tenant;
    loading: any;
    isOpen: boolean;
    onEnableMfa: ({ secret, totpCode, useEmailOnly }: { secret: string, totpCode: string, useEmailOnly: boolean }) => void;
    onEmailTotpCode: (payload: EmailTotpData) => void;
    onCancel: () => void;
}
export interface MfaDrawerState {
    selectedSetupType?: 'device' | 'email'
}

/**
 * MFA Drawer component
 */
class MfaDrawer extends React.Component<MfaDrawerProps, MfaDrawerState> {

    constructor(props: MfaDrawerProps) {
        super(props);

        const { account: { totp } } = props;
        const selectedSetupType = totp ? (totp!.useEmailOnly ? 'email' : 'device') : undefined;

        this.state = {
            selectedSetupType,
        }
    }

    onSelectSetupType(selectedSetupType: 'device' | 'email') {
        const state = this.state;
        this.setState({ ...state, selectedSetupType });
    }

    render() {
        const { isOpen, onCancel, onEnableMfa, tenant, account, loading, onEmailTotpCode } = this.props;
        const { selectedSetupType } = this.state;

        const drawerState = {
            isChooseMethodOpen: isOpen,
            isSetupDeviceOpen: isOpen && selectedSetupType === 'device',
            isSetupEmailOpen: isOpen && selectedSetupType === 'email',
            isConfirmSetupOpen: Boolean(isOpen && this.props.loading.status.UPDATE_ACCOUNT_TOTP === 'complete'),
        };

        return (
            <React.Fragment>
                {/*MfaChooseMethod will remain open, other components conditionally render on top of it*/}
                {drawerState.isChooseMethodOpen && <MfaChooseMethod
                    isModal={true}
                    account={account}
                    onCancel={onCancel}
                    selectSetupType={(t) => this.onSelectSetupType(t)}
                />}
                {drawerState.isSetupDeviceOpen && <MfaSetupDevice
                    isModal={true}
                    account={account}
                    loading={loading}
                    onCancel={onCancel}
                    tenant={tenant}
                    onEnableMfa={onEnableMfa}
                />}
                {drawerState.isSetupEmailOpen && <MfaSetupEmail
                    isModal={true}
                    account={account}
                    loading={loading}
                    onCancel={onCancel}
                    onEmailTotpCode={onEmailTotpCode}
                    onEnableMfa={onEnableMfa}
                />}
                {drawerState.isConfirmSetupOpen && <MfaConfirmSetup
                    isModal={true}
                    onConfirmed={onCancel}
                    selectedSetupType={selectedSetupType}
                />}
            </React.Fragment>
        );
    }
}

export default MfaDrawer;
