import { BASE_URL } from '../../../constants';
import { AccountData } from '../../../models';
import { getToken } from '../../../helpers/tokens';
import { HttpClient } from '../../../helpers/HttpClient';
import { AccountRole, RoleMembership } from '../../../models/role';

export class AccountsService {
    private accountsCache: { [tenantId: string]: AccountData[] } = {};

    static inject = [ HttpClient ];
    constructor(private httpClient: HttpClient) {}

    getAccounts = async ({ tenantId }: { tenantId: string }): Promise<AccountData[]> => {

        // We're caching the returned data (not the promise), so it's possible we could get a cache miss
        // while waiting for a response, but we decided it was worth ignoring this to keep the code simple.
        if (tenantId in this.accountsCache) {
            return this.accountsCache[tenantId];
        }

        const getAccountsRecursive = async (url: string, accounts: AccountData[]): Promise<AccountData[]> => {
            const response = await this.httpClient.request({
                url,
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    Accept: 'application/json',
                },
            });

            if (response && response.results.length > 0) {
                accounts = accounts.concat(response.results);
            }

            if (response && response.next) {
                return await getAccountsRecursive(response.next, accounts);
            }

            return accounts;
        };

        const allAccounts = await getAccountsRecursive(`${BASE_URL}/identity/tenants/${tenantId}/accounts/?limit=100`, []);
        this.accountsCache[tenantId] = allAccounts;
        return allAccounts;
      };

    getAccount = async ({ tenantId, username }: { tenantId: string, username: string }): Promise<AccountData> => {
        const response = await this.httpClient.request({
            url: `${BASE_URL}/identity/tenants/${tenantId}/accounts?username=${username}`,
            headers: {
                Authorization: `Bearer ${getToken()}`,
                Accept: 'application/json',
            },
        });
        return response ? response.results[0] : undefined;
    };

    getAccountRoles = async ({ tenantId, accountId }: { tenantId: string, accountId: string }): Promise<AccountRole[]> => {
        const response = await this.httpClient.request({
            url: `${BASE_URL}/identity/tenants/${tenantId}/accounts/${accountId}/roles`,
            headers: {
                Authorization: `Bearer ${getToken()}`,
                Accept: 'application/json',
            },
        });

        return response;
    };

    assignRole = async ({ tenantId, accountId, roleId }: { tenantId: string, accountId: string, roleId: string }): Promise<RoleMembership> => {
        return await this.httpClient.request({
            method: 'POST',
            url: `${BASE_URL}/identity/tenants/${tenantId}/accounts/${accountId}/role-memberships`,
            headers: {
                Authorization: `Bearer ${getToken()}`,
                'Content-Type': 'application/json',
            },
            body: {
                accountId,
                roleId,
            },
        });
    }

    unassignRole = async ({ tenantId, accountId, roleMembershipId }: { tenantId: string, accountId: string, roleMembershipId: string }) => {
        await this.httpClient.request({
            method: 'DELETE',
            url: `${BASE_URL}/identity/tenants/${tenantId}/accounts/${accountId}/role-memberships/${roleMembershipId}`,
            headers: { Authorization: `Bearer ${getToken()}` },
        });
    }
}
