import { BASE_URL } from '../../constants';
import { request } from '../../helpers/http';
import { getToken } from '../../helpers/tokens';
import { createAsyncAction } from '../async/async';
import { authFallback } from '../authentication/authentication';
import { TenantIntegrationKey } from '../../models';

export const LOAD_INTEGRATION_LOGS = 'LOAD_INTEGRATION_LOGS';

export function loadLogs({ tenantId, integrationId }: TenantIntegrationKey) {
    return createAsyncAction(LOAD_INTEGRATION_LOGS, () => request({
        url: `${BASE_URL}/api/tenants/${tenantId}/integrations/${integrationId}/integration-logs`,
        headers: { Authorization: `Bearer ${getToken()}` },
    }).then((logs) => {
        return { key: { tenantId, integrationId }, logs };
    }), authFallback);
}
