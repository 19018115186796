import * as React from 'react';
import { connect } from 'react-redux';
import { Nav, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Authentication, Integrations, Users, Verification, AdvancedHR } from '../../facade/Icons/Icons';

import { AccountData, ApplicationState, Tenant } from '../../models';
import { isAuthorizedToSsoConfiguration, isAuthorizedToIntegrations, isAuthorizedToAccounts, isAuthorizedToVerification, isAuthorizedToAdvancedHr } from '../../helpers/auth';

export const sideBarItems = [
    {
        link: '/sso-configuration',
        icon: <Authentication size="20" className="mr-3" />,
        text: 'SSO_CONFIGURATION',
        predicate: (account: AccountData, selectedTenant: Tenant) =>
            isAuthorizedToSsoConfiguration({ account }),
    },
    {
        link: '/integrations',
        icon: <Integrations size="20" className="mr-3" />,
        text: 'INTEGRATIONS',
        predicate: (account: AccountData, selectedTenant: Tenant) =>
            isAuthorizedToIntegrations({ account }),
    },
    {
        link: '/accounts',
        icon: <Users size="20" className="mr-3" />,
        text: 'USERS',
        predicate: (account: AccountData, selectedTenant: Tenant) =>
            isAuthorizedToAccounts({ account, selectedTenantId: selectedTenant!.id }),
    },
    {
        link: '/verification',
        icon: <Verification size="20" className="mr-3" />,
        text: 'VERIFICATION',
        predicate: (account: AccountData, selectedTenant: Tenant) =>
            isAuthorizedToVerification({ account, selectedTenantId: selectedTenant!.id }),
    },
    {
        link: '/advanced-hr',
        icon: <AdvancedHR size="20" className="mr-3" />,
        text: 'ADVANCED_HR',
        predicate: (account: AccountData, selectedTenant: Tenant) =>
            isAuthorizedToAdvancedHr({ account, selectedTenantId: selectedTenant!.id }),
    },
];

export interface SidebarProps {
    account: AccountData | undefined;
    selectedTenant?: Tenant;
}

class Sidebar extends React.Component<SidebarProps> {
    render() {
        const { account, selectedTenant } = this.props;
        if (!account || !selectedTenant) {
            return null;
        }

        const routeLinks = sideBarItems.filter((item) => !item.predicate || item.predicate(account, selectedTenant)).map((item) => {
            return (
                <NavItem key={item.text} className="w-100 pt-3">
                    <NavLink
                        to={item.link}
                        className="nav-link d-flex align-items-center p-3"
                        activeClassName="active"
                    >
                        {item.icon}
                        <span className="d-none d-sm-inline"><FormattedMessage id={item.text} /></span>
                    </NavLink>
                </NavItem>
            )
        });

        return (
            <Nav vertical="true" className="vertical-nav bg-light sidebar-contents d-block h-100">
                {routeLinks}
            </Nav>
        );
    }
}

// pure:false is the easiest way to ensure component re-renders when "active" state changes
// (see https://react-redux.js.org/api/connect)
export default connect<any>(({ tenants: { selectedTenant } }: ApplicationState) => ({
    selectedTenant,
}), undefined, undefined, { pure: false })(Sidebar);
