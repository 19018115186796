import 'whatwg-fetch';

export interface FetchOptions {
    url: string;
    method?: string;
    headers?: { [name: string]: string };
    body?: any;
}

export async function request({ url, method = 'GET', headers = {}, body }: FetchOptions): Promise<any> {
    if (!url) {
        throw new Error(`Must specify a url to fetch`);
    }

    const response = await fetch(url, { method, headers, body: body ? JSON.stringify(body) : undefined });
    if (response.status >= 400) {
        throw response;
    }

    if (response.status === 204) {
        return undefined;
    }

    const text = await response.text();
    if (!text) {
        return undefined;
    }

    try {
        return JSON.parse(text);
    } catch (e) {
        throw new Error('Error Parsing response JSON');
    }
}
