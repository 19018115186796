export class ErrorService {
  showNotFound() {
    const title = document.createElement('h2');
    title.classList.add('alert-heading');
    title.innerText = 'Page Not Found';

    const text = document.createElement('p');
    text.innerText = `We're sorry, the page you requested was not found. Please check the address and try again.`;

    const div = document.createElement('div');
    div.classList.add('alert');
    div.classList.add('alert-warning');
    div.appendChild(title);
    div.appendChild(text);

    const outer = document.createElement('div');
    div.classList.add('container');
    div.style.marginTop = '50px';
    outer.appendChild(div);

    document.body.appendChild(outer);
  }
}
