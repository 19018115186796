import { BASE_URL } from '../../constants';
import { request } from '../../helpers/http';
import { getToken } from '../../helpers/tokens';
import { createAsyncAction } from '../async/async';
import { authFallback } from '../authentication/authentication';
import { GenericAction, TenantSettings, TenantIntegrationKey } from '../../models';

export const LOAD_TENANT_SETTINGS = 'LOAD_TENANT_SETTINGS';
export type LOAD_TENANT_SETTINGS = typeof LOAD_TENANT_SETTINGS;
export const SAVE_TENANT_SETTINGS = 'SAVE_TENANT_SETTINGS';
export type SAVE_TENANT_SETTINGS = typeof SAVE_TENANT_SETTINGS;

export interface SettingsPayload {
    key: TenantIntegrationKey;
    tenantSettings: TenantSettings;
}

export interface LoadSettings extends GenericAction<SettingsPayload> {
    type: LOAD_TENANT_SETTINGS;
}

export interface SaveSettings extends GenericAction<TenantSettings> {
    type: SAVE_TENANT_SETTINGS;
}

export type SettingsAction = LoadSettings | SaveSettings;

export function loadSettings({ tenantId, integrationId }: TenantIntegrationKey) {
    return createAsyncAction(LOAD_TENANT_SETTINGS, () => request({
        url: `${BASE_URL}/api/tenants/${tenantId}/integrations/${integrationId}/integration-configurations?filterBy=tenant`,
        headers: { Authorization: `Bearer ${getToken()}` },
    }).then((configurations) => {
        const tenantSettings = configurations.length ? configurations[0] : { tenantId, integrationId };
        return { key: { tenantId, integrationId }, tenantSettings };
    }), authFallback);
}

export function saveSettings(tenantSettings: TenantSettings) {
    const { id = '', tenantId, integrationId } = tenantSettings;
    const method = id ? 'PUT' : 'POST';
    return createAsyncAction(SAVE_TENANT_SETTINGS, () => request({
        url: `${BASE_URL}/api/tenants/${tenantId}/integrations/${integrationId}/integration-configurations/${id}`,
        method,
        headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': 'application/json' },
        body: tenantSettings,
    }), authFallback);
}
