import * as React from 'react';
import LoadingIndicator from '../../../facade/LoadingIndicator/LoadingIndicator';
import { InjectAccounts } from './InjectAccounts';
import ErrorRenderer from '../../../components/ErrorRenderer/ErrorRenderer';
import { FormattedMessage } from 'react-intl';
import AccountListing from '../../../components/AccountListing/AccountListing';
import { InjectSelectedTenant } from '../Tenants/InjectSelectedTenant';
import { Alert } from 'reactstrap';

export default class AccountListingPage extends React.Component {
    render() {
        return (
            <InjectSelectedTenant>
                {({ selectedTenant }) => (
                    <InjectAccounts key={selectedTenant.id} tenantId={selectedTenant.id}>
                        {({ accounts, error, isLoading }) => {

                            if (!error && isLoading) {
                                return (
                                    <LoadingIndicator />
                                );
                            }

                            if (error) {
                                return (
                                    <ErrorRenderer error={error} />
                                );
                            }

                            if (!isLoading && !accounts.length) {
                                return (
                                    <div className="pt-4 mb-5 pr-3 mw-960" data-page="user-list">
                                        <div className="pb-4 card-body-title">
                                            <h3 className="pb-0 mb-0">
                                                <FormattedMessage id="USERS" />
                                            </h3>
                                        </div>
                                        <Alert color="info">
                                            <FormattedMessage id="USERS_NOT_FOUND" />
                                        </Alert>
                                    </div>
                                );
                            }

                            return (
                                <div className="pt-4 mb-5 pr-3 mw-960" data-page="user-list">
                                    <div className="pb-3 card-body-title">
                                        <h3 className="pb-0 mb-0">
                                            <FormattedMessage id="USERS" />
                                        </h3>
                                    </div>
                                    <AccountListing accounts={accounts} />
                                </div>
                            );

                        }}
                    </InjectAccounts>
                )}
            </InjectSelectedTenant>
        );
    }
}
