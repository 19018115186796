import { BASE_URL, LOAD_ACCOUNT, UPDATE_ACCOUNT_TOTP, DELETE_ACCOUNT_TOTP, SET_ACCOUNT_DATA, EMAIL_TOTP_CODE } from '../../constants';
import { GenericAction, AccountData, AccountPayload, TotpPayload, EmailTotpPayload } from '../../models';
import { request } from '../../helpers/http';
import { getToken } from '../../helpers/tokens';
import { createAsyncAction } from '../async/async';
import { authFallback } from '../authentication/authentication';

export interface SetAccountData extends GenericAction<object> {
    type: SET_ACCOUNT_DATA;
}

export interface LoadAccount extends GenericAction<AccountPayload> {
    type: LOAD_ACCOUNT;
}

export interface UpdateAccountTotp extends GenericAction<TotpPayload> {
    type: UPDATE_ACCOUNT_TOTP;
}

export interface DeleteAccountTotp extends GenericAction<AccountPayload> {
    type: DELETE_ACCOUNT_TOTP;
}

export interface EmailTotpCode extends GenericAction<object> {
    type: EMAIL_TOTP_CODE;
}

export function setAccountData(payload: AccountData | object) {
    return {
        type: SET_ACCOUNT_DATA,
        payload,
    };
}

export type AccountAction = SetAccountData | UpdateAccountTotp | DeleteAccountTotp | LoadAccount | EmailTotpCode;

export function loadAccount(payload: AccountPayload) {
    return createAsyncAction(LOAD_ACCOUNT, () => request({
        url: `${BASE_URL}/identity/tenants/${payload.tenantId}/accounts/${payload.id}`,
        headers: { Authorization: `Bearer ${getToken()}` },
    }), authFallback);
}

export function updateTotp(payload: TotpPayload) {
    return createAsyncAction(UPDATE_ACCOUNT_TOTP, () => request({
        url: `${BASE_URL}/identity/tenants/${payload.tenantId}/accounts/${payload.id}/totp`,
        method: 'PUT',
        headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': 'application/json' },
        body: payload.body,
    }), authFallback);
}

export function deleteTotp(payload: AccountPayload) {
    return createAsyncAction(DELETE_ACCOUNT_TOTP, () => request({
        url: `${BASE_URL}/identity/tenants/${payload.tenantId}/accounts/${payload.id}/totp`,
        method: 'DELETE',
        headers: { Authorization: `Bearer ${getToken()}` },
    }), authFallback);
}

export function emailTotpCode(payload: EmailTotpPayload) {
    return createAsyncAction(EMAIL_TOTP_CODE, () => request({
        url: `${BASE_URL}/identity/tenants/${payload.tenantId}/email-totp-code`,
        method: 'POST',
        headers: {
            Authorization: `Bearer ${getToken()}`,
            'Content-Type': 'application/json',
        },
        body: payload.body,
    }), authFallback);
}
