import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Row, Col } from 'reactstrap';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Success } from '../../../facade/Icons/Icons';

import {
    ApplicationState,
    AccountData,
    AccountPayload,
    TotpPayload,
    Tenants,
    EmailTotpData,
    EmailTotpPayload
} from '../../../models';
import * as AccountActions from '../../../actions/account/account';
import * as LoadingActions from '../../../actions/loading/loading';
import MfaDrawer from '../../../components/MfaDrawer/MfaDrawer';
import StandardModal from '../../../components/StandardModal/StandardModal';
import AccountDetails from '../../../components/AccountDetails/AccountDetails';
import AccountSecurity from '../../../components/AccountSecurity/AccountSecurity';
import LoadingIndicator from '../../../facade/LoadingIndicator/LoadingIndicator';
import Toast from '../../../components/Toast/Toast';
import { UPDATE_ACCOUNT_TOTP, DELETE_ACCOUNT_TOTP } from '../../../constants/actions';

export interface AccountSettingsProps {
    loadAccount: (payload: AccountPayload) => void;
    updateTotp: (payload: TotpPayload) => void;
    deleteTotp: (payload: AccountPayload) => void;
    clearLoadingState: () => void;
    emailTotpCode: (payload: EmailTotpPayload) => void;
    account: AccountData;
    tenants: Tenants;
    loading: any;
}
export interface AccountSettingsState {
    showDrawer: boolean,
    showConfirm: boolean,
    drawerId: number
}

const mapStateToProps = ({ account, loading, tenants }: ApplicationState) => {
    return {
        account,
        loading,
        tenants,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        loadAccount: (payload: AccountPayload) => dispatch(AccountActions.loadAccount(payload)),
        updateTotp: (payload: TotpPayload) => dispatch(AccountActions.updateTotp(payload)),
        deleteTotp: (payload: AccountPayload) => dispatch(AccountActions.deleteTotp(payload)),
        emailTotpCode: (payload: EmailTotpPayload) => dispatch(AccountActions.emailTotpCode(payload)),
        clearLoadingState: () => {
            dispatch(LoadingActions.clearLoadingState(UPDATE_ACCOUNT_TOTP));
            dispatch(LoadingActions.clearLoadingState(DELETE_ACCOUNT_TOTP));
        },
    };
};

class AccountSettings extends React.Component<AccountSettingsProps, AccountSettingsState> {
    constructor(props: AccountSettingsProps) {
        super(props);
        this.state = {
            showDrawer: false,
            showConfirm: false,
            drawerId: 1,
        };
    }

    componentDidMount() {
        const { loadAccount, account } = this.props;

        if (account && account.loadedFromToken) {
            const { tenantId, id } = account;
            loadAccount({ tenantId, id });
        }
    }

    toggleDrawer(): void {
        if (!this.state.showDrawer) {
            this.props.clearLoadingState();
        }

        this.setState((prevState: AccountSettingsState) => {
            const showDrawer = !prevState.showDrawer;

            // if showing the drawer increment the drawerId to force a new instance of MFA Drawer each time
            // the drawer is opened
            const drawerId = showDrawer ? prevState.drawerId + 1 : prevState.drawerId;

            return {
                showDrawer,
                drawerId,
            };
        });
    }

    toggleConfirm(): void {
        this.setState((prevState: AccountSettingsState) => {
            return { showConfirm: !prevState.showConfirm };
        });
    }

    async enableMfa({ secret, totpCode, useEmailOnly }: { secret: string, totpCode: string, useEmailOnly: boolean }) {
        const { account, updateTotp, loadAccount } = this.props;
        const { tenantId, id } = account;

        const payload: TotpPayload = {
            tenantId,
            id,
            body: { secret, totpCode, useEmailOnly },
        };

        await updateTotp(payload);
        await loadAccount({ tenantId, id });
    }

    async emailTotpCode(data: EmailTotpData) {
        const { emailTotpCode } = this.props;
        const { tenantId, username, secret } = data;
        const payload: EmailTotpPayload = {
            tenantId,
            body: {
                username,
                secret,
            },
        };

        await emailTotpCode(payload);
    }

    async disableMfa() {
        const { account, deleteTotp, loadAccount } = this.props;
        const { tenantId, id } = account;

        const payload: AccountPayload = {
            tenantId,
            id,
        };

        await deleteTotp(payload);
        await loadAccount({ tenantId, id });
    }

    render() {
        const { account, loading, tenants } = this.props;
        const { showDrawer, showConfirm } = this.state;
        const showToast = loading.status.DELETE_ACCOUNT_TOTP === 'complete';

        if (!tenants || !account || !account.loadedFromDb) {
            return (
                <LoadingIndicator />
            );
        }

        const tenant = tenants.results.find(t => t.id === account.tenantId);
        const emailOnlyTotp = account.totp && account.totp.useEmailOnly;
        const mfaEnabled = tenant && tenant.mfaEnabled;

        return (
            <React.Fragment>
                <Toast color="success" isOpen={showToast} toggle={this.props.clearLoadingState}>
                    <Success className="position-absolute ml-2" size="20" />
                    <div className="ml-5 mr-4">
                        <FormattedHTMLMessage id="MFA_DISABLED" />
                    </div>
                </Toast>

                <Row className="pt-4 mb-5 pr-3 mw-960">
                    <Col>
                        <h2><FormattedMessage id="SETTINGS" /></h2>
                        <AccountDetails account={account} />
                        {mfaEnabled && <AccountSecurity
                            account={account}
                            onDisableMfa={() => this.toggleConfirm()}
                            onEnableMfa={() => this.toggleDrawer()}
                        />}
                    </Col>
                </Row>

                {mfaEnabled && <MfaDrawer
                    key={String(this.state.drawerId)}
                    isOpen={showDrawer}
                    account={account}
                    tenant={tenant!}
                    onEnableMfa={(payload: { secret: string, totpCode: string, useEmailOnly: boolean }) => this.enableMfa(payload)}
                    onEmailTotpCode={(data: EmailTotpData) => this.emailTotpCode(data)}
                    onCancel={() => this.toggleDrawer()}
                    loading={loading}
                />}

                <StandardModal
                    isOpen={showConfirm}
                    toggle={() => this.toggleConfirm()}
                    disabled={['loading', 'complete'].indexOf(loading.status.DELETE_ACCOUNT_TOTP) > -1}
                    titleId="DISABLE_MFA_QUESTION"
                    confirmButtonId="DISABLE"
                    cancelButtonId="CANCEL"
                    onConfirm={async () => {
                        await this.disableMfa();
                        await this.toggleConfirm();
                    }}
                    modalClassName="dialog"
                >
                    <p><FormattedMessage id="DISABLING_LESS_SECURE_MESSAGE"/></p>
                    <ul>
                        <li><FormattedHTMLMessage id={emailOnlyTotp ? 'MFA_EMAIL_REMOVED' : 'MFA_DEVICE_REMOVED'} /></li>
                        <li><FormattedMessage id="PASSWORD_ONLY_MESSAGE" /></li>
                    </ul>
                </StandardModal>
            </React.Fragment>
        );
    }
}

export default connect<any>(mapStateToProps, mapDispatchToProps)(AccountSettings);
