import * as React from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

export interface VerificationDataComponentProps {
    url: string;
    code: string,
    tenantId?: string,
}

export default class VerificationData extends React.Component<VerificationDataComponentProps, any> {
    urlField: HTMLInputElement;
    codeField: HTMLInputElement;

    copyToClipboard(field: string) {
        this[field].select();
        document.execCommand('copy');
    }

    render() {
        const { url, code } = this.props;
        return (
            <Form className="pt-4">
                <div className="form-row">
                    <div className="col-12">
                        <p className="font-weight-bold"><FormattedMessage id="COPY_OR_SAVE_DETAILS" />:</p>
                    </div>
                </div>
                <div className="form-row form-control-sm">
                    <FormGroup className="col-12">
                        <Label for="URL"><FormattedMessage id="URL" /></Label>
                        <div className="form-row align-items-center">
                            <div className="col-8">
                                <Input
                                    readOnly={true}
                                    value={url}
                                    innerRef={(input: any) => { this.urlField = input; }}
                                />
                            </div>
                            <div className="col-2">
                                <Button
                                    color="white"
                                    onClick={this.copyToClipboard.bind(this, 'urlField')}
                                >
                                    <FormattedMessage id="COPY" />
                                </Button>
                            </div>
                        </div>
                    </FormGroup>
                </div>
                <div className="form-row form-control-sm">
                    <FormGroup className="col-12">
                        <Label for="verificationCode">
                            <FormattedMessage id="VERIFICATION_CODE" />
                        </Label>
                        <div className="form-row align-items-center">
                            <div className="col-4">
                                <Input
                                    readOnly={true}
                                    value={code}
                                    innerRef={(input: any) => { this.codeField = input; }}
                                />
                            </div>
                            <div className="col-2">
                                <Button
                                    color="white"
                                    onClick={this.copyToClipboard.bind(this, 'codeField')}
                                >
                                    <FormattedMessage id="COPY" />
                                </Button>
                            </div>
                        </div>
                    </FormGroup>
                </div>
            </Form>
        );
    }
}
