import { LOAD_INTEGRATION_CONFIGURATIONS, CREATE_INTEGRATION_CONFIGURATION, UPDATE_INTEGRATION_CONFIGURATION } from '../../actions/integrations/configurationsMap';
import { Action } from 'redux-actions';
import { Configuration, ConfigurationsMap, TenantIntegrationKey } from '../../models';

const initialState = new ConfigurationsMap();

export function configurationsMap(state: ConfigurationsMap = initialState, action: Action<any>): ConfigurationsMap {
    if (!action.error && action.type && action.payload) {
        switch (action.type) {

            case LOAD_INTEGRATION_CONFIGURATIONS: {
                const { payload } = action;
                const { key, configurations } = payload;
                return new ConfigurationsMap(state).set(key, configurations);
            }

            case CREATE_INTEGRATION_CONFIGURATION: {
                const { payload } = action;
                const { tenantId, integrationId } = payload;
                const key: TenantIntegrationKey = { tenantId, integrationId };
                const configurations = state.has(key) ? state.get(key).slice() : [];
                configurations.push(payload);
                return new ConfigurationsMap(state).set(key, configurations);
            }

            case UPDATE_INTEGRATION_CONFIGURATION: {
                // merge the updated integration configuration into the results array
                const { payload } = action;
                const { tenantId, integrationId } = payload;
                const match = (m1: Configuration, m2: Configuration) => {
                    return m1.clientId === m2.clientId && m1.companyId === m2.companyId;
                };
                const key: TenantIntegrationKey = { tenantId, integrationId };
                const oldState = state.get(key) || [];
                return new ConfigurationsMap(state).set(key, oldState.map(configuration => match(configuration, payload) ? payload : configuration));
            }
        }
    }
    return state;
}
