import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import { MfaContainer, MfaBody, MfaFooter } from './MfaContainer';
import { AccountData } from '../../models';
import CardButton from '../CardButton/CardButton';
import { Mobile, Mail } from '../../facade/Icons/Icons';

export interface MfaChooseMethodProps {
    onCancel?: () => void;
    account: AccountData;
    selectSetupType: (setupType: 'device' | 'email') => void;
    isModal?: boolean;
    defaultSetupType?: 'device' | 'email';
}
export interface MfaChooseMethodState {
    setupType?: 'device' | 'email';
}

class MfaChooseMethod extends React.Component<MfaChooseMethodProps, MfaChooseMethodState> {
    constructor(props: MfaChooseMethodProps) {
        super(props);
        this.state = { setupType: props.defaultSetupType };
    }

    onSetup(): void {
        if (this.state.setupType) {
            this.props.selectSetupType(this.state.setupType);
        }
    }

    render() {
        // onCancel optional because it's not supplied by the app-setup/mfa flow
        const onCancel = this.props.onCancel;

        // we only set hasOverlay = true on the first dialog, because otherwise the different steps in the worflow cause a darker overlay
        // as they stack
        const hasOverlay = true;

        return (
            <MfaContainer isModal={this.props.isModal} hasOverlay={hasOverlay} onCancel={() => (onCancel ? onCancel() : {})}>
                <MfaBody>
                    <h4><FormattedMessage id="MFA_SELECT_TYPE_TITLE"/></h4>
                    <p className="m-3"><FormattedMessage id="MFA_SELECT_TYPE_TEXT"/></p>
                    <div className="d-flex flex-column">
                        <CardButton
                            icon={Mobile}
                            title="MFA_DEVICE_NAME"
                            message={{ id: 'MFA_DEVICE_DESCRIPTION' }}
                            active={this.state.setupType === 'device'}
                            onClick={() => this.setState({ setupType: 'device' })}
                        />
                        <CardButton
                            icon={Mail}
                            title="MFA_EMAIL_NAME"
                            message={{ id: 'MFA_EMAIL_DESCRIPTION', values: { ACCOUNT_EMAIL: this.props.account.email } }}
                            active={this.state.setupType === 'email'}
                            onClick={() => this.setState({ setupType: 'email' })}
                        />
                    </div>
                </MfaBody>
                <MfaFooter>
                    {onCancel &&
                        <Button color="white" onClick={() => onCancel()}>
                            <FormattedMessage id="CANCEL" />
                        </Button>
                    }
                    <Button color="primary" disabled={!this.state.setupType} onClick={() => this.onSetup()}>
                        <FormattedMessage id="SET_UP" />
                    </Button>
                </MfaFooter>
            </MfaContainer>
        )
    }
}

export default MfaChooseMethod;
