import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';

export default function LogOutLink() {
    return (
        <Link to="/logout" replace={true}>
            <DropdownItem className="btn-logout text-nowrap">
                <FormattedMessage id="LOG_OUT"/>
            </DropdownItem>
        </Link>
    );
}
