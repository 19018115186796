import * as React from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import { AccountData } from '../../models';
import { AsyncToggle } from '../Forms/Toggle/AsyncToggle';
import LoadingIndicator from '../../facade/LoadingIndicator/LoadingIndicator';
import { InjectRoles } from '../../containers/Views/Accounts/InjectRoles';
import { FormattedMessage } from 'react-intl';

export interface AccountRolesProps {
    account: AccountData;
}

const RolesHeader = () => (
    <h3>
        <FormattedMessage id="ROLES" />
    </h3>
)

const NoRoles = () => (
    <>
        <RolesHeader />
        <p>
            Your account has not been configured to see any roles.
        </p>
    </>
)

export default class AccountRoles extends React.Component<AccountRolesProps> {
    render() {
        const { account } = this.props;
        return (
            <Card className="mt-4" data-name="role-list">
                <CardBody>
                    <InjectRoles accountId={account.id} tenantId={account.tenantId}>
                        {({ roles, assignRole, unassignRole, isLoading }) => {
                            if (isLoading) {
                                return (
                                    <LoadingIndicator text="LOADING_ROLES" />
                                );
                            }

                            if (roles.length === 0) {
                                return <NoRoles />
                            }

                            return (
                                <>
                                    <RolesHeader />
                                    <Table className="border-bottom">
                                        <thead>
                                            <tr>
                                                <th><small><FormattedMessage id="ROLE" /></small></th>
                                                <th><small/></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {roles.map((role) => {
                                                const accountHasRole = Boolean(role.roleMembershipId);
                                                const disabled = accountHasRole ? !role.canUnassignRole : !role.canAssignRole;
                                                const toggle = accountHasRole ? () => unassignRole(role) : () => assignRole(role);
                                                return (
                                                    <tr key={role.id} data-name="role-list-item" data-id={role.id}>
                                                        <td className="align-middle">
                                                            {role.name}
                                                            {Boolean(role.description) && <p className="text-muted small mb-0">{role.description}</p>}
                                                        </td>
                                                        <td className="align-middle pb-0">
                                                            <AsyncToggle
                                                                on={accountHasRole}
                                                                disabled={disabled}
                                                                onChange={toggle}
                                                                name="toggle-role"
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </>
                            );
                        }}
                    </InjectRoles>
                </CardBody>
            </Card>
        );
    }
}
