import { PromisedState } from '../../../helpers/PromisedState';
import { IntegrationsService } from './IntegrationsService';
import { alphabetizeByName } from '../../../helpers/lists';
import { Integration } from '../../../models';

export class IntegrationsStateFactory {

    static inject = [IntegrationsService];
    constructor(private integrationsService: IntegrationsService) { }

    private tenantIntegrationsPromiseCache: { [tenantId: string]: PromisedState } = {};

    /**
     * Cache the tenant integrations state
     * But evict the cache if it fails to load, so it will try again
     */
    getIntegrationsForTenant = (tenantId: string): PromisedState<{ integrations: Integration[] }> => {
        if (!(tenantId in this.tenantIntegrationsPromiseCache)) {
            this.tenantIntegrationsPromiseCache[tenantId] = new PromisedState<{ integrations: Integration[] }>(async () => {
                const integrations = await this.integrationsService.getIntegrations({ tenantId });
                return { integrations: alphabetizeByName(integrations) };
            });

            this.tenantIntegrationsPromiseCache[tenantId]
                .load()
                .catch(() => {
                    delete this.tenantIntegrationsPromiseCache[tenantId];
                });
        }

        return this.tenantIntegrationsPromiseCache[tenantId];
    }

}
