import * as React from 'react';
import { Card, CardBody, Row, Col, FormText, Badge, Button } from 'reactstrap';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { AccountData } from '../../models';

export interface AccountSecurityProps {
    account: AccountData;
    onDisableMfa: () => void;
    onEnableMfa: () => void;
}

export default (props: AccountSecurityProps) => {
    const { account: { totp }, onDisableMfa, onEnableMfa } = props;
    const hasTotp = Boolean(totp);
    const emailOnlyTotp = hasTotp && totp!.useEmailOnly;

    return (
        <Card className="mt-4">
            <CardBody>
                <h3><FormattedMessage id="SECURITY" /></h3>
                <Row className="mb-3">
                    <Col xs="12" lg="3">
                        <FormattedMessage id="MFA_TITLE" />
                        <Badge className="d-lg-none ml-4" pill={true} color={hasTotp ? 'success' : 'secondary'}>
                            <FormattedMessage id={hasTotp ? 'ENABLED' : 'DISABLED'} />
                        </Badge>
                    </Col>
                    <Col>
                        <Badge className="d-none d-lg-inline-block" pill={true} color={hasTotp ? 'success' : 'secondary'}>
                            <FormattedMessage id={hasTotp ? 'ENABLED' : 'DISABLED'} />
                        </Badge>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" lg={{ size: 8, offset: 3 }}>
                        <FormText color="muted">
                            <FormattedHTMLMessage id={emailOnlyTotp ? 'MFA_USER_HELP_EMAIL_ONLY' : 'MFA_USER_HELP'} />
                        </FormText>
                    </Col>
                </Row>
                <Row className="mt-4 mb-4">
                    <Col xs="12" lg={{ size: 8, offset: 3 }}>
                        {hasTotp &&
                            <Button className="mr-3" color="primary" onClick={onDisableMfa} id="disable-totp">
                                <FormattedMessage id="DISABLE" />
                            </Button>
                        }
                        {!emailOnlyTotp &&
                            <Button color={hasTotp ? 'white' : 'primary'} onClick={onEnableMfa} id="setup-totp">
                                <FormattedMessage id={hasTotp ? 'CHANGE_DEVICE' : 'ENABLE'} />
                            </Button>
                        }
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};
