import { BASE_URL, EVO_APP_ID } from '../../constants';
import { createAsyncAction } from '../async/async';
import { getToken } from '../../helpers/tokens';
import { authFallback } from '../authentication/authentication';
import { Tenant, CompanyKey, EDCode } from '../../models';
import { request } from '../../helpers/http';

export const LOAD_EDCODES = 'LOAD_EDCODES';

export function loadEDCodes(tenant: Tenant, companyKey: CompanyKey) {
    return createAsyncAction(LOAD_EDCODES, () => request({
        url: `${BASE_URL}/identity/tenants/${tenant.id}/oauth/token`,
        method: 'POST',
        headers: { Authorization: `Bearer ${getToken()}` },
        body: { grant_type: 'token_exchange', audience: EVO_APP_ID },
    }).then((tokenResponse) => Promise.all([
        request({
            url: `${BASE_URL}/v3/api/bureaus/${tenant.subdomain}/clients/${companyKey.clientId}/eds`,
            headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        }),
        request({
            url: `${BASE_URL}/v3/api/bureaus/${tenant.subdomain}/clients/${companyKey.clientId}/companies/${companyKey.companyId}/eds`,
            headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        }),
    ])).then(([ { results: clientEDs }, { results: companyEDs } ]) => {
        const idSet = new Set(companyEDs.map((ed: any) => ed.clientEdId));
        const edCodes = clientEDs.filter((ed: any) => idSet.has(ed.id) && isApplicableType(ed));
        return { companyKey, edCodes };
    }), authFallback);
}

// only map E/D codes of types E*, M*, DL, DM, DO
function isApplicableType(ed: EDCode) {
    return (
        ed.codeType.startsWith('E') ||
        ed.codeType.startsWith('M') ||
        [ 'DL', 'DM', 'DO', 'DV', 'DW' ].indexOf(ed.codeType) > -1
    );
}
