import { ApplicationState } from '../models';
import { Dispatch, Store } from 'redux';
/**
 * Marker class to allow the redux store to be injected via IOC.
 */
export class ReduxStore implements Partial<Store<ApplicationState>> {
    dispatch: Dispatch<ApplicationState>;
    getState: () => ApplicationState;

    constructor() {
        throw new Error('ReduxStore should be injected by IOC, not constructed!');
    }
}
