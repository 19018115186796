import * as React from 'react';
import { FormGroup, FormText, Label, Button, Input } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Formik, Form, Field, FieldProps } from 'formik';
import * as Yup from 'yup'

import ControlledInput from '../Forms/ControlledInput/ControlledInput';
import Toggle from '../Forms/Toggle/Toggle';
import StandardModal from '../StandardModal/StandardModal';
import UploadDropzone from '../Forms/Upload/UploadDropzone';
import UploadContent from '../Forms/Upload/UploadContent';

import { Tenants, Tenant, TenantPayload, TenantUploadRequest, SsoData, ConfigurationModes, LogoUploadPayload, AccountData } from '../../models';
import { isAuthorizedTo } from '../../helpers/auth';
import { TENANT_BUCKET } from '../../constants';

interface ConfigurationComponentProps {
    account?: AccountData;
    selectedTenant: Tenant;
    updateTenant: (payload: TenantPayload) => void;
    createTenant: (tenantPayload: SsoData, logoPayload?: any) => void;
    uploadTenantLogo: (payload: TenantUploadRequest) => void;
    saveSsoData: (payload: SsoData) => void;
    loading: any;
    ssoData: SsoData;
    toggleMode: (mode: string) => void;
    tenants: Tenants;
}

interface ConfigurationFormValues {
    bureauName: string;
    subdomain: string;
    serialNumber?: number | string;
    sso?: boolean;
    logo?: LogoUploadPayload | string;
    mfa?: boolean;
    evoDatabaseHost?: string;
}

const DEFAULT_FORM_COLUMNS = { default: 12, sm: 6, md: 6, lg: 6, xl: 6 };
const DEFAULT_FORM_STATE = {
    enabled: true,
    mfaEnabled: true,
    serialNumber: '',
    logo: '',
    subdomain: '',
    name: '',
    evoDatabaseHost: '',
};
const setInitialValues = (props: any = DEFAULT_FORM_STATE) => {
    const {
        enabled = true,
        mfaEnabled = false,
        serialNumber = '',
        logo = '',
        subdomain = '',
        name = '',
        evoDatabaseHost = '',
    } = props;
    return {
        sso: enabled,
        mfa: mfaEnabled,
        bureauName: name,
        serialNumber,
        subdomain,
        logo,
        evoDatabaseHost,
    }
};

const UploadComponent = ({ setFieldValue, logo, disabled }: any) => {
    let logoObj: any = undefined;
    if (logo) {
        // Determine if the logo came from the UploadDropzone component or from the selectedTenant object.
        const { file } = logo;
        if (file) {
            // Drag n' Dropped Logo
            logoObj = file;
        } else if (typeof logo === 'string') {
            // selectedTenant logo
            logoObj = {
                preview: `${TENANT_BUCKET}/${logo}`,
                name: logo,
            }
        }
    }
    if (logoObj) {
        return (
            <UploadContent
                file={logoObj}
                onClear={() => setFieldValue('logo', '')}
                disabled={disabled}
            />
        );
    }
    return (
        <UploadDropzone
            onFileDrop={(payload: LogoUploadPayload) => setFieldValue('logo', payload)}
            disabled={disabled}
        />
    );
}

export const Configuration: React.FC<ConfigurationComponentProps> = ({
    account,
    selectedTenant,
    updateTenant,
    createTenant,
    uploadTenantLogo,
    saveSsoData,
    loading,
    ssoData,
    toggleMode,
    tenants,
}) => {
    const { configurationMode } = ssoData;
    const { uploadingLogo, updatingTenant, creatingTenant } = loading;
    const isLoading = uploadingLogo === 'loading' || updatingTenant === 'loading' || creatingTenant === 'loading';
    const tenantUpdateComplete = updatingTenant === 'complete' || creatingTenant === 'complete';
    const disabled = configurationMode === ConfigurationModes.View;
    const isEditMode = configurationMode === ConfigurationModes.Edit;
    const isCreateMode = configurationMode === ConfigurationModes.Create;

    const [modalIsOpen, toggleModal] = React.useState(false);
    if (tenantUpdateComplete && modalIsOpen) {
        toggleMode(ConfigurationModes.View);
        toggleModal(false);
    }

    const toggleEditMode = (mode: string) => {
        mode = configurationMode === ConfigurationModes.View ? mode : ConfigurationModes.View;
        toggleMode(mode);
    }

    const handlePublish = (values: ConfigurationFormValues) => {
        isEditMode ? handleUpdate(values) : handleCreate(values);
    };

    const handleUpdate = (values: ConfigurationFormValues) => {
        const { sso: enabled, mfa: mfaEnabled, bureauName: name, serialNumber, logo, subdomain, evoDatabaseHost } = values;
        // Upload base64 encoded to S3.
        if (typeof logo === 'object' && logo.encodedFile) {
            const { encodedFile, file } = logo;
            const body = {
                filename: file.name,
                payload: encodedFile,
                type: 'logo',
            };
            uploadTenantLogo({ id: selectedTenant.id, body });
        } else {
            // If user removed the logo, it's undefined here which would exclude it from the PATCH.
            // So we send empty string, and goldilocks recognizes this (falsey) as a "remove" request.
            const payload: TenantPayload = {
                id: selectedTenant.id,
                body: {
                    name,
                    enabled,
                    mfaEnabled,
                    serialNumber,
                    logo: logo || '',
                    subdomain,
                    evoDatabaseHost,
                },
            };
            updateTenant(payload);
        }
    };

    const handleCreate = (values: ConfigurationFormValues) => {
        const { sso: enabled, mfa: mfaEnabled, bureauName: name, serialNumber, logo, subdomain, evoDatabaseHost } = values;

        const tenantPayload: SsoData = {
            name,
            enabled,
            mfaEnabled,
            serialNumber,
            logo: ' ',
            subdomain,
            evoDatabaseHost: evoDatabaseHost === '' ? undefined : evoDatabaseHost,
        };
        // Upload base64 encoded to S3.
        if (typeof logo === 'object' && logo.encodedFile) {
            const { encodedFile, file: { name: filename } } = logo;
            const body = {
                filename,
                payload: encodedFile,
                type: 'logo',
            };
            createTenant(tenantPayload, body);
        } else {
            createTenant(tenantPayload);
        }
    };

    const saveFormDataToReduxStore = (values: ConfigurationFormValues) => {
        const { bureauName: name, serialNumber, sso: enabled, mfa: mfaEnabled, logo, subdomain } = values;
        saveSsoData({
            name,
            serialNumber,
            enabled,
            mfaEnabled,
            subdomain,
            logo,
        });
    };

    const isNotDuplicateValue = (key: string, value: any) => {
        const { results: tenantsResults } = tenants;
        const { id: selectedTenantId } = selectedTenant;
        const duplicates = tenantsResults.filter(tenant => tenant[key] === value);
        return !(value && duplicates.length > 0 && duplicates.some(duplicate => duplicate.id !== selectedTenantId));
    };

    const baseValidationSchema = {
        bureauName: Yup
            .string()
            .required('CUSTOM_FORM_VALIDATION_ERROR'),
        subdomain: Yup
            .string()
            .test('duplicate-subdomain', 'FIELD_VALUE_IS_ALREADY_IN_USE', value => isNotDuplicateValue('subdomain', value))
            .required('CUSTOM_FORM_VALIDATION_ERROR'),
        serialNumber: Yup
            .string()
            .test('duplicated-serialNumber-1', 'FIELD_VALUE_IS_ALREADY_IN_USE', value => isNotDuplicateValue('serialNumber', value)),
    };

    const serialNumberRequiredSchema = {
        serialNumber: Yup
            .string()
            .test('duplicated-serialNumber-2', 'FIELD_VALUE_IS_ALREADY_IN_USE', value => isNotDuplicateValue('serialNumber', value))
            .required('CUSTOM_FORM_VALIDATION_ERROR'),
    };

    return (
        <React.Fragment>
            {isAuthorizedTo({ action: 'tenant:modify', resource: `tenants/${selectedTenant.id}` }, account) &&
            <div className="row">
                <div className="col-12 pl-2 d-flex flex-row justify-content-between align-items-start">
                    <h3 className="pt-3"><FormattedMessage id="SSO_CONFIGURATION" /></h3>
                    <Button
                        color="primary"
                        disabled={!disabled}
                        onClick={() => toggleEditMode(ConfigurationModes.Edit)}
                        className="btn-edit mt-2"
                        hidden={isCreateMode}
                    >
                        <FormattedMessage id="EDIT"/>
                    </Button>
                </div>
            </div>}
            <Formik
                initialValues={setInitialValues(isCreateMode ? undefined : selectedTenant)}
                enableReinitialize={true}
                validationSchema={() => {
                    const schema = isCreateMode ? { ...baseValidationSchema, ...serialNumberRequiredSchema } : baseValidationSchema;
                    return Yup.object().shape(schema)
                }}
                onSubmit={() => {}}
            >
                {({
                    errors,
                    isValid,
                    resetForm,
                    touched,
                    setFieldValue,
                    values,
                    }) => (
                    <React.Fragment>
                        <Form>
                            <div className="row">
                                <FormGroup id="ssoToggle" className="sso-toggle-wrapper col-12 form-control-sm d-flex align-items-center">
                                    <Label for="sso" className="mb-0 mr-4">
                                        <span className="h5 font-weight-normal"><FormattedMessage id="SINGLE_SIGN_ON_SSO" /></span>
                                    </Label>
                                    <Field
                                        name="sso"
                                        render={({ field }: FieldProps<ConfigurationFormValues>) => (
                                            <Toggle
                                                {...field}
                                                on={values.sso}
                                                disabled={disabled || isCreateMode}
                                                onChange={({ sso }) => setFieldValue('sso', sso)}
                                            />
                                        )}
                                    />
                                </FormGroup>
                            </div>
                            <div className="row">
                                <ControlledInput
                                    label="BUREAU_NAME"
                                    columnConfig={DEFAULT_FORM_COLUMNS}
                                    disabled={disabled}
                                    invalid={(errors.bureauName && touched.bureauName) as boolean}
                                    required={true}
                                    errorMessage={
                                        errors.bureauName &&
                                        <FormattedMessage id={errors.bureauName as string} values={{ FIELD_NAME: <FormattedMessage id="BUREAU_NAME" /> }}/>
                                    }
                                    renderField={(controlledInputProps: any) => (
                                        <Field
                                            name="bureauName"
                                            type="text"
                                            render={({ field }: FieldProps<ConfigurationFormValues>) => (
                                                <Input
                                                    {...field}
                                                    {...controlledInputProps}
                                                />
                                            )}
                                        />
                                    )}
                                />
                                <ControlledInput
                                    label="SERIAL_NUMBER"
                                    columnConfig={{ default: 12, sm: 3, md: 3, lg: 3, xl: 3 }}
                                    disabled={disabled}
                                    invalid={(errors.serialNumber && touched.serialNumber) as boolean}
                                    required={isCreateMode}
                                    errorMessage={
                                        errors.serialNumber &&
                                        <FormattedMessage id={errors.serialNumber as string} values={{ FIELD_NAME: <FormattedMessage id="SERIAL_NUMBER" /> }}/>
                                    }
                                    renderField={(controlledInputProps: any) => (
                                        <Field
                                            name="serialNumber"
                                            type="text"
                                            render={({ field }: FieldProps<ConfigurationFormValues>) => (
                                                <Input
                                                    {...field}
                                                    {...controlledInputProps}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </div>
                            <div className="row">
                                <ControlledInput
                                    label="SUBDOMAIN"
                                    columnConfig={DEFAULT_FORM_COLUMNS}
                                    disabled={disabled}
                                    invalid={(errors.subdomain && touched.subdomain) as boolean}
                                    required={true}
                                    errorMessage={
                                        errors.subdomain &&
                                        <FormattedMessage id={errors.subdomain as string} values={{ FIELD_NAME: <FormattedMessage id="SUBDOMAIN" /> }}/>
                                    }
                                    renderField={(controlledInputProps: any) => (
                                        <Field
                                            name="subdomain"
                                            type="text"
                                            render={({ field }: FieldProps<ConfigurationFormValues>) => (
                                                <Input
                                                    {...field}
                                                    {...controlledInputProps}
                                                />
                                            )}
                                        />
                                    )}
                                />
                                <ControlledInput
                                    label="EVOLUTION_DATABASE_HOST_URL"
                                    columnConfig={DEFAULT_FORM_COLUMNS}
                                    disabled={disabled}
                                    invalid={(errors.evoDatabaseHost && touched.evoDatabaseHost) as boolean}
                                    required={false}
                                    errorMessage={
                                        errors.evoDatabaseHost &&
                                        <FormattedMessage id={errors.evoDatabaseHost as string} values={{ FIELD_NAME: <FormattedMessage id="EVOLUTION_DATABASE_HOST_URL" /> }}/>
                                    }
                                    renderField={(controlledInputProps: any) => (
                                        <Field
                                            name="evoDatabaseHost"
                                            type="text"
                                            render={({ field }: FieldProps<ConfigurationFormValues>) => (
                                                <Input
                                                    {...field}
                                                    {...controlledInputProps}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </div>
                            <div className="row">
                                <FormGroup className="col-12 form-control-sm">
                                    <Label for="logo"><FormattedMessage id="LOGO"/></Label>
                                    <UploadComponent
                                        setFieldValue={setFieldValue}
                                        logo={values.logo}
                                        disabled={disabled}
                                    />
                                </FormGroup>
                            </div>
                            <div className="row">
                                <FormGroup id="mfaToggle" className="sso-toggle-wrapper col-12 form-control-sm">
                                    <div className="d-flex align-items-center border-top pt-5 mt-5">
                                        <Label for="mfa" className="mb-0 mr-4">
                                            <span className="h5 font-weight-normal">
                                                <FormattedMessage id="MFA_TITLE" />&nbsp;
                                                (<FormattedMessage id="MFA_ABBREV" />)
                                            </span>
                                        </Label>
                                        <Field
                                            name="mfa"
                                            render={({ field }: FieldProps<ConfigurationFormValues>) => (
                                                <Toggle
                                                    {...field}
                                                    on={isCreateMode && values.sso ? true : values.mfa}
                                                    disabled={disabled || !values.sso}
                                                    onChange={({ mfa }) => setFieldValue('mfa', mfa)}
                                                />
                                            )}
                                        />
                                    </div>
                                    <FormText color="muted mt-3">
                                        <FormattedMessage id="MFA_HELP"/>
                                    </FormText>
                                </FormGroup>
                            </div>
                            {!disabled &&
                            <div className="row">
                                <FormGroup className="col-12 text-right">
                                    <Button
                                        color="white"
                                        className="btn-cancel mr-3"
                                        onClick={() => {
                                            toggleEditMode(ConfigurationModes.View)
                                            resetForm();
                                        }}
                                    >
                                        <FormattedMessage id="CANCEL" />
                                    </Button>
                                    <Button
                                        color="primary"
                                        className="btn-publish"
                                        disabled={!isValid}
                                        onClick={() => {
                                            saveFormDataToReduxStore(values);
                                            toggleModal(true);
                                        }}
                                    >
                                        <FormattedMessage id={isEditMode ? 'PUBLISH' : 'CREATE'} />
                                    </Button>
                                </FormGroup>
                            </div>}
                        </Form>
                        <StandardModal
                            isOpen={modalIsOpen}
                            toggle={isLoading ? undefined : () => {toggleModal(false)}}
                            disabled={isLoading}
                            titleId={isEditMode ? 'PUBLISH_CHANGES' : 'CREATE_TENANT'}
                            confirmButtonId={isEditMode ? 'OK_PUBLISH' : 'OK_CREATE'}
                            cancelButtonId="CANCEL"
                            onConfirm={() => handlePublish(values)}
                            modalClassName="dialog"
                        >
                            <h6 className="font-weight-normal"><FormattedMessage id="PUBLISH_TENANT_CHANGES_MESSAGE"/></h6>
                        </StandardModal>
                    </React.Fragment>
                )}
            </Formik>
        </React.Fragment>
    );
}
