import * as React from 'react';
import * as queryString from 'query-string';
import { BASE_URL, TENANT_BUCKET } from '../../constants';
import { request } from '../../helpers/http';
import { Tenant } from '../../models/tenants';
import MfaChooseMethod from '../../components/MfaDrawer/MfaChooseMethod';
import MfaSetupDevice from '../../components/MfaDrawer/MfaSetupDevice';
import MfaConfirmSetup from '../../components/MfaDrawer/MfaConfirmSetup';
import { HeisenbergLayout } from './HeisenbergLayout';
import { generateOtpSecret, generateOtpCode } from '../../helpers/auth';
import { REACT_APP_MFA_WEB_SETUP_COMPLETE_URL } from '../../constants/globals'
import { AccountData } from '../../models';

type SelectedSetupType = 'email' | 'device' | undefined;

interface AccountSetupAppState {
  selectedSetupType: SelectedSetupType;
  account: AccountData,
  tenant: Tenant,
  accessToken: string,
  continuationToken: string,
  isComplete: boolean,
  isUpdatingTOTP: boolean,
  updatedTOTPError?: Error
}

export class AccountSetupApp extends React.Component<{}, AccountSetupAppState> {
  async componentDidMount() {
    const query = queryString.parse(window.location.search);
    const { access_token: accessToken, continuation_token: continuationToken } = query;

    if (!accessToken || !continuationToken) {
      // this could probably be improved by redirecting the user to account-settings, but that UX should get thought through first
      // if you're trying to test this page locally, log into an account of a tenant with mfaRequired:true. Remove your TOTP device, you will
      // then get redirected to this page. Change the url to your localhost version.
      console.log('an access token and continuation token is required to use this page');
      return;
    }

    const token = JSON.parse(atob(accessToken.split('.')[1]));

    const { account } = token;

    const tenant: Tenant = await request({
      url: `${BASE_URL}/identity/tenants/${account.tenantId}`,
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    this.setState({
      account,
      tenant,
      accessToken,
      continuationToken,
      isComplete: false,
      isUpdatingTOTP: false,
    });
  }

  enableMfa = async ({ secret, totpCode, useEmailOnly }: { secret: string, totpCode: string, useEmailOnly: boolean }) => {
    const { account, accessToken } = this.state;
    const { tenantId, id: accountId } = account;

    this.setState({ isUpdatingTOTP: true });

    try {
      await request({
        url: `${BASE_URL}/identity/tenants/${tenantId}/accounts/${accountId}/totp`,
        method: 'PUT',
        headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' },
        body: { secret, totpCode, useEmailOnly },
      });
      this.setState({ isComplete: true });
    } catch (updatedTOTPError) {
      this.setState({ updatedTOTPError });
    } finally {
      this.setState({ isUpdatingTOTP: false });
    }
  }

  enableMfaEmail = async () => {
    // for a user to get to this flow, they must have just entered a code sent to their email
    // so we want their totp device to no longer by autoGenerated, and to be verified
    // the easiest way to do that is to create a new secret (which isn't an issue b/c they don't have / know the old secret)
    // and put a new totp device
    const secret = generateOtpSecret();
    const totpCode = generateOtpCode(secret);

    await this.enableMfa({ secret, totpCode, useEmailOnly: true });
  }

  selectMfaMethod = async (selectedSetupType: SelectedSetupType) => {
    if (selectedSetupType === 'email') {
      await this.enableMfaEmail();
    }

    this.setState({ selectedSetupType });
  }

  completeAccountSetup = () => {
    const { continuationToken } = this.state as any;
    window.location.href = REACT_APP_MFA_WEB_SETUP_COMPLETE_URL.replace('{continuation_token}', continuationToken);
  }

  getMfaSetupDeviceLoadingStatus = () => {
    const { isUpdatingTOTP, updatedTOTPError } = this.state;

    if (isUpdatingTOTP) {
      return 'loading';
    } else if (updatedTOTPError) {
      return 'error';
    }

    return '';
  }

  render() {
    if (!this.state) {
      return null;
    }
    const { account, tenant, selectedSetupType, isComplete } = this.state;

    const logoUrl = `${TENANT_BUCKET}/${tenant.logo}`;

    let SetupStep: React.SFC;

    if (isComplete) {
      SetupStep = () => (
        <MfaConfirmSetup
          onConfirmed={this.completeAccountSetup}
          selectedSetupType={selectedSetupType}
        />
      )
    } else if (!selectedSetupType) {
      SetupStep = () => (
        <MfaChooseMethod
          account={account}
          selectSetupType={this.selectMfaMethod}
          defaultSetupType="device"
        />
      )
    } else {
      SetupStep = () => (
        <MfaSetupDevice
          account={account}
          loading={{ status: { UPDATE_ACCOUNT_TOTP: this.getMfaSetupDeviceLoadingStatus() } }}
          tenant={tenant}
          onCancel={() => { this.setState({ selectedSetupType: undefined }) }}
          onEnableMfa={(payload: { secret: string, totpCode: string, useEmailOnly: boolean }) => this.enableMfa(payload)}
        />
      );
    }

    return (
      <HeisenbergLayout logoUrl={logoUrl}>
        <SetupStep />
      </HeisenbergLayout>
    );
  }
}
