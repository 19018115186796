import { BASE_URL, EVO_APP_ID } from '../../constants';
import { request } from '../../helpers/http';
import { getToken } from '../../helpers/tokens';
import { createAsyncAction } from '../async/async';
import { authFallback } from '../authentication/authentication';
import { GenericAction, Company, Tenant } from '../../models';

export const LOAD_COMPANIES = 'LOAD_COMPANIES';
export type LOAD_COMPANIES = typeof LOAD_COMPANIES;

export interface CompanyPayload {
    id: string;
    companies: {
        results: Company[];
    };
}

export interface LoadCompanies extends GenericAction<CompanyPayload> {
    type: LOAD_COMPANIES;
}

export type CompanyAction = LoadCompanies;

export function loadCompanies({ id, subdomain }: Tenant) {
    return createAsyncAction(LOAD_COMPANIES, () => request({
        url: `${BASE_URL}/identity/tenants/${id}/oauth/token`,
        method: 'POST',
        headers: { Authorization: `Bearer ${getToken()}` },
        body: { grant_type: 'token_exchange', audience: EVO_APP_ID },
    }).then((tokenResponse) => request({
            url: `${BASE_URL}/v3/api/bureaus/${subdomain}/companies`,
            headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
    })).then((companies) => {
        return { id, companies };
    }), authFallback);
}
