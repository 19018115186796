import { HashMap } from '../helpers/HashMap';

export interface IntegrationKey {
    id: string;
}

export interface Integration {
    id: string;
    key: string;
    name: string;
    description: string;
}

export interface ClientKey {
    tenantId: string;
    clientId: number | string;
}

export interface CompanyKey {
    tenantId: string;
    clientId: number | string;
    companyId: number | string;
}

export interface EvoCompanyKey {
    clientId: number | string;
    id: number | string;
}

export interface Company {
    id: number;
    name: string;
    shortname: string;
    clientId: number;
    clientName: string;
    clientShortName: string;
    isRestrictedToSuperAdmin: boolean;
}

export interface CompaniesByTenant {
    [tenantId: string]: Company[];
}

export interface EDCode {
    id: number;
    code: string;
    codeType: string;
    description: string;
}

export interface EDCodesByCompany {
    [companyKey: string]: EDCode[]
}

export interface TenantIntegrationKey {
    integrationId: string;
    tenantId: string;
}

export interface ConfigurationKey {
    integrationId: string;
    tenantId: string;
    clientId: number | string;
    companyId: number | string;
}

export interface Configuration {
    id?: string;
    integrationId: string;
    tenantId: string;
    clientId: number;
    companyId: number;
    companyName: string;
    companyNumber?: string;
    integrationDetails: any;
}

export interface TenantSettings {
    id?: string;
    integrationId: string;
    tenantId: string;
    integrationDetails: any;
}

export class ConfigurationsMap extends HashMap<TenantIntegrationKey, Configuration[]> {
    keyToString = (key: TenantIntegrationKey) => `${key.tenantId}@${key.integrationId}`;
}

export class TenantSettingsMap extends HashMap<TenantIntegrationKey, TenantSettings> {
    keyToString = (key: TenantIntegrationKey) => `${key.tenantId}@${key.integrationId}`;
}

export interface Log {
    id: string;
    clientId?: number;
    companyId?: number;
    createdAt: string;
    event: string;
    status: string;
    messages: string[];
}

export class LogsMap extends HashMap<TenantIntegrationKey, Log[]> {
    keyToString = (key: TenantIntegrationKey) => `${key.tenantId}@${key.integrationId}`;
}

export interface TenantIntegrationKey {
    integrationId: string;
    tenantId: string;
}

export interface Paygroup {
    tlmPayGroupId: string;
    code: string;
    name: string;
}

export interface PaygroupsByCompany {
    [companyKey: string]: Paygroup[]
}

export interface Person {
    id: string;
    name: string;
}

export interface Employee {
    companyId: string;
    id: string;
    person: Person;
    employeeNumber: string;
    tlmPayGroupId: string;
}

export interface EmployeesByCompany {
    [companyId: string]: Employee[];
}

