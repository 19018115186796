export default {
  ACCOUNT_DETAILS_AND_MAPPINGS_WILL_BE_SAVED: 'Account details and mappings will be saved.',
  ACCOUNT_SETTINGS: 'My Account',
  ACTION: 'Action',
  ACTIVITY_LOG: 'Activity Log',
  ADMIN: 'Admin',
  ADD_NEW: 'Add new',
  ADD_NEW_ACCOUNT: 'Add a new account',
  ADD_NEW_ACCOUNT_HELP: 'in most apps you can tap on the “+” icon.',
  ADD_THIS_ACCOUNT: 'Add this account',
  ADD: 'Add',
  ADD_COMPANY: 'Add Company',
  ADVANCED_HR: 'Advanced HR',
  ADVANCED_HR_CONFIGURATION_VERBIAGE: 'Provisioning and configuration tools for Advanced HR 2.0',
  APPROVE: 'APPROVE',
  ASUREFORCE: 'Asure Time & Attendance',
  ASUREFORCE_URL: 'Asure Time & Attendance Url',
  ASUREFORCE_NO_ENROLLED_COMPANIES: 'There are no companies signed up with<br /> Asure Time & Attendance.',
  AT: 'at',
  BLANK: ' ',
  BUREAU: 'Bureau',
  BUREAUS: 'Bureaus',
  BUREAU_NAME: 'Bureau Name',
  BY: 'by',
  CANCEL: 'Cancel',
  CANCEL_ADVANCED_HR_DATABASE_DELETION: 'Cancel AdvancedHR database deletion',
  CANCEL_DELETE_CONFIRMATION_MESSAGE: 'Are you sure you want to cancel the database deletion for {tenantName}?',
  CANCEL_DELETION: 'Cancel Deletion',
  CANNOT_SCAN_CODE: 'Can\'t scan the code?',
  CANNOT_SCAN_CODE_HELP: 'Enter this key into the authenticator app instead:',
  CHANGE_DEVICE: 'Change device',
  CHECKING_FOR_AHR_DATABASE: 'Checking to see if a database has been created for {tenantName}',
  CHOOSE_A_FILE: 'choose a file',
  CHOOSE_SETTINGS: 'Choose Settings for Timecard Data',
  CONFIGURE: 'Configure',
  CONFIGURATION_CREATED_ON: 'Configuration created on',
  COMPANY: 'Company',
  COMPANIES: 'Companies',
  COMPLETE: 'Complete',
  CONFIGURE_SETTINGS: 'Configure Settings',
  CONFIRM_ORG_LEVELS: 'Confirm Payroll DBDT to TLM Organization Levels',
  CONNECT: 'Connect',
  CONNECT_ACCOUNTS: 'Connect Accounts',
  CONNECTED: 'Connected',
  CONNECTED_TO: 'Connected to {CONNECTED_NAME}',
  CONNECT_TO: 'Connect to {CONNECTED_NAME}',
  CONNECTED_TO_ASUREFORCE: 'Connected to Asure Time & Attendance',
  CONNECT_TO_ASUREFORCE: 'Connect to Asure Time & Attendance',
  CONTINUE: 'Continue',
  COPY: 'Copy',
  COPY_OR_SAVE_DETAILS: 'Copy or save these details',
  COPY_TOTP_SECRET: 'Copy',
  COPY_TOTP_SECRET_SUCCESS: 'Copied!',
  CREATE: 'Create',
  CREATE_TENANT: 'Create tenant',
  CREATE_INTEGRATION_USER: 'Create integration user',
  CREATE_ADVANCED_HR_DATABASE: 'Create Advanced HR database',
  CREATE_ADVANCED_HR_INTEGRATION_USER: 'Create Advanced HR User Credentials',
  HR_INTEGRATION_USER_CONFIRMATION: 'Proceed with creating Advanced HR user credentials?',
  CUSTOM_FORM_VALIDATION_ERROR: 'Please enter a {FIELD_NAME}.',
  DATABASE_CREATED: 'A database has been created for {tenantName}',
  USER_CREATED: 'Integration user credentials has been created for {tenantName}',
  DATABASE_DELETION_SCHEDULED: 'The database for {tenantName} is currently scheduled to be deleted on {date} at {time}',
  DATA_WILL_BE_AUTOMATICALLY_SYNCED: 'Data will be automatically synced between systems.',
  DATA_WILL_NO_LONGER_BE_SYNCED: 'Data will no longer be synced between systems.',
  DATE_AND_TIME: 'Date and time',
  DELETE: 'Delete',
  DELETING: 'Deleting',
  DELETE_ADVANCED_HR_DATABASE: 'Delete Advanced HR database',
  DELETE_CONFIRMATION_MESSAGE: `Are you sure you want to delete the database for {tenantName}? 
  The deletion will take place on {date} at {time}, and can be cancelled at any point before then.`,
  DETAILS: 'Details',
  DIMENSIONS: 'Dimensions',
  DISABLE_MFA_QUESTION: 'Disable two-factor authentication?',
  DISABLE: 'Disable',
  DISABLE_ASURE_FORCE_INTEGRATION: 'Disable Asure Time & Attendance integration?',
  DISABLED: 'Disabled',
  DISABLING_LESS_SECURE_MESSAGE: 'Disabling two-factor authentication will make your account less secure.',
  DISCARD_CHANGES: 'Discard Changes',
  DROP_AN_IMAGE_HERE: 'Drop an image here',
  ED_CODE: 'E/D code',
  ED_CODES_MAPPED: 'E/D Codes Successfully Mapped',
  EDIT: 'Edit',
  EDIT_MAPPINGS: 'Edit mappings',
  EMAIL: 'Email',
  ERROR_CANCELLING_DATABASE_DELETION: 'Sorry, an error occured while cancelling the database deletion for {tenantName}',
  ERROR_CONTACT: 'If you continue to experience problems, please contact your administrator.',
  ERROR_CREATING_DATABASE: 'Sorry, an error occured while creating the data base for {tenantName}',
  ERROR_HEADING: 'Sorry, something went wrong.',
  ERROR_MAYBE_RETRY: "We're sorry, something went wrong with your request, please try again.",
  ERROR_RETRY: 'Retry the action that failed.',
  ERROR_SCHEDULING_DATABASE_DELETION: 'Sorry, an error occured while scheduling the database deletion for {tenantName}',
  ERROR_TEXT: 'We were unable to display the page.',
  EMAIL_ADDRESS: 'Email address',
  EMPLOYEE: 'Employee',
  EMPLOYEE_PAYGROUP_MAPPING: 'Sync Employees and Set Paygroups',
  EMPLOYEE_PAYGROUPS_SUCCESSFULLY_MAPPED: 'Employees and paygroups have been successfully paired.',
  EMPLOYEES_HAVE_BEEN_SUCCESSFULLY_MAPPED: 'Payroll Employees have been successfully matched to existing Asure Time & Attendance Employees.',
  EMPLOYEES_HAVE_BEEN_AUTO_MAPPED: 'We have attempted to automatically match Payroll Employess to Asure Time & Attendance Employees.  Please confirm these mappings are correct.',
  ENABLE: 'Enable',
  ENABLE_ASUREFORCE_INTEGRATION: 'Enable Asure Time & Attendance integration?',
  ENABLE_INTEGRATION: 'Enable Integration',
  ENABLE_PAY_RATES_IN_PUNCH_PULL: 'Employee pay rates will be synced to Asure Time & Attendance and included in time card imports.',
  ENABLE_TIME_CARD_PUNCH_IMPORT: 'Punch data that has been approved will be automatically imported into scheduled payrolls.',
  ENABLED: 'Enabled',
  ENTER_CREDENTIALS_ASUREFORCE: 'Enter credentials for the Asure Time & Attendance account that you want to connect Test Company to.  The account must have full API access.',
  ENTER_VERIFICATION_CODE: 'Enter verification code',
  EVOLUTION_DATABASE_HOST_URL: 'Payroll DB Host',
  EVOLUTION_EARNINGS_CODE: 'Payroll Earnings Code',
  EVOLUTION_EMPLOYEE: 'Payroll Employee',
  EVOLUTION_LEVEL: 'Payroll Level',
  EVOLUTION_LEVEL_DBDT_CONFIRMED: 'Payroll DBDT to TLM Organization Levels Confirmed',
  FAILED_TO_CHECK_FOR_DATABASE: 'Sorry, an error occurred while trying to find the database for {tenantName}.',
  FIELD_VALUE_IS_ALREADY_IN_USE: 'This {FIELD_NAME} is already in use.',
  FORCE_EMPLOYEE: 'TLM Employee',
  FORCE_LEVEL: 'TLM Level',
  FORCE_PAYTYPE: 'TLM Paytype',
  FILE: 'File',
  GENERATE_CODE_AND_URL: 'Generate code and URL',
  GET_AUTH_APP: 'Get an authenticator app',
  GET_AUTH_APP_HELP: 'Download and install a two-factor authentication app on your mobile device. You can use <a href="https://support.google.com/accounts/answer/1066447" target="_blank"> Google Authenticator</a> or a similar app.',
  GO_BACK: 'Go back',
  I_AM_SURE: 'I am sure',
  INCORRECT_MAPPINGS_PLEASE_FIX: 'If any of the above mappings are incorrect please update the organization levels and click refresh.',
  INCOMPLETE: 'Incomplete',
  INTEGRATE_A_COMPANY_WITH_ASUREFORCE: 'Integrate a company with Asure Time & Attendance',
  INTEGRATION_ACTIVE: 'Integration is now active!',
  INTEGRATIONS: 'Integrations',
  INVALID_FILE_TYPE: '<strong>Oops!</strong> This file type is not supported.',
  LAST_MODIFIED_ON: 'Last modified on',
  LAST_SYNC: 'Last Sync',
  LAST_UPDATED: 'Last updated',
  LOADING: 'Loading',
  LOADING_ROLES: 'Loading roles',
  LOAD_TENANTS: 'Load Tenants',
  LOGO: 'Logo',
  LOG_OUT: 'Sign Out',
  MAP: 'Set',
  MAPPED: 'Mapped',
  MAP_ED_CODES: 'Map E/D Codes',
  MAP_EMPLOYEE: 'Pair Employee',
  MAP_EMPLOYEES: 'Employee Pairings',
  MAP_FORCE_EMPLOYEES_TO_EVO: 'Please match Payroll Employees to existing TLM Employees. Required for existing setups.',
  MAP_FORCE_PAYTYPE_TO_ED_CODES: 'Map Force Paytype to E/D Code',
  MAPPINGS: 'Mappings',
  MFA_ABBREV: '2FA',
  MFA_DEVICE_DESCRIPTION: 'Use a free app on your mobile device to generate codes.',
  MFA_DEVICE_ENABLED_MESSAGE: 'From now on, you’ll need to <strong>enter your password and a code from your authenticator app</strong> to sign in.',
  MFA_DEVICE_FORGOT_MESSAGE: 'If you forget your device you can receive a code via email.',
  MFA_DEVICE_NAME: 'Authenticator app',
  MFA_DEVICE_REMOVED: 'Your authentication device will be removed from your settings.',
  MFA_DISABLED: 'Two-factor authentication has been <strong>disabled</strong> for your account.',
  MFA_DRAWER_TITLE: 'Two-factor authentication',
  MFA_EMAIL_DESCRIPTION: 'Have a code emailed to {ACCOUNT_EMAIL}.',
  MFA_EMAIL_ENABLED_MESSAGE: 'From now on, you’ll need to <strong>enter your password and a code sent to your email address</strong> to sign in.',
  MFA_EMAIL_FORGOT_MESSAGE: 'If you cannot login to your email account you will need to contact a system administrator.',
  MFA_EMAIL_NAME: 'Email',
  MFA_EMAIL_REMOVED: 'Your authentication email account will be removed from your settings.',
  MFA_EMAIL_RESEND_CODE_PROMPT: 'Didn\'t receive the code?',
  MFA_EMAIL_RESEND_CODE: 'Resend the code.',
  MFA_EMAIL_VERIFY_DESCRIPTION: 'We just sent an email with a verification code to {ACCOUNT_EMAIL}.',
  MFA_ENABLED: 'Two-factor authentication enabled!',
  MFA_HELP: 'Adds a second layer of security on sign-in, requiring the user to use an authenticator app on their device. This can only be enabled when SSO is enabled.',
  MFA_SELECT_TYPE_TEXT: 'How do you want to receive your verification codes?',
  MFA_SELECT_TYPE_TITLE: 'Choose a verification method',
  MFA_TITLE: 'Two-factor authentication',
  MFA_TOTP_VERIFY_DEVICE_LABEL: 'Enter the six-digit code that the authenticator app generates.',
  MFA_TOTP_VERIFY_EMAIL_LABEL: 'Enter that code below.',
  MFA_USER_HELP_EMAIL_ONLY: 'This helps to protect your account. Whenever you sign in, you’ll be required to enter <strong>your password and a unique code from your email</strong> to verify your identity.',
  MFA_USER_HELP: 'This helps to protect your account. Whenever you sign in, you’ll be required to enter <strong>your password and a unique code from either your mobile device or your email</strong> to verify your identity.',
  MFA_VERIFY_NAME: 'Verify account',
  NAME_AND_USERNAME: 'Name and username',
  NO: 'No',
  NO_ACCOUNT_ROLES: 'You do not have permission to list roles.',
  NO_BUREAUS: 'You do not have permission to edit any bureaus.',
  NO_DATABASE_EXISTS: 'No database has been created for {tenantName}',
  NO_EMPLOYEE_MATCH_WAS_FOUND: 'No Asure Time & Attendance employee was found that maps to the existing Payroll Employee.',
  NO_FORCE_EMPLOYEES_FOUND: 'No employees were found in Asure Time & Attendance.  If this is correct please click Map to sync employees.  If this is incorrect please contact your administrator.',
  NO_MAPPINGS_WERE_FOUND: 'No organizational mappings were found.  If this is incorrect please update mappings and click refresh.  If this is correct please approve and continue with the configuration of Asure Time & Attendance.',
  NO_USERS_MATCH: 'No users match your search "{SEARCH_QUERY}".',
  NOT_AUTHORIZED: 'Not Authorized',
  NOT_CONNECTED: 'Not connected',
  NOT_ENABLED: 'Not Enabled',
  NOT_FOUND: 'Not Found',
  NOTIFICATION: 'Notification',
  OFF: 'Off',
  OK: 'OK',
  OK_CREATE: 'OK, create',
  OK_GOT_IT: 'OK, got it',
  OK_PUBLISH: 'OK, publish',
  ON: 'On',
  OR: 'or',
  ORG_HIERARCHY_LEVELS: 'Organization Hierarchy Levels',
  OPEN_AUTH_APP: 'Open the authenticator app and:',
  OPTIONAL: 'Optional',
  PASSWORD: 'Password',
  PASSWORD_ONLY_MESSAGE: 'You\'ll only need your password to sign in.',
  PAYGROUP: 'Paygroup',
  PAY_RATES_HEADING: 'Sync pay rates',
  PENDING: 'Pending',
  PIXELS: 'pixels',
  PLEASE_CONFIRM: 'Please Confirm',
  PLEASE_CONFIRM_MAPPINGS: 'Please confirm your Payroll DBDT to TLM Organization Level Mappings are correct:',
  PLEASE_MAP_FORCE_PAYTYPE_TO_ED_CODE: 'Please map your TLM Paytypes to the correct E/D codes:',
  PLEASE_MAP_EMPLOYEES: 'Please map employees to the correct paygroup; employees will not synch to TLM until this has been completed',
  PLEASE_MAP_EMPLOYEES_TO_CORRECT_PAYGROUP: 'Please map employees to the correct paygroup:',
  PLEASE_MAP_EVO_EMPLOYEES_TO_FORCE_EMPLOYEES: 'Please match Payroll Employees to existing Asure Time & Attendance Employees:',
  PROCEED_TO_EVOLUTION_PAYROLL: 'Integration Enabled - Please complete employee paygroup mapping',
  PUBLISH: 'Publish',
  PUBLISH_CHANGES: 'Publish changes',
  PUBLISH_TENANT_CHANGES_MESSAGE: 'Your changes will go into effect immediately.',
  RECOMMENDED_IMAGE_SIZE_MESSAGE: '<strong>Recommended image size:</strong> 300 x 100 pixels. Supported file types: .jpg, .gif, or .png',
  REFRESH: 'Refresh',
  ROLE: 'Role',
  ROLES: 'Roles',
  SCAN_QR_CODE: 'Scan the QR code to the left.',
  SCAN_QR_CODE_MOBILE: 'Scan the QR code below:',
  SECURITY: 'Security',
  SEARCH: 'Search',
  SEARCH_USERS: 'Search users',
  SELECT_ED_CODE: 'Select E/D Code',
  SELECT_THE_COMPANY: 'Select the company you want to add to the Asure Time & Attendance integration.',
  SERIAL_NUMBER: 'Serial Number',
  SESSION_EXPIRED: 'Your session has expired',
  SET_UP: 'Set up',
  SETTINGS: 'Settings',
  SETUP_NEEDED: 'Setup needed',
  SINGLE_SIGN_ON_SSO: 'Single Sign-On (SSO)',
  SIX_DIGIT_CODE: '6-digit code',
  SIX_DIGIT_CODE_ERROR: 'Please enter a 6-digit code.',
  SIZE: 'Size',
  SSO_CONFIGURATION: 'Authentication',
  START: 'Start',
  START_SYNCING: 'Start syncing data between Payroll and Asure Time & Attendance',
  STATUS: 'Status',
  STATUS_KEY: 'Status key',
  STOPPED: 'Stopped',
  STOPPING: 'Stopping',
  SUBDOMAIN: 'Subdomain',
  SYNC_STATUS: 'Sync Status',
  TENANT_URL_STILL_EXISTS_MESSAGE: 'The tenant\'s URL still exists. You must delete the tenant\'s URL first before proceeding.',
  TEST_CONFIGURATION_STEP_TITLEID: 'Step Title',
  TEST_CONFIGURATION_STEP_SUBHEADER: 'I am a test subheader',
  TEST_STANDARDMODAL: 'Test standardmodal',
  TIME_CARD_PUNCH_DATA: 'Import time card punch data',
  TIME_CARD_SETTINGS_CONFIGURED: 'Timecard Data Settings Successfully Configured',
  UNNAMED_COMPANY: 'Unnamed company',
  UNRECOGNIZED_COMPANY: 'Unrecognized company "{COMPANY_ID}" in bureau "{BUREAU_NAME}".',
  UNRECOGNIZED_INTEGRATION: 'Unrecognized integration "{INTEGRATION_KEY}".',
  UNSAVED_CHANGES: 'Unsaved changes',
  UNSAVED_MESSAGE: 'You have unsaved changes. If you leave, your changes will be lost.',
  URL: 'URL',
  USERNAME: 'Username',
  USER_DETAILS: 'User details',
  USER_DETAILS_HELP: 'Your username and email address are managed by your administrator.',
  USER_NOT_FOUND: 'User "{USERNAME}" not found.',
  USERS: 'Users',
  USERS_NOT_FOUND: 'No users found.',
  UPDATE: 'Update',
  VERIFICATION: 'Verification',
  VERIFICATION_CODE: 'Verification Code',
  VERIFICATION_CODE_DID_NOT_MATCH: 'Your verification code did not match. Please try again.',
  VERIFICATION_NOT_STORED_DESCRIPTION: 'The codes and URLS are not stored in the App Manager, so you will need to copy or save them.',
  VERIFY_SERVICE_ACCOUNT_DESCRIPTION: 'Generate a verification code and URL for the Service Bureau to enable their service account.',
  VERIFY_SERVICE_ACCOUNT_TITLE: 'Verify Service Account for SSO',
  WARNING: 'Warning!',
  WELCOME: 'Welcome',
  XACTPAY: 'XactPAY',
  XACTPAY_ACCOUNT: 'XactPAY account',
  XACTPAY_ACTIVITY_LOG: 'Activity log for XactPAY data transmissions',
  XACTPAY_EDIT_SETTINGS: 'Edit settings',
  XACTPAY_CONSENT_ABSENT: 'The company must sign up for an account with the <a target="_blank" href="http://www.xactpay.com/?source=email">XactPAY program</a> from The Hartford, and keep the account in good standing. (It may take up to 24 hours for new accounts to be reported to us).',
  XACTPAY_CONSENT_RECEIVED: 'The company has a valid account with the <a target="_blank" href="http://www.xactpay.com/?source=email">XactPAY program</a> from The Hartford and we have consent to send payroll data.',
  XACTPAY_CONSENT_STOPPED: 'Consent has been stopped by The Hartford.  The company no longer has a valid account with the XactPAY program and payroll data is no longer being sent.',
  XACTPAY_CONSENT_STOPPING: 'The company has a valid account with the <a target="_blank" href="http://www.xactpay.com/?source=email">XactPAY program</a> from The Hartford and we have consent to send payroll data.<br><br>Payroll will no longer be sent to The Hartford starting on {STOP_DATE}',
  XACTPAY_DESCRIPTION: 'XactPAY is a pay-as-you-go workers\' compensation insurance program from The Hartford.  Instead of using an annual estimate, XactPAY calculates premiums payroll by payroll, resulting in more accurate payments and reducing the risk of under-reporting.',
  XACTPAY_ENROLLED_COMPANIES: 'Companies signed up with XactPAY',
  XACTPAY_EXCLUDE_WARNING: 'You are choosing to exclude E/D code "{CODE}", which means it will not be sent to The Hartford.',
  XACTPAY_MAPPING: 'Match the E/D codes on the left with XactPAY Paytypes on the right.  A Paytype can be matched to multiple E/D codes.',
  XACTPAY_NOTIFY_EMAIL_LABEL: 'Email address to receive notifications from this integration',
  XACTPAY_NO_ENROLLED_COMPANIES: 'There are no companies signed up with<br /> XactPAY.',
  XACTPAY_PAYTYPE: 'XactPAY Paytype',
  XACTPAY_POLICY_EFFECTIVE_DATE: 'Policy effective date',
  XACTPAY_SELECT_PAYTYPE: 'Select a paytype',
  XACTPAY_SETTINGS_LABEL: 'Settings',
  XACTPAY_SETTINGS_HEADING: 'Settings for XactPAY integration',
  YES: 'Yes',
}
