import { LOAD_COMPANIES } from '../../actions/integrations/companiesByTenant';
import { Action } from 'redux-actions';
import { CompaniesByTenant, Tenant, Company } from '../../models';
import { createSelector } from 'reselect';

const initialState: CompaniesByTenant = {};

export function companiesByTenant(state: CompaniesByTenant = initialState, action: Action<any>): CompaniesByTenant {
    if (!action.error && action.type && action.payload) {
        switch (action.type) {
            case LOAD_COMPANIES: {
                const { payload } = action;
                const { id, companies } = payload;
                return {
                    ...state,
                    [id]: companies.results,
                }
            }
        }
    }
    return state;
}

// memoized selector to extract the companies for a specified tenant
export const getCompanies = createSelector<CompaniesByTenant, Tenant | undefined, CompaniesByTenant, Tenant | undefined, Company[] | undefined>(
    (state) => state,
    (state, props) => props,
    (byTenant, tenant) => {
        return tenant ? byTenant[tenant.id] : undefined;
    }
)
