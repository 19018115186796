import { LOAD_INTEGRATION_LOGS } from '../../actions/integrations/logsMap';
import { Action } from 'redux-actions';
import { LogsMap } from '../../models';

const initialState = new LogsMap();

export function logsMap(state: LogsMap = initialState, action: Action<any>): LogsMap {
    if (!action.error && action.type && action.payload) {
        switch (action.type) {
            case LOAD_INTEGRATION_LOGS: {
                const { payload } = action;
                const { key, logs } = payload;
                return new LogsMap(state).set(key, logs);
            }
        }
    }
    return state;
}
