import { isLoading, isLoaded, isFailed } from '../loading/loading';
import { Dispatch } from 'react';

const TIMEOUT_MS = 15000;

export function createAsyncAction(
    type: string,
    doAsync: () => Promise<any>,
    fallbackActionProvider?: (error: any) => any,
    timeout = TIMEOUT_MS,
    retry = 0
) {
    if (!type) {
        throw new Error('An action type is required to create an async action');
    }

    const asyncAction = (dispatch: Dispatch<any>) => {
        let timedOut = false;
        dispatch(isLoading(type));

        return new Promise<any>((resolve, reject) => {
            const timeoutId = setTimeout(() => {
                timedOut = true;
                const message = 'Async action timed out';
                dispatch(isFailed(type, message));
                reject(new Error(message));
            }, timeout);
            doAsync().then(
                // Do not use catch, because that will also catch
                // any errors in the dispatch and resulting render,
                // causing a loop of 'Unexpected batch number' errors.
                // https://github.com/facebook/react/issues/6895
                (result: any) => {
                    if (!timedOut) {
                        clearTimeout(timeoutId);
                        dispatch({ type, payload: result });
                        dispatch(isLoaded(type));
                        resolve(result);
                    }
                },
                (error: any) => {
                    if (!timedOut) {
                        clearTimeout(timeoutId);
                        if (retry > 0) {
                            dispatch(createAsyncAction(type, doAsync, fallbackActionProvider, timeout, --retry));
                        } else {
                            dispatch(isFailed(type, error));
                            if (fallbackActionProvider) {
                                const fallbackAction = fallbackActionProvider(error);
                                if (fallbackAction) {
                                    dispatch(fallbackAction);
                                }
                            }
                        }
                        reject(error);
                    }
                }
            );
        });
    };
    // add type proprty to returned function - hacky, but redux dispatch expects it
    asyncAction.type = `ASYNC_${type}`;
    return asyncAction;
}
