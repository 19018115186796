import * as React from 'react';

import { Tenant, AccountData } from '../../models';
import Header from './Header';
import AccountDropdown from './AccountDropdown/AccountDropdown';
import SearchDropdown from './SearchDropdown/SearchDropdown';
import NoDropdown from './NoDropdown/NoDropdown';
import { TENANT_SEARCH_KEYS, TENANT_BUCKET } from '../../constants';
import { isAuthorizedTo } from '../../helpers/auth';
import { isAccountSettings } from '../../containers/App/AppRouter';

export interface AdminHeaderComponentProps {
    tenants: Array<Tenant>;
    account?: AccountData;
    selectTenant: (tenantId: string) => string;
    selectedTenant?: Tenant;
    toggleMode: (mode: string) => void;
    configurationMode?: string;
}
export default function AdminHeader({ tenants, account, selectTenant, selectedTenant, toggleMode, configurationMode }: AdminHeaderComponentProps) {
    const logo = selectedTenant && selectedTenant.logo && selectedTenant.logo !== ' ' ? `${TENANT_BUCKET}/${selectedTenant.logo}` : undefined;
    const shouldRenderSearchDropdown = !isAccountSettings() && (isAuthorizedTo({ action: 'tenant:list' }, account) || isAuthorizedTo({ action: 'tenant:create' }, account));
    const shouldRenderTenantName = !isAccountSettings() && !shouldRenderSearchDropdown;
    const headingId = isAccountSettings() ? 'ACCOUNT_SETTINGS' : 'ADMIN';

    return (
        <Header headingId={headingId} logo={logo}>
            {shouldRenderSearchDropdown ?
                <SearchDropdown
                    account={account}
                    searchToggleHeader="BUREAU"
                    searchData={tenants}
                    searchKeys={TENANT_SEARCH_KEYS}
                    searchHeaderText="BUREAUS"
                    selectItem={selectTenant}
                    selectedItem={selectedTenant}
                    toggleMode={toggleMode}
                    configurationMode={configurationMode}
                />
            : shouldRenderTenantName ?
                <NoDropdown
                    header="BUREAU"
                    selectedItem={selectedTenant}
                />
            : null}
            <AccountDropdown
                account={account}
            />
        </Header>
    );
}
