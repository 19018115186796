export interface Named { name: string; }
export interface Items { items: object; }

export function alphabetizeByName<T extends Named>(named: Array<T>) {
    // Return a sorted copy of the array
    return [...named].sort((a: any, b: any) => (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1);
}
// Sorts array of objects based on object field
export function alphabetizeByField<T extends Items>(items: Array<T>, fields: any) {
    return items.sort((a: any, b: any) => {
        for (let i = 0; i < fields.length; i++) {
            if (a[fields[i]].toLowerCase() < b[fields[i]].toLowerCase()) {
                return -1;
            } else if (a[fields[i]].toLowerCase() > b[fields[i]].toLowerCase()) {
                return 1;
            }
        };
        return 0;
    });
}
// Sorts array of objects based on nested field
export function alphabetizeResultsByKey<T extends Items>(items: Object, sortArrayField: string, keys: any) {
    return items[sortArrayField].sort((a: any, b: any) => {
        for (let i = 0; i < keys.length; i++) {
            const key: string = keys[i];
            if (getPropByString(a, key) < getPropByString(b, key)) {
                return -1;
            } else if (getPropByString(a, key) > getPropByString(b, key)) {
                return 1;
            }
        }
        return 0;
    })
}
function getPropByString(obj: Object, propString: string) {
    if (!propString) { return obj };

    const props = propString.split('.');
    for (const prop of props) {
        const candidate = obj[prop];
        if (candidate !== undefined) {
            obj = candidate;
        } else {
            break;
        }
    }
    return obj;
}

// intended usage: map((item, ...rest) => isLast(...rest) ? 'x' : 'y';
export function isLast(index: number, list: any[]) {
    return index === list.length - 1;
}

// finds first object in a list which matches all the attributes of the provided key object
export function findByKey<T>(list: T[], compositeKey: object): T | undefined {
    for (const item of list) {
        if (Object.keys(compositeKey).every((key) => item[key] === compositeKey[key])) {
            return item;
        }
    }
    return undefined;
}
