import * as React from 'react';
import { Container, Row, Col, Card } from 'reactstrap';

import './HeisenbergLayout.scss';

export const HeisenbergLayout = ({ logoUrl, children }: { logoUrl: string, children: any }) => (
  <div className="setup-container d-flex flex-column">
    <Container>
      <Row className="justify-content-center">
        <Col className="setup-wrapper w-100 mb-2">
          <Card className="p-0 p-sm-4 pt-2 w-100 border-0">
            <img alt="logo" className="logo m-auto" src={logoUrl} />
          </Card>
          <div>
            {children}
          </div>
        </Col>
      </Row>
    </Container>
    <div className="mt-auto asure-footer text-center">
      <span>© 2019 Evolution HCM by Asure Software, Inc.</span>
    </div>
  </div>
);
