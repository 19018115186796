import * as React from 'react';
import { AccountsService } from '../../../containers/Views/Accounts/AccountsService';
import { AccountRole } from '../../../models/role';
import { withDependencies } from '@symbiotic/green-state';

interface InjectRolesProps {
    tenantId: string;
    accountId: string;
    accountsService?: AccountsService; // injected
    children: (props: InjectRolesRenderProps) => any;
}

interface InjectRolesState {
    roles: AccountRole[];
    isLoading: boolean;
    error?: Error;
}

interface InjectRolesRenderProps extends InjectRolesState {
    assignRole: (role: AccountRole) => Promise<void>;
    unassignRole: (role: AccountRole) => Promise<void>;
}

@withDependencies({ accountsService: AccountsService })
export class InjectRoles extends React.Component<InjectRolesProps, InjectRolesState> {
    state: InjectRolesState = {
        roles: [],
        isLoading: true,
        error: undefined,
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const { accountId, tenantId } = this.props;

        this.setState({ isLoading: true });

        const accountRoles = await this.props.accountsService!.getAccountRoles({ tenantId, accountId });

        this.setState({ roles: accountRoles, isLoading: false });
    }

    updateLocalRoleMembership = ({ roleId, roleMembershipId }: { roleId: string, roleMembershipId: string }) => {
        const roles = [...this.state.roles];
        const role = roles.find(r => r.id === roleId) as AccountRole;

        // update the role membership
        roles[roles.indexOf(role)] = {
            ...role,

            // If it has a membership, we must be removing it, otherwise it's been assigned so set it
            roleMembershipId: (role.roleMembershipId ? undefined : roleMembershipId),
        }

        this.setState({ roles });
    };

    assignRole = async (role: AccountRole) => {
        const { accountId, tenantId } = this.props;
        const { id: roleId } = role;
        const { id: roleMembershipId } = await this.props.accountsService!.assignRole({ accountId, tenantId, roleId });
        this.updateLocalRoleMembership({ roleId, roleMembershipId });
    }

    unassignRole = async (role: AccountRole) => {
        const { accountId, tenantId } = this.props;
        const { roleMembershipId } = role;

        if (!roleMembershipId) {
            // the account doesn't have the role, nothing to remove
            return;
        }

        await this.props.accountsService!.unassignRole({ accountId, tenantId, roleMembershipId });
        this.updateLocalRoleMembership({ roleId: role.id, roleMembershipId });
    }

    componentWillUnmount() {
        this.setState = () => { };
    }

    render() {
        const { children } = this.props;

        const state = this.state;
        const { assignRole, unassignRole } = this;

        return children({ ...state, assignRole, unassignRole });
    }
}
