import * as React from 'react';
import { hot } from 'react-hot-loader';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import AdvancedHrDatabase from '../../../components/AdvancedHrDatabase/AdvancedHrDatabase';

const AdvancedHrSettingsPage = () => {
    return (
        <>
            <Row>
                <Col>
                    <h3 className="pt-3"><FormattedMessage id="ADVANCED_HR" /></h3>
                    <p><FormattedMessage id="ADVANCED_HR_CONFIGURATION_VERBIAGE" /></p>
                </Col>
            </Row>
            <AdvancedHrDatabase />
        </>
    )
}

export default hot(module)(AdvancedHrSettingsPage);
