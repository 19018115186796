import * as React from 'react';
import Toggle, { ToggleComponentProps } from './Toggle';
import { Alert } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

interface AsyncToggleState {
    isOn?: boolean;
    error?: Error;
}

export class AsyncToggle extends React.Component<ToggleComponentProps, AsyncToggleState> {
    isSaving = false;
    state = {
        isOn: undefined,
        error: undefined,
    }

    // Clicking on the toggle when its still saving should be a no-op
    handleChange = async (payload: {[name: string]: boolean}) => {
        if (this.isSaving) {
            return;
        }

        this.isSaving = true;
        try {
            const toggleValue = payload[this.props.name];
            this.setState({
                isOn: toggleValue,
                error: undefined,
            });

            await this.props.onChange(payload);
        } catch (error) {
            this.setState({
                error,
            })
        } finally {
            this.isSaving = false;
            this.setState({
                isOn: undefined,
            });
        }
    };

    render() {
        const { isOn, error } = this.state;
        const { isSaving, handleChange } = this;
        const toggleProps = {
            ...this.props,
            onChange: handleChange,
        };
        if (isOn !== undefined) {
            toggleProps.on = isOn;
        }
        return (
            <>
                <Toggle {...toggleProps} />
                <p style={{ visibility: isSaving ? 'visible' : 'hidden' }} className="text-muted small mb-0">Saving...</p>

                {/* Visually this error doesn't flow well but isn't an immedate priority. Given more time, we'd render an error icon
                    and use a tooltip so that we don't have the flow issues.
                  */}
                {Boolean(error) && (
                    <Alert color="danger"><FormattedMessage id="ERROR_MAYBE_RETRY" /></Alert>
                )}
            </>
        );

    }
}
