import { LOAD_VERIFICATION_URL, CLEAR_VERIFICATION_CODE } from '../../constants';
import { VerificationAction } from '../../actions/verification/verification'
import { Verification } from '../../models';

const initialState: Verification = {
    url: '',
    code: '',
    tenantId: '',
};

export function verification(state: Verification = initialState, action: VerificationAction): any | null {
    if (!action.error) {
        switch (action.type) {
            case LOAD_VERIFICATION_URL: {
                const { payload } = action;
                return {
                    ...state,
                    ...payload,
                }
            }
            case CLEAR_VERIFICATION_CODE: {
                return initialState;
            }
        }

        return state;
    }
}
