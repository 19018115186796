import { REQUEST_SSO_TOKEN } from '../../constants';
import { getSSOToken } from '../../helpers/tokens';
import { createAsyncAction } from '../async/async';
import { GenericAction, Authentication } from '../../models';

export interface RequestSSOToken extends GenericAction<Authentication> {
    type: REQUEST_SSO_TOKEN;
}

export type AuthenticationAction = RequestSSOToken;

export function requestSSOToken() {
    return createAsyncAction(REQUEST_SSO_TOKEN, () => getSSOToken());
}

export function authFallback(error: any) {
    return error.status === 401 ? requestSSOToken() : undefined;
}
