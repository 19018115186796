import { LogoUploadPayload } from './logoUpload';

export interface SsoData {
    enabled?: boolean;
    mfaEnabled?: boolean;
    name?: string;
    serialNumber?: number | string;
    logo?: string | LogoUploadPayload;
    subdomain?: string;
    configurationMode?: string;
    evoDatabaseHost?: string;
}

export enum ConfigurationModes {
    View = 'view',
    Edit = 'edit',
    Create = 'create',
}
