import { State } from '@symbiotic/green-state';

export type AsyncState<M> = M & {
    isLoading?: boolean
    error?: any;
};

export class PromisedState<M = any> extends State<AsyncState<M>> {
    constructor(private getState: () => Promise<M>) {
        super({ isLoading: false, error: undefined } as any as AsyncState<M>);
    }

    load = async () => {
        try {
            this.setState({ isLoading: true } as any);

            const state = await this.getState();

            this.setState({
                ...(state as any), // https://github.com/microsoft/TypeScript/issues/24630
                error: undefined,
            });

        } catch (error) {
            this.setState({ error } as any);
            throw error;

        } finally {
            this.setState({ isLoading: false } as any);
        }
    }
}
