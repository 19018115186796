/**
 * Represents a form field's status and value.
 *
 * @author Drew Chalke
 */
export class FormState {

  /**
   * Status of form field
   * @property {List} dirty
   */
  dirty: boolean;

  /**
   * Current value of the form field
   * @property {List} value
   */
  value: any;

  public constructor(dirty: boolean = false, value?: any) {
    this.dirty = dirty;
    if (value) {
      this.value = value;
    }
  }
}
